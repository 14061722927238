.DaysCheckContainer-offer {
    margin-top: 26px;
    margin-left: -15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    .days {
      transform: translateY(4px);
      width: 18px;
      height: 18px;
      margin-left: 15px;
      margin-right: 10px;
      accent-color: #E52333;
    }
  }
//   .DaysCheckContainer {
//     margin-top: -7px;
//     margin-bottom: 20px;
//     display: flex;
//     justify-content: flex-start;
//   }
  
//   .DaysCheckContainer {
//     label {
//       padding-left: 8px;
//       // border: 1px solid red;
//       padding-right: 20px;
      
  
  
//       //styleName: Subtitle/Text 16;
//   font-family: "Poppins",sans-serif;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 24px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   color: #2F2F2F;
//     }
//   }
  