* {
  font-family: "Poppins", sans-serif;
}

.container-step2 {
  // position: absolute;
  // top: 635px;
  // left: 10px;
  width: 40%;
  height: auto;
  // margin-top: 82.5%;
  // background-color: lightsalmon;
  @media(max-width: 1080px){
    // background-color: lightsalmon;
  }
}

.Step2-container {
  position: relative;
  // top: 100px;

  // border: 2px solid red;
  display: flex;
  width: 600px;
  height: 500px;
  justify-content: flex-start;
}
.Step2-form {
  // border:2px solid red;
  position: relative;
  top: 30px;
  display: flex;
  width: 500px;
  flex-direction: column;
}
.Step2-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 47vw;
  position: relative;
  // border: 1px solid red;
  width: 38vw;
  height: 3rem;

 
}

.Step2-headerExpanded{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  // border: 1px solid red;
  width: 38vw;
  height: 3rem;

 
}

.Step2-heading {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 25px;
  position: absolute;
}
.Rupees-heading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
}
.editbuttonpricing {
  display: flex;

  // margin-left: 150px;
  background: none;
  // background-color: pink;
  text-decoration: none;
  border: none;
  font-family: "Poppins",sans-serif;

  font-size: 16px;
  font-weight: 400;
  display: flex;
  color: #E52333;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  right:  2vw;

 

}

.Res-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.CostPrice-heading {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 10px;
}

.Step2Kitchen-relatedheading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.Step2KitchenRelated {
  display: flex;
  justify-content: flex-start;
  margin-top: -10px;

  width: 600px;
  gap: 60px;
}
.Step2KitchenStation {
  width: 250px;
  display: flex;
  flex-direction: column;
}
.Step2KitchenStationheading {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
}
.Step2KitchenStationvalue {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
}
.Step2Preparation {
  width: 200px;
  display: flex;
  flex-direction: column;
  // background-color: red;
}
.Step2Preparationheading {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
  width: fit-content;
  // background-color: red;
}
.Step2Preparationvalue {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
  width: fit-content;
  // background-color: red;
}
.Step2Inventory-relatedheading {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 20px;
}
.Step2InventoryRelated {
  display: flex;
  justify-content: flex-start;
  width: 600px;
  gap: 60px;
  margin-top: -5px;
  margin-bottom: 10px;
}
.Step2InventoryServings {
  width: 250px;
  display: flex;
  flex-direction: column;
}
.Step2Servingsheading {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 5px;
}
.Step2Servingsvalue {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.Step2Threshold {
  width: 200px;
  display: flex;
  flex-direction: column;
  // background-color: red;
}
.Step2Thresholdheading {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 5px;
  width: fit-content;
  // background-color: red;
}
.Step2Thresholdvalue {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  width: fit-content;
  // background-color: red;
}
.Step2InventoryMaxCount {
  width: 250px;
  display: flex;
  flex-direction: column;
}
.Step2ResetInventory {
  width: 200px;
  display: flex;
  flex-direction: column;
  // background-color: red;
}
