:root {
  --cchart-bg: #ffffff;
  --cchart-border: #000000;
}

.cdark-theme {
  --cchart-bg: #222b3c;
  --cchart-border: #ffffff;
}

.clight-theme {
  --cchart-bg: #ffffff;
  --cchart-border: #000000;
}

.c-bar-chart-container {
  position: relative;
  width: 100% !important;
  height: fit-content;
  height: 100 !important;

  @media (max-width: 500px) {
    width: 100% !important;
    height: 100% !important;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
    background-color: var(--cchart-bg);
    border-radius: 1rem;
    border: 1px solid var(--cchart-border);
  }
  .canvasjs-chart-credit {
    display: none !important;
  }
  .no-data-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--cchart-bg);
    border-radius: 1rem;
    border: 1px solid var(--cchart-border);
    padding: 20px;
    box-sizing: border-box;
    color: var(--no-data-text-color);
    text-align: center;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.bar-chart-loader {
  width: 1rem;
  height: 1rem;
  border: 3px solid var(--table-text);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute; /* Overlay on .s-box */
  bottom: 1vh;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}