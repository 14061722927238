#root {
  height: 100%;
  overflow-x: hidden;
}

.app {
  height: 100%;
  padding: 0px 0px;
  box-sizing: border-box;
}

a {
  color: #fff;
}

button:focus {
  outline: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
  // color: #000000;
  font-family: Poppins;
}
.pointer{
  cursor: pointer;
}