.dropdown-component {
  margin: 0;
  padding: 0;
  height: fit-content;
  width: fit-content;

  .dropDownBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    // align-items: center;
    // position: fixed;
  }
  .dropdownimageclosed {
    rotate: 180deg;
    cursor: pointer;
  }
  .dropdownimageopen {
    cursor: pointer;
  }
  .Dropdown-Error-message {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
    transform: translateY(-7px);
    margin-bottom: -25.5px;
    margin-top: 8px;
    // margin: 0;
    // background-color: pink;
    height: fit-content;
    width: fit-content;
  }
}
.dropdown-arrow-PC {
  position: relative;
  left: -10%;
  opacity: 60%;
}
.dropdown-search {
  width: 20vw;
  height: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  padding-right: 2rem;
  padding-left: 0.625rem;

  border-radius: 0.313rem;
  color: black;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.3);
  &:focus {
    outline: none;
  }
}
.subCategorysearch {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-body {
  position: absolute;
  width: 19.8vw;
  // border: 1px solid red;
  height: 10.8rem;
  max-height: 10.8rem;
  z-index: 5;
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #00000040;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    // display: block;
    // width: 0.5rem; 
    // height: 5px; 
    // background-color: #8f8f8f;   
    // border-radius: 1rem;  
  }
  
  &::-webkit-scrollbar-thumb {
    // background-color: #4c4c4c;
    // border-radius: 0.6rem;
    display: none;
  }

  .edititem {
    margin-left: -10px;
    position: relative;
  
  }

  .Dropdown-lists-and-edit {
    display: flex;
    justify-content: space-between;
    // padding-right: 10px;
 width: 19rem;
 overflow-y: scroll;
 overflow-x: hidden;
 height: 7.8rem;
 position: relative;
 &::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

&::-webkit-scrollbar-track {
  background: #b3b3b3;
}

&::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 10px;
  height: 40px;
  
}

&::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
}

@media (max-width: 2000px) {
  width: 23.7rem;
  // border: 1px solid red;
 
 }


 @media (max-width: 1770px) {
  width: 21rem;
  // border: 1px solid black;
 
 }
@media (max-width: 1600px) {
  width: 19rem;
  // border: 1px solid blue;
  position: relative;
 
 }
@media (max-width:1500px) {
  width: 17.2rem;
  // border: 1px solid green;

}

@media (max-width:1350px) {
  width: 15.2rem;
  // border: 1px solid yellow;

}

// border: 1px solid red;
cursor: pointer;
   
    // ::-webkit-scrollbar{
    //   display: none;
    // }
  }

  .dropdown-options {
    width: 300px;
    // width:25rem;
    // height: 7.5rem;
  //  overflow-y: hidden;
  //   overflow-x: hidden;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    // border: 1px solid red;
    
    // &::-webkit-scrollbar-track {
    //   background: #b3b3b3;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #5f5f5f;
    //   border-radius: 10px;
    //   height: 40px;
      
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //   background: #5f5f5f;
    // }

   
    // border: 1px solid red;
    cursor: pointer;
    .dropdown-option-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      // border: 1px solid red;
    }
    .dropdown-option-delete {
      display: flex;
      position: relative;
      font-size: 12px;
      width: 400px;
      right: 0.4rem;
      color: #e52333;
      .disabled-delete {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
    .dropdown-option {
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      padding-left: 1rem;
      // border: 1px solid red;

      .dropdon-option-inputfield {
        accent-color: #E52333;
        width: 1.25rem;
        height: 1.25rem;
        // border: 1px solid red;
      }

      .dropdon-option-label {
        padding-left: 0.5rem;
        width: 200px;
display: flex;
white-space: normal; /* Allows wrapping of text */
word-break: break-word; /* Breaks words when they're too long */
display: inline-block;
flex-wrap: wrap;
        @media (max-width: 2000px) {
          max-width: 260px;
          // border: 1px solid red;
         
         }
        
        
         @media (max-width: 1770px) {
          max-width: 220px;
          // border: 1px solid black;
         
         }
        @media (max-width: 1600px) {
       
         max-width: 180px;

          // border: 1px solid red;
     
          
         
         }
        @media (max-width:1500px) {
       max-width: 170px;
          // border: 1px solid green;
        
        }
        
        @media (max-width:1350px) {
      max-width: 140px;
          // border: 1px solid yellow;
        
        }


        // border: 1px solid red;
      }
    }
  }
  .dropdown-Addbutton {
    height: 3rem;
    // border: 1px solid blue;
    position: relative;
    right: 5px;
    width: 100%;
    .dropdown-addnew {
      height: 3rem;
      display: flex;
      align-items: center;
      position: relative;
      left: 6.5%;
      // border: 1px solid red;
      // padding: 0.5rem;
      .dropdown-addnew-input-and-button {
        display: flex;
        // width: 100%;
        height: 2rem;
        border: 1px solid #E52333;
        margin-left: -0.5rem;
        border-radius: 0px 4px 4px 0px;

        .dropdown-addnew-input-filed {
          width: 70%;
          height: 90%;
          border: none;
          padding-left: 10px;

          &:focus {
            border: none;
            outline: none;
          }
        }
        .dropdown-addnew-button {
          width: 5rem;
         border: none;
          outline: none;
          background-color: #E52333;
//  margin-left: 2rem;
          color: #ffffff;
//styleName: Subtitle/Text 16;
font-family: "Poppins",sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

text-decoration-skip-ink: none;

          border-radius: 0px 4px 4px 0px;
          cursor: pointer;
        }
      }
    }
   
  }
}

.dropdown-no-options {
  width: 20rem;
  height: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #E52333;
}

.imgLoader1 {
  // width: 30px;
  // height: 30px;
  // position:relative;
  top: 12vw;
  left: 21.5vw;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1300px) and (max-width: 1450px) {
  .imgLoader1 {
    width: 50px;
    height: 50px;
    top: 10%;
    left: 1vw;
    margin-right: 2vw;
  }
}

.editiconimage { 
  // margin-top: 0.5rem;
position: sticky;
  // left: -1rem;
  // z-index: 2;
  font-size: 16px;
  font-weight: 400;
  margin-left: -3.2rem;
  color: #E52333;
  text-decoration: underline;
  width: 3rem;
  margin-top: 0.5rem;
  z-index: 2;
cursor: pointer;
  // border: 1px solid red;
}

.dropdown-lists-edit
{
  display: flex;
  position: relative;
}

.addnew-open-dropdown
{
  display: flex;
  margin-top: 0.5rem;

}

.dropdown-Addbutton-add
{
  // border: 1px solid red;
  position: relative;
}

.dropdown-addnew-open-input
{
  
  width:78.5% ;
  position: absolute;
height: 33px;
margin-left: 0.7rem;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
border: 1px solid #67833E;


}
.dropdown-addnew-button
{
  background-color:#E52333 ;
  color: white;
  width: 53px;
height: 33px;
// left: 236px;
gap: 0px;
border-radius: 0px 4px 4px 0px;
border: none;
outline: none;
position: absolute;
right: 3%;
// left: -0.5rem;

}

.Addnew-edit-fields {
  // position: relative;
  display: flex;

  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  right: 0.1rem;
  bottom: 1px;
  height: 2.7rem;
  // border: 1px solid red;

  

  .dropdown-edit-button {
    // width: 3rem;
    border: 1px solid red;
    // position: relative;
    .dropdown-edit-done {
      color: #E52333;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #E52333;
      text-align: center;
      border-radius: 4px;
      width: 65px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 8rem;
      top: -0.15rem;
      cursor: pointer;
    }
  }
  .dropdown-addbutton {
    width: 6.813rem;
    // border:1px solid red;
    height: 2.063rem;
    color: #ffffff;
    border-radius: 0.25rem;
    background: #E52333;
    outline: none;
    // border: 1px solid red;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 2%;
    // margin-right: 10px;
    // left: 12rem;
    top: 0.7rem;
  
  
  }
  
}

.dropdown-input-and-addnewbtn
{
  position: relative;
  height: 3rem;
  // border: 1px solid green;
}
.delete-option

{

  position: relative;
  // border: 1px solid red;
  .delete-updated {
    position: absolute;
    visibility: hidden;
    // top: 100px;
    right: 332px;
    top: 15px;
    // left: 1%;
    width: 188px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, 
    visibility 0.3s ease-in-out;
    // background-color: pink;
  
    .delete-box-updated {
      width: fit-content;
      height: fit-content;
      border-radius: 3px;
      // padding: 6px;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: #e52333;
      color: white;
      font-size: 8px;
      margin-bottom: 0;
    }
  
    .ArrowHoverdelete-updated {
      display: flex;
      justify-content: flex-end;
      position: relative;
  
      width: 10px;
      height: 10px;
      top: 3px;
      // background-color: lightcoral;
      left: 72%;
      // margin-bottom: 2px;
    }
  }
  &:hover .delete-updated {
    visibility: visible;
    opacity: 1;
  }
}
