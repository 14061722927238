.popupInnerItem {
  display: flex;
}

.popupContainer {
  display: flex;
  flex-direction: row;
  margin-top: -17px;
  width: 230px;  
  justify-content: flex-end;
  text-align: end;
  align-items: flex-end;
}

.main-section .menu-items .header {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0px;
  width: 100%;
  border-bottom: none;
}

.employee-pad {
  padding-top: 0px !important;
}

.tableContainer{
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.employeeTable{
  width: 100%;
  border-collapse: collapse;
}

.employeeTable thead {
  position: sticky;
  top: 0;
  background-color: white; 
  z-index: 9999999;
  width: 100px;
}

.main-section .menu-list table{
  width: 100%;
}

.main-section .menu-list {
  height: 500px;
  overflow: auto;
}

.main-section .menu-list table tr{
  height: 20px;
}

.main-section .menu-list table tr:nth-of-type(odd) {
  background-color: rgba(196, 196, 196, 0.4);
}

.main-section .menu-list table tr:nth-of-type(even) {
  background-color: white;
}

.main-section .menu-list table thead th {
  width: 100px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: left;
  padding-bottom: 13px;
  border-bottom: none;
  padding-left: 10px;
  font-weight: 500;
  text-align: start;
  padding-left: 20px;
  background-color: white;
  box-sizing: border-box;
}

.main-section .menu-list table td{
  width: fit-content;
  border: none;
  font-size: 15px;
  padding: 5px 0 5px 0;
  /* justify-content: center; */
  text-align: start;
  padding-left: 15px;
  margin-right: 100px;
}

.main-section .menu-list table td ul{
  box-shadow: none;
  background: none;
  position: absolute;
  right: -50px;
  top: 24px;
  padding: 0;
  list-style-type: none;
  border-radius: 5px;
  padding: 13px 17px 0px;
  z-index: 9999;
  box-sizing: border-box;
}

.dropDown {
  font-size: 14px !important;
}

.actions{
  width: 23px;
  height: 23px;
  margin-right: 15px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 0px 5px 5px 5px;
}

.previewActions{
  margin-top: 15px;
  width: 18px;
  margin-left: 40px;
  margin-right: 20px;
  cursor: pointer;
}

.searchContainer{
  display: flex;
  justify-content: space-between;
}

.searchBox{
  width: 461px;
  display: flex;
  border: 1px solid #B4B4B4;
  justify-content: space-between;
  border-radius: 1px;
  margin-bottom: 30px;
}

.searchBar{
  border: none;
}

img{
  cursor: pointer;
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5rem;
}

.no-results h2 {
  margin-top: 1rem;
}

.addBtn{
  width: 120px;
  height: 40px;
  margin-top: 30px;
  border: none;
  background-color: #E52333;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.rolesBox{
  display: flex;
  gap: 10px;
  width: fit-content;
  margin-right: 100px;
}

.statusBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.statusImg{
  width: 18px;
  height: 18px;
  margin-right: 9px;
}

.dropDownContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999; 
}

.functionsDropDown {
  /* margin-top: 330px; */
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 7px;
  /* padding: 20px; */
  width: 80%;
  max-width: 900px;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  border: 1.5px solid black;
  bottom: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease; 
}

.fixedTitle{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 0px 0;
  height: 60px;
  margin-top: -20px;
  padding-top: 10px;
}

.checkboxList p{
  font-size: 14px;
  width: fit-content;
}

.checkBoxContainer{
  display: flex;
  flex-direction: column;
}

.menu-details .flexContainer .checkBoxItem input{
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkBoxItem{
  display: flex;
  flex-direction: column !important;
  align-items: start; 
  gap: 10px; 
  cursor: pointer;
}

.checkBoxItemfunction{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  align-items: center !important; 
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.checkBoxItemfunction label{
  width: fit-content;
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

.roleFunction{
  width: 180px;
  margin-left: 420px;
  margin-top: -55px;
  color: #E52333 ;
  font-weight: 600;
  cursor: pointer;
}

.menu-details .flexContainer .functionBtn button {
  width: 130px;
  border-radius: 5px;
  font-size: 13px;
  /* padding:5px; */
}

.functionBtn {
  display: flex;
  justify-content: end;
  padding: 10px 0 10px 0; 
  /* border-top: 1px solid #ccc; */
  background-color: #fff;
  position: sticky;
  bottom: 0;
  width: 100%;
  gap: 10px;
}

.resetbtn{
  background-color: #fff;
  color: #E52333;
  width: 80px;
  cursor: pointer;
  font-weight: 600;
  border:1px solid #E52333
}
.resetbtn span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.functionBtn .resetbtn img{
  width: 30px;
}
.saveBtn{
  background-color: #E52333;
  color: #fff;
  width: 80px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
}

.checkList {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.openedEyeIcon{
  position: absolute;
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
}
.modalContainer{
  border: 1px solid #ccc;
  background-color: white;
  padding: 50px;
  border-radius: 7px;
  text-align: center;
}
.modalBtn{
  display: flex;
  gap: 40px;
  margin-top: 30px;
}
.modalBtn .noBtn{
  background-color: white;
  color: #E52333;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  width: 130px;
  border-radius: 7px;
  border-color: #E52333;
  border-width: 1px;
  cursor: pointer;
}
.modalBtn .yesBtn{
  background-color: #E52333;
  color: white;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  width: 130px;
  border-radius: 7px;
  border: none;
  border-width: 1px;
  cursor: pointer;
}

.addEmployeeHeader{
  display:flex;
  top:0;
  background-color:white;
  position:fixed;
  height:100px;
  width:100%;
  z-index:999999999;
}

.passwordErrorText{
  font-size: 12px;
  min-height: 20px;
  color: #FF0505;
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  margin-bottom: -120px;
}

.emailErrorText{
  color: #FF0505;
  font-size: 12px;
}

@media(max-width:1280px){
  .main-section .menu-list table td{
    margin-right: 100px;
  }
  .popupContainer{
    margin-top: -17px;
    width: 100px;  
    margin-right: -90px;
  }
  .previewActions{
    margin-top: 15px;
    width: 15px;
    margin-left: 60px;
    margin-right: 20px;
    cursor: pointer;
  }
}

@media(max-width:1080px){
  .main-section .menu-list table td{
    margin-right: 50px;
  }
  .popupContainer{
    margin-top: -17px;
    width: 100px;  
    margin-right: -90px;
  }
  .previewActions{
    margin-top: 15px;
    width: 15px;
    margin-left: 60px;
    margin-right: 20px;
    cursor: pointer;
  }
}

@media(max-width:900px){
  .main-section .menu-list table td{
    margin-right: 10px;
  }
  .popupContainer{
    margin-top: -17px;
    width: 100px;  
    margin-right: -90px;
  }
  .previewActions{
    margin-top: 15px;
    width: 15px;
    margin-left: 60px;
    margin-right: 20px;
    cursor: pointer;
  }
}

@media(max-width:800px){
  .flexContainer{
    display: flex;
    flex-direction: column;
  }
}

.unblockMessageTxt{
  font-family: Poppins;
  color: #67833E;
}

.unblockMessageTxtContainer{
  margin-top: 100px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the rotating-div class */
.rotating-div {
  animation: rotate 2s linear infinite;
}

.employeeContainer1{
  display: flex !important;
  flex-direction: column !important;
  height: 100vh;
  width: 70%;
}
