.toggleBtn {
  background-color: #979797;
  width: 1.688rem;
  height: 1.125rem;
  border-radius: 6.188rem;
  border: 0.063rem solid #979797;
  box-shadow: 0.063rem 0.063rem 0.063rem rgba(0, 0, 0, 0.749);
  position: relative;
  cursor: pointer;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  top: 2px;
  /* left: -10px; */
}

.thumb {
  border-radius: 8.188rem;
  width: 0.725rem;
  height: 0.725rem;
  background-color: #ffffff;
  position: absolute;
  left: 0.188rem;
  transition: left 0.2s;
}

.toggleBtn.Toggled {
  background-color: #E52333;
  border-color: #E52333;
}

.toggleBtn.Toggled .thumb {
  left: calc(100% - 13px);
}
