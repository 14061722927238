:root {
  --t-navbar-bg: #e0e0e0;
  --t-navbar-text: #333333;
  --t-table-bg: #ffffff;
  --t-table-text: #333333;
  --t-chart-bg: #ffffff;
}

.t-dark-theme {
  --t-navbar-bg: #222b3c;
  --t-navbar-text: #6e7d7d;
  --t-table-bg: #222b3c;
  --t-table-text: #ffffff;
  --t-chart-bg: #222b3c;
}

.t-light-theme {
  --t-navbar-bg: #e0e0e0;
  --t-navbar-text: #333333;
  --t-table-bg: #ffffff;
  --t-table-text: #333333;
  --t-chart-bg: #ffffff;
}

.t-expanded-topnavbar {
  width: 100% !important;
  
  @media(max-width: 500px) {
    width: 104vw !important;
    // background-color: palevioletred !important;
  }
}

.t-navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--t-navbar-bg);
  color: var(--t-navbar-text);
  position: sticky;
  top: 0;
  z-index: 2;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    justify-content: space-between;
    // padding-left: 5vw;
  }
  @media (max-width: 500px) {
    width: 103vw;
    // background-color: pink;
  }

  .t-nav-item {
    font-size: 16px;
    color: var(--t-navbar-text);
    cursor: pointer;
    margin-left: 1vw;

    &.t-active {
      color: var(--t-table-text);
      font-weight: bold !important;
      padding-left: 2vw;
    }

    &:hover {
      color: var(--t-table-text);
    }
    hr {
      height: 2px;
      color: var(--t-table-text);
      border-radius: 5px;
      background-color: var(--t-table-text);
      margin: 0px;
    }
    @media (max-width: 800px) {
      display: none;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  .t-theme-toggle {
    @media (max-width: 800px) {
      padding-right: 1rem;
    }
    @media (max-width: 500px) {
      padding-right: 1rem;
    }
    .t-switch {
      position: relative;
      display: inline-block;
      width: 3.75rem;
      height: 2.125rem;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .t-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #5d6e8f;
        transition: 0.4s;
        border-radius: 2.125rem;

        &:before {
          position: absolute;
          content: "";
          height: 1.625rem; /* 26px */
          width: 1.625rem; /* 26px */
          left: 0.25rem; /* 4px */
          bottom: 0.25rem; /* 4px */
          background-color: black;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .t-slider {
        background-color: #2196f3;
      }

      input:focus + .t-slider {
        box-shadow: 0 0 0.0625rem #2196f3; /* 1px */
      }

      input:checked + .t-slider:before {
        transform: translateX(1.625rem); /* 26px */
      }
    }
  }

  .t-filter-toggle-btn-container {
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;

    .t-filter-toggle-btn {
      text-align: center;
      padding: 0.5rem;
      width: 10rem;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-radius: 0.8rem;
      cursor: pointer;
      height: 0.8rem;
    }

    .t-filter-drop-down-options {
      position: absolute;
      top: 110%;
      left: 50%;
      transform: translateX(-50%);
      width: 12rem;
      padding: 0.5rem;
      background-color: var(--t-navbar-bg);
      color: var(--t-navbar-text);
      border: 1px solid #ccc;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      z-index: 999;
      border-radius: 1rem;

      p {
        margin: 0;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 0.6rem;
        &:hover {
          background-color: var(--t-navbar-text);
          color: var(--t-navbar-bg);
        }
      }
    }
  }
}
