.Review-values {
  width: 13rem;
  height: auto;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  // border: 2px solid;
  // overflow-y: auto;
  // overflow-x: hidden;
  padding-left: 20px;

  .Review-values-label {
    font-size: 12px;
    font-weight: 400;
    color: #6e6e6e;
  }
  .Review-values-text {
    font-size: 14px;
    font-weight: 400;
    color: black;
   
    height: auto;
    
    max-width: 13.5rem;
    // border: 1px solid red;
    word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: pre-wrap;
  }
}
