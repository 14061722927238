.DaysOfWeek {
    display: flex; /* Use flexbox for layout */
    justify-content: start; /* Center items horizontally */
    list-style: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
    margin-top: 0px;
    gap: 0.7rem;
    margin-left: -0.1rem;
    // margin-left: 21px;
}

.list {
    margin: 0 0px; /* Add some margin between the days */
    border: 2px solid transparent; /* Initial transparent border */
    border-radius: 50%; /* Use 50% for a perfect circle */
    width: 25px;
    height: 25px;
    text-align: center;
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    color: #868686;
    transition: background-color 0.3s, border 0.3s; /* Smooth transition */
}

.included {
    background-color: #779749; /* Highlight color */
    border: 2px solid #779749; /* Border color when included */
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
