:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #404d66;
  --new-bg:#ccc;
}

.sales-dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #2e3a4f;
  --cchart-bg: #222b3c;
  --cchart-border: #ffffff;
  --new-bg: #404d66;
}

.sales-light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ffffff;
  --cchart-bg: #ffffff;
  --cchart-border: #000000;
  --new-bg: #ccc;
}

// .s-expanded-width-sales {  
//   width: 94% !important;
//   // margin-left: -1.5rem;
//   // @media(max-width: 1536px){
//   //   width: 94% !important;
//   // }
// }

.s-expanded-boxes {
  width: 10.7rem ;
  @media(max-width: 1536px){
    // background-color: lightgreen;
    width: 19vw !important;
  }
  @media (max-width: 1280px) {
    width: 8.8rem ;
  }
}

.s-sales-container {
  background-color: var(--screen-bg);
  display: flex;
  flex-direction: column;
  // margin-left: -20px;
  gap: 0.5rem;
  // width: 91vw;
  // margin-left: -1.5rem;
  padding-bottom: 5vh;

  @media (max-width: 500px) {
    width: 104vw !important;
  }

  .s-sales-head {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    @media(max-width: 500px){
      justify-content: space-around;
      display: flex;
      flex-direction: column;
      gap: 2vh;
    }

    .s-name-board {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--table-text);

      h1 {
        font-weight: 500;
        font-size: 21px;
        @media (max-width: 800px) {
          font-size: 17px;
        }
      }
    }

    // .s-dates {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 3rem;
    //   height: 6rem;

    //   .s-label-time-period {
    //     font-weight: 600;
    //     color: var(--table-text);
    //     @media (max-width: 800px) {
    //       font-size: smaller;
    //     }
    //     @media (max-width: 500px) {
    //       display: none;
    //     }
    //   }

    //   .s-filter-toggle-btn-container {
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     gap: 0.6rem;

    //     .s-filter-toggle-btn {
    //       text-align: center;
    //       padding: 0.5rem;
    //       width: 10rem;
    //       height: 2rem;
    //       color: #000;
    //       background-color: #ccc;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       border: 1px solid #000;
    //       border-radius: 0.8rem;
    //       cursor: pointer;
    //       @media (max-width: 800px) {
    //         width: 5rem;
    //         height: 1rem;
    //         font-size: smaller;
    //       }
    //       @media(max-width: 500px){
    //         width: 27vw;
    //       }
    //     }

    //     .s-filter-drop-down-options {
    //       position: absolute;
    //       top: 110%;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       width: 12rem;
    //       padding: 0.5rem;
    //       background-color: var(--navbar-bg);
    //       color: var(--navbar-text);
    //       border: 1px solid #ccc;
    //       box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    //       z-index: 999;
    //       border-radius: 1rem;
    //       @media (max-width: 800px) {
    //         width: 5rem;
    //       }
    //       @media(max-width: 500px) {
    //         width: 25vw;
    //       }

    //       p {
    //         margin: 0;
    //         padding: 0.5rem;
    //         cursor: pointer;
    //         border-radius: 0.6rem;
    //         text-align: center;

    //         &:hover {
    //           background-color: var(--navbar-text);
    //           color: var(--navbar-bg);
    //         }

    //         @media (max-width: 800px) {
    //           font-size: x-small;
    //         }
    //       }
    //     }
    //   }
    // }
    .sales-date-filter-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .s-canva-pie-chart-outer-cont {
    display: flex;
    width: 71%;
    gap: 0.5rem;
    @media (max-width: 800px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .s-canva-pie-chart-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--chart-bg);
      width: 71%;
      border: 1px solid var(--table-text);
      border-radius: 1rem;
      overflow: hidden;
    }
  }

  .s-employee-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .s-overall-summary {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .s-overall-summary-inner-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      flex-wrap: wrap;
      gap: 0.5rem;
      // background-color: pink;
      @media(max-width: 1536px){
        // background-color: lightsalmon;
        // width: 90%;
        height: 100%;
      }
      @media(max-width: 800px){
        // background-color: pink;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
      }
      @media(max-width: 500px){
        display: flex;
        flex-direction: column;
        // background-color: lightseagreen;
      }
    }

    @media(max-width: 1536px){
      // background-color: lightskyblue;
      // width: 50%;
      flex-wrap: wrap;
    }

    @media (max-width: 1080px) {
      display: flex;
      flex-wrap: wrap;
      // background-color: pink;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-wrap: wrap;
      // background-color: lightgreen;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      // change
      // background-color: lightseagreen;
    }


    .s-box {
      height: 8rem;
      width: 11.8rem;
      border-radius: 1rem;
      background-color: var(--table-bg);
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: var(--table-text);
      border: 1px solid;
      position: relative;

      // @media(max-width: 1536px){
      //   height: 20vh;
      //   width:  50vw;
      //   background-color: darksalmons;
      // }

      @media (max-width: 1280px) {
        width: 9.7rem;
      }

      @media (max-width: 1080px) {
        width: 35%;
      }

      @media (max-width: 800px) {
        width: 80%;
      }

      @media (max-width: 500px) {
        width: 85% !important;
        // background-color: pink;
      }

      h2 {
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        @media(max-width: 800px){
          font-size: small;
        }
        @media(max-width: 500px){
          font-size:  x-large;
        }
      }

      h3 {
        margin-bottom: 0rem;
        margin-top: 0rem;
        @media(max-width: 800px){
          font-size: x-small;
        }
        @media(max-width: 500px){
          font-size:  large;
        }
      }
      .label-tooltip-container {
        display: flex;
        align-items: center;
        gap: 1vw;
    
        .s-tooltip-wrapper {
          position: relative;
          display: inline-block;
          // background-color: orange;
          height: 2.9vh;
          border-radius: 1vw;
    
          .s-tool-tip-image {
            cursor: pointer;
            height: 2.8vh;
            // background-color: pink;
            border-radius: 1vw;
          }
    
          .tool-tip-content {
            position: absolute;
            top: 120%; // Adjust to position the tooltip below the icon
            left: 50%;
            transform: translateX(-50%);
            background-color: #f9f9f9;
            color: #333;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 0.5rem;
            width: max-content;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 10;
            font-size: 0.9rem;
            text-align: center;
            white-space: nowrap;
            opacity: 0; // Hidden by default
            visibility: hidden; // Prevent interaction when hidden
            transition: opacity 0.3s ease, visibility 0.3s ease;
            @media (max-width: 800px) {
              top: 110%; 
              font-size: 0.8rem; 
              padding: 0.4rem; 
              max-width: 60vw; 
              white-space: normal;
              text-align: left;
            }
          }
    
          &:hover .tool-tip-content {
            opacity: 1; // Show tooltip on hover
            visibility: visible;
          }
        }
      }
    }
  }

  .s-summary-no-data{
    background-color: var(--new-bg);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }



  .loader-container-s-box{
    height: 8rem;
    width: 11.8rem;
    border-radius: 1rem;
    background-color: var(--table-bg);
    background-color: red;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--table-text);
    border: 1px solid;
    position: absolute;
    z-index: 1000; /* Overlay on .s-box */
  }

  .loader {
    width: 2rem;
    height: 2rem;
    border: 3px solid var(--table-text);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute; /* Overlay on .s-box */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .s-overall-total-sales-ds {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 10vh;
    margin-top: 10vh;
    flex-direction: column;
    // background-color: pink;
    @media(max-width: 1707px){
      // background-color: lightskyblue;
    }
    

    .title-overall-total-sales-ds{
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 30px;
      color: var(--table-text);
      @media(max-width: 800px){
        // background-color: lightgreen;

        font-size: medium;
        width: 100%;
        height: fit-content;
        text-wrap-style: balance;
        // padding-left: 18vw;
        text-align: center;
      }
      @media(max-width: 500px){
        font-size: medium;
        width: 100%;
        height: fit-content;
        text-wrap-style: balance;
        text-align: center;
      }
    }

    .s-overall-total-sales-ds-inner-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      flex-wrap: wrap;
      gap: 0.5rem;
      height: 40vh;
      // background-color: pink;
      // background-color: palegreen;
      @media(max-width: 1536px){
        // background-color: lightsalmon;
        // width: 90%;
        height: 100%;
      }
      @media(max-width: 800px){
        display: flex;
        flex-wrap: wrap;
      }
      @media(max-width: 500px){
        display:flex ;
        flex-direction: column;
      }
    }

    @media(max-width: 1536px){
      // background-color: lightskyblue;
      // width: 50%;
      // flex-wrap: wrap;
      flex-direction: column;
    }

    @media (max-width: 1080px) {
      display: flex;
      flex-direction: column;
      // background-color: pink;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      // background-color: lightgreen;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      // background-color: lightseagreen;
    }


    .s-box {
      height: 8rem;
      width: 11.8rem;
      border-radius: 1rem;
      background-color: var(--table-bg);
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: var(--table-text);
      border: 1px solid;

      // @media(max-width: 1536px){
      //   height: 20vh;
      //   width:  50vw;
      //   background-color: darksalmons;
      // }

      @media (max-width: 1280px) {
        width: 9.7rem;
      }

      @media (max-width: 1080px) {
        width: 35%;
      }

      @media (max-width: 800px) {
        width: 35%;
      }

      @media (max-width: 500px) {
        width: 85% !important;
        // background-color: pink;
      }

      h2 {
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        @media(max-width: 800px){
          // background-color: pink ;
          font-size: small;
        }
        @media(max-width: 500px){
          font-size:  x-large;
        }
      }

      h3 {
        margin-bottom: 0rem;
        margin-top: 0rem;
        @media(max-width: 800px){
          // background-color: lightseagreen;
          font-size: small;
        }
        @media(max-width: 500px){
          font-size: large;
        }
      }
      .label-tooltip-container {
        display: flex;
        align-items: center;
        gap: 0.1vw;
    
        .s-tooltip-wrapper {
          position: relative;
          display: inline-block;
          // background-color: orange;
          height: 2.9vh;
          border-radius: 1vw;
    
          .s-tool-tip-image {
            cursor: pointer;
            height: 2.8vh;
            // background-color: pink;
            border-radius: 1vw;
          }
    
          .tool-tip-content {
            position: absolute;
            top: 120%; // Adjust to position the tooltip below the icon
            left: 50%;
            transform: translateX(-50%);
            background-color: #f9f9f9;
            color: #333;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 0.5rem;
            width: max-content;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 10;
            font-size: 0.9rem;
            text-align: center;
            white-space: nowrap;
            opacity: 0; // Hidden by default
            visibility: hidden; // Prevent interaction when hidden
            transition: opacity 0.3s ease, visibility 0.3s ease;
            @media (max-width: 800px) {
              top: 110%; 
              font-size: 0.8rem; 
              padding: 0.4rem; 
              max-width: 60vw; 
              white-space: normal;
              text-align: left; 
              left: -50%; 
            }
          }
    
          &:hover .tool-tip-content {
            opacity: 1; // Show tooltip on hover
            visibility: visible;
          }
        }
      }
    }
  }


  .direct-sales-store-price-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: pink;
    gap: 4vw;
    .inner-direct-sales-store-prices{
      // background-color: lightgreen;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
      @media(max-width: 800px){
        margin-top: 1vh;
        // background-color: lightcoral !important;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .s-date-range-style {
    display: flex;
    height: 60px;
    right: 1rem;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    // background-color: pink;
    @media(max-width: 500px){
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      // width: 90%;
      // background-color: palevioletred;
      height: 40vh;
    }

    .dateLabel {
      align-items: flex-start;
      margin-right: 0.2rem;
      color: var(--table-text);
      // background-color: pink;
    }

    .s-start-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
      margin-right: 1rem;
    }

    .s-end-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
    }
  }

  .s-name-board-two {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--table-text);
    h1 {
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  .s-tab-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 83%;
    width: 100%;
    margin: auto;
    gap: 0.5rem;
    // background-color: palegreen;

    .s-table-container-one-s {
      // width: 86%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      // background-color: pink;
      @media (max-width: 800px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .s-table-container-two-s {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: center;
      // width: 86%;
      width: 100%;
      gap: 0.5rem;
      @media (max-width: 800px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .s-day-of-the-week {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .s-day-of-the-week-inner {
      width: 70%;
    }
  }
}
