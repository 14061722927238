.lable{
   
    .lable-style
{
    font-size: 14px;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    color: #666666;  



}

}

