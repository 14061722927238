.session-constainer {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 1200;
}
.session-window {
  //  margin-top: -30%;
  position: relative;
  // left: -19rem;
  top: -1rem;
  background-color: white;
  padding: 5px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.sessions-head {
  font-size: 20px;
  font-weight: 500;
  padding: 2px;
}
.session-name {
  display: flex;
  gap: 10px;

  padding: 3px;
  width: fit-content;
  // border: 1px solid red;
  .session-lable {
    width: 13rem;
  }
  input {
    accent-color: #E52333;
    width: 20px;
    height: 20px;
  }
}
.save-btn-session {
  width: 100px;
  height: 40px;
  color: white;
  border: 0.5px solid #E52333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E52333;
  border-radius: 3.47px;
  cursor: pointer;
}
.cancel-btn-session {
  width: 100px;
  height: 40px;
  color: #E52333;
  border: 0.5px solid #E52333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 3.47px;
  cursor: pointer;
}
.session-save-cancel {
  display: flex;
  padding-bottom: 10px;
  gap: 10px;
}
