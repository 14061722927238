.buttonsContainer {
    position: fixed;
    bottom: 10px; 
    right: 30px;  
    display: flex;
    gap: 10px;
  }
.cancelBtn {
    width: 140px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    border-radius:9px;
    text-align: center;
    color: #6a8c3a;
    border-style: solid;
    border-width: thin;
    border-color: #6a8c3a;
    background-color: #fff;
    margin-right: 30px;
    cursor: pointer;

    &.cancelBtn:hover{
        box-shadow: 1px 1px 1px 1px #6a8c3a;
    }
  }
  .saveBtn {
    width: 140px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 9px;
    text-align: center;
    color: #fff;
    background-color: #E52333;
    border-style: hidden;
    cursor: pointer;

    &.saveBtn:hover{
        box-shadow: 1px 1px 1px 1px #E52333;
    }
  }
  .modalScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    height: 10rem;
    width: 25rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: none;
    border-radius: 20px;
  }
  
  .modalBtnsContainer {
    display: flex;
    justify-content: space-between;
    width: 55%;
  }
  
  .yesBtn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-width: thin;
    border-radius: 17px;
    font-size: 15px;
    font-weight: 600;
    color: #6a8c3a;
    border-color: #6a8c3a;
  }

  .yesBtn:hover{
    box-shadow: 2px 2px 1px 1px gray;
  }
  
  .noBtn {
    color: #fff;
    background-color: #6a8c3a;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-width: thin;
    border-width: thin;
    border-radius: 17px;
    font-size: 15px;
    font-weight: 600;
  }

  .noBtn:hover{
    box-shadow: 2px 2px 1px 1px gray;
  }
  .messagebox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: 1px solid #5f5f5f;
  }
  .messageContainer {
    height: 10rem;
    width: 25rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: none;
    border-radius: 20px;
  }
  
  .messageContainer {
    display: flex;
    justify-content: space-between;
    width: 500px;
    padding: 30px;
    p{
      font-weight: 600;
      // color: #6a8c3a;
    }
  }
  
  .previwBtn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-width: thin;
    border-radius: 17px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 50px;
  }

  .previwBtn:hover{
    box-shadow: 2px 2px 1px 1px gray;
  }
  
  .continueBtn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-width: thin;
    border-width: thin;
    border-radius: 17px;
    font-size: 15px;
    font-weight: 500;
  }

  .continueBtn:hover{
    box-shadow: 2px 2px 1px 1px gray;
  }

  @media(max-width:1280px){
    .buttonsContainer {
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;
      margin-left: 78%;
    }
    .cancelBtn {
      width: 100px;
      height: 30px;
      font-size: 13px;
    }
    .saveBtn {
      width: 100px;
      height: 30px;
      font-size: 13px;
    }
  }
  
