.restInfoContainer{
  display: flex;
  flex-direction: row;
  width: 80%;
}

.info{
    margin-top: 30px;
    margin-bottom: 30px;
    // margin-left: -24%;
    .restaurantImages{
      margin-left: 7%;
    }
}

.videoTitle{
    margin-top: 20px;
    margin-left: 29px;
}

.restaurantInfo{
    .imageTitle{
        width: 300px;
        text-align: left;
        margin-top: 20px; 
    }
}

.PreviewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    top: -13%;
    right: 15%;  
    height: 100%;   
    img {
      // margin-top:-250px ;
      // margin-right: 350px;
      position: fixed;
      width: 400px;
      height: 400px;    
    }
    &.show-preview img {
      display: block;   
    }
  } 
  
.image{
    width: 50%;
  }

  .headers{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 5px;
    img{
      margin-left: 25px;
      margin-top: 20px;
      cursor: pointer;
    }
    .tooltip {
      display: flex;
      position: relative;
    }
    
    .tooltip .tooltiptexts {
      visibility: hidden;
      width: 500px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 50%;
      right: 50%;
      margin-left: 40px; 
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltip:hover .tooltiptexts {
      visibility: visible;
      opacity: 1;
    }
  }
  .header6{
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      margin-left: -180px;
      margin-top: 22px;
      cursor: pointer;
    }
    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptexts {
      visibility: hidden;
      width: 500px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 50%;
      right: 50%;
      margin-left: -140px; 
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltip:hover .tooltiptexts {
      visibility: visible;
      opacity: 1;
    }
  }
  .header3{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img{
      margin-left: -105px;
      margin-top: 22px;
      cursor: pointer;
    }
    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptexts {
      visibility: hidden;
      width: 500px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 50%;
      right: 50%;
      margin-left: -10px; 
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltip:hover .tooltiptexts {
      visibility: visible;
      opacity: 1;
    }
  }
  .header4{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img{
      margin-left: -80px;
      margin-top: 22px;
      cursor: pointer;
    }
    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptexts {
      visibility: hidden;
      width: 500px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 50%;
      right: 50%;
      margin-left: 10px; 
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltip:hover .tooltiptexts {
      visibility: visible;
      opacity: 1;
    }
  }
  .header5{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img{
      margin-left: -205px;
      margin-top: 22px;
      cursor: pointer;
    }
    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptexts {
      visibility: hidden;
      width: 500px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 50%;
      right: 50%;
      margin-left: -70px; 
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltip:hover .tooltiptexts {
      visibility: visible;
      opacity: 1;
    }
  }


@media(max-width:1280px){
    .restaurantVideo{
        
        .videoTitle{
            margin-top: 20px;
            font-size: 13px;
        }
    }
    .restaurantImage{
        .imageTitle{
            width: 350px;
            margin-top: 20px;
            font-size: 13px;
        }
    }
    .PreviewContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute; 
      top: -19%;
      right: 15%;  
      height: 100%;   
      img {
        // margin-top:-250px ;
        // margin-right: 350px;
        position: fixed;
        width: 350px;
        height: 350px;    
      }
      &.show-preview img {
        display: block;   
      }
    } 
}