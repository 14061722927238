.headerV2 {
  width: 98%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 20px;
  border-bottom: 1px solid #F4F4F4;

  .v2-menuCount {
    font-size: 24px;
    font-weight: 500;
    padding: 0;
    margin: 0 95px 0 0;
  }

  .v2-searchBar {
    width: 500px;
    margin-right: 10vw;
  }

  .v2-searchBar1{
    width: 500px;
    margin-right: 19vw;
  }

  .filterProd {
    width: 45px;
    height: 52px;
    cursor: pointer;
  }

  .sync-container{
    width: 99px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: #E52333;
    border: 1px solid #E52333;
    border-radius: 8px;
    cursor: pointer;
    padding: 0 5px;
    font-size: 16px;
    font-weight: 500;

    img{
        width: 20px;
        height: 20px;
    }
  }

  .sync-container-loading{
    width: 99px;
    max-width: 99px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: #E52333;
    border: 1px solid #E52333;
    border-radius: 8px;
    cursor: pointer;
    padding: 0 5px;
    font-size: 16px;
    font-weight: 500;    
    overflow: hidden;

    img{
        width: 20px;
        height: 20px;
    }
  }

  .addItem-header{
    cursor: pointer;
    margin-top: 2px;
    margin-right: 22px;

    img{
        width: 137px;
        height: 49px !important;
    }
  }
}
