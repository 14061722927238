.imagesselected {
  width: 20rem;
  height: 10rem;

  .images1,
  .images2 {
    height: 7.5rem;
    overflow-y: auto;
    border-right: 1px solid #979797;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    /* Add a min-height to ensure the container is scrollable */
    min-height: 100px;
    font-size: 12px;

    .selectedingredientsimage,
    .selectedallergenimage {
      display: flex;
      width: auto;
      height: auto;
      padding: 10px;
      gap: 10px;

      img{
        width: 20px;
        height: 20px;
      }
    }

    &::-webkit-scrollbar {
      display: block;
      width: 0.5rem;
      height: 10px;
      background-color: #8f8f8f;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #4c4c4c;
      border-radius: 0.6rem;
      height: 0.5rem;
    }
  }
}

// ::-webkit-scrollbar {
//   display: block;
//   width: 1rem;
//   height: 10px;
//   background-color: #8f8f8f;
//   border-radius: 1rem;
//   &::-webkit-scrollbar-thumb {
//     background-color: #4c4c4c;
//     border-radius: 1rem;
//     height: 3rem;
//   }
// }

/* Custom Scrollbar Styling */
// ::-webkit-scrollbar {
//   width: 2rem;
//   height: 10px;
//   //   background-color: lightgreen;
//   ::-webkit-scrollbar-thumb {
//     background-color: #888;
//     border-radius: 10px;
//     height: 3rem;
//   }
// }

// ::-webkit-scrollbar-track {
//     background-color: #f1f1f1;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//     background-color: #888;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background-color: #555;
// }
