.offerTable{
  width: 100%;
  display: flex;
}

.offerTable1{
  width: 100%;
  display: flex;
}

.offerTableBody{
  width: 82%;
}

.OfferSideNavbarContainer {
  display: flex;
  justify-content: center;
}
.down-arrow-price
{
  rotate: 180deg;
}
.table-container {
  width: 166%; 
  height: 80vh;
  overflow-y: auto; 
  overflow-x: auto;
  
  font-family: "Poppins";
  margin-left: -20px;
}

.table-container1 {
  width: 135%;
  height: 80vh;
  overflow-y: auto; 
  overflow-x: auto;
  font-family: "Poppins";
  transition: transform 0.2s ease-in;
}

.tableHeading{
  position: relative;
  margin-left: -30px;
}

.tableHeading1{
  position: relative;
  margin-left: -20px;
}

.OffrtsTabletho {
  position: sticky;
  top: 0;
  z-index: 1;
}


.OffersTableo {
  transform: translateX(20px);
  width: 63.5%;

  border-collapse: collapse;
  //margin-left: -30px;
  // height: 50vh;
}

.OffersTable1o {
  transform: translateX(20px);
  width: 85.5%;
  border-collapse: collapse;
  margin-left: -20px;
  // height: 50vh;
 

}
.spaction-container {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
}
.spaction-container1 {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
  margin-right: 49px;
}

.duration{
  width: 240px;
}

th.OffrtsTabletho,
td.OffrtsTabletd {
  text-align: left;
  padding: 12px;
  padding: 20px;
  padding-left: 5px;
  cursor: pointer;
  // background-color: pink;
 
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

th.OffrtsTabletho {
  background-color: white;
  position: relative;
  
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.OfferTableHeadingo{

  position: relative;
  left: -1.5rem;
top: -0.2rem;
  font-family: "Poppins",sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  
}

th.OffrtsTabletho:nth-child(1) {
  width: 250px;
  padding-left: 60px;
  position: sticky;
  top: 0;
}

th.OffrtsTabletho:nth-child(2) {
  width: 200px;
  padding-left: 30px;
  position: sticky;
  top: 0;
}

th.OffrtsTabletho:nth-child(3) {
  width: 300px;
  padding-left: 28px;
  position: sticky;
  top: 0;
}

th.OffrtsTabletho:nth-child(4) {
  width: 220px;
  padding-left: 43px;
  position: sticky;
  top: 0;
}

th.OffrtsTabletho:nth-child(5) {
  width: 200px;
  position: sticky;
  top: 0;
}

th.OffrtsTabletho:nth-child(6) {
  width: 200px;
  position: sticky;
  top: 0;
  padding-left: 30px;
}

th.OffrtsTabletho:nth-child(7) {
  width: 100px;
  // background-color: red;

  position: sticky;
  top: 0;

}

.OffrtsTabletr{
}



tr.OffrtsTabletr:nth-child(odd) {
  background-color: #e8e8e8;

}

td.OffrtsTabletd:nth-child(4) {
  padding-right: 30px;
  width: 200px;
  // border: 1px solid red;
  padding-left: 20px;
  // height: 3rem;
  min-height: 3rem;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;



}

td.OffrtsTabletd:nth-child(3) {
  width: 300px;
  padding-left: -10px;
  min-height: 3rem;
 
}

td.OffrtsTabletd:nth-child(1) {
  width: 270px;
  padding-left: 35px;
  min-height: 3rem;
}

td.OffrtsTabletd:nth-child(5) {
  padding-left: 30px;
  min-height: 3rem;
}

td.OffrtsTabletd:nth-child(2) {
  width: 200px;
  min-height: 3rem;
}

td.OffrtsTabletd:nth-child(6) {
  padding-left: 34px;
  min-height: 3rem;
}

td.OffrtsTabletd:nth-child(7) {
  padding-left: 34px;
  width: 100px;
  min-height: 3rem;
}

td.OffrtsTabletd{
  min-height: 3rem;
}

.extra-items {
  color: green;
  font-size: 0.9em;
  margin-left: 5px;
}

.day-icons span {
  display: inline-block;
  margin-right: 4px;
  background-color: #e0e0e0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}
.OfferDiv {
  border: 0;
  height: 1px;
  background-color: #e9e9e9; 
  margin-top: -20px;
  width: 800%;
}
.OfferDiv2 {
  border: 0;
  height: 1px;
  background-color: #e9e9e9; 
  margin-top: -20px;
  width: 900%;
}
.RowDuration {
  display: flex;
  flex-direction: column;
}

.OffersDropDownTable {
   position: absolute;
   right: 250px;
   left: -10rem;
  // top: -4rem;
 
}
.Menu-noOptions-offertable
{
  display: flex;
  position: absolute;
  top: 16rem;
  left: 40%;
}
.table-body-data-offer
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.NoDataFoundContainer-offer
{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10rem;
  left: 35%;

}

.action-icon-container
{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-self: center;
  // border: 1px solid red;
  padding-left: 5px;
  padding-right: 5px;
}