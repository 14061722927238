.input-and-spantext {
  width: 19rem;
  height: 2.8rem;
}
.Input-Filed {
  width: 20vw;
  height: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  padding-left: 0.625rem;
  border-radius: 0.313rem;
  color: black;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  &:focus {
    outline: none;
  }
}
.Input-Field-Error-message {
  position: relative;
  top: -20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
}
.input-and-spantext {
  width: 21vw;
  position: relative;
}
.placeholder {
  position: absolute;
  right: 6%;
  color: #979797;
  font-weight: 400;
  top: 20%;
  font-size: 0.875rem;
}


.itemCode-Success-validation{
  position: relative;
  top: -15px;
  color: #e52333;
  width: 300px;
  margin-bottom: 20px;
}