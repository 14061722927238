:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ccc;
}

.t-dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #404d66;
}

.t-light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ccc;
}

.t-report-pagination {
  width: 69%;
  border: 1px solid var(--table-text);
  background-color: var(--table-bg);
  color: var(--table-text);
  padding: 0.7rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 20rem;

  .t-table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      padding-left: 1rem;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: inherit;
      z-index: 1;
    }

    .t-side-attribs {
      display: flex;
      .t-expand-all {
        cursor: pointer;
        background-color: var(--navbar-bg);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        color: #fff;
        font-size: 20px;
      }
      .t-export-container {
        position: relative;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s, border-color 0.3s;
        background-color: var(--table-bg);
        border-radius: 0.8rem;

        img {
          background-color: var(--table-bg);
          color: var(--table-text);
          height: 1.8rem;
          width: 1.8rem;
          border-radius: 1rem;
          transition: background-color 0.3s, border-color 0.3s;

          &:hover {
            background-color: var(--navbar-bg);
            border-color: var(--navbar-bg);
          }
        }

        .t-export-drop-down {
          position: absolute;
          top: 100%;
          right: 0;
          background-color: white;
          border: 0.1rem solid #ddd;
          border-radius: 0.4rem;
          box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.1);
          z-index: 1000;

          opacity: 0;
          visibility: hidden;
          // transform: translateY(1rem);
          transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

          p {
            text-align: center;
            padding: 0.5rem 1rem;
            margin: 0;
            cursor: pointer;
            font-size: 0.9rem;
            color: black;
            transition: background-color 0.3s, color 0.3s, padding 0.3s;

            &:not(:last-child) {
              border-bottom: 0.1rem solid #ddd;
            }

            &:hover {
              background-color: #f4f4f4;
              color: #283347;
              // padding: 0.5rem 1.5rem;
              // transform: translateX(0.5rem);
            }
          }
        }

        &:hover .t-export-drop-down {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }

  .t-table-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--table-bg);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--navbar-text);
      border-radius: 10px;
      border: 2px solid var(--table-bg);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--navbar-text);
    }

    scrollbar-width: thin;
    scrollbar-color: var(--navbar-text) var(--table-bg);
  }

  .t-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    font-size: 0.8125rem;

    .t-expand-icon {
      cursor: pointer;
      text-align: center;
    }

    .t-nestedTable {
      width: 100%;
      background-color: #f9f9f9;
      border: 1px solid #ddd;

      .t-nestedHeader {
        background-color: #f0f0f0;
      }
      td {
        padding: 5px;
      }
    }

    tr {
      .t-tableHeader,
      .t-tableRow {
        display: flex;
      }

      .t-tableHeader {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        font-size: 2rem;
        font-weight: 600;
        width: 12.5rem;
      }

      .t-header,
      .t-tableCell {
        padding: 0.5rem;
        text-align: left;
        font-size: 0.76rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--table-text);
      }

      .t-header {
        font-weight: bold;
        font-size: small;
        width: fit-content;
        background-color: var(--screen-bg);

        &.t-align-right {
          text-align: right;
        }

        &.t-align-center {
          text-align: center;
        }

        .t-sort-icon {
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }

      .t-tableCell.t-align-right {
        text-align: right;
      }

      .t-tableCell.t-align-center {
        text-align: center;
      }
    }
  }
}

.t-paginationCursor {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: inherit;
}

.t-pagenationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: none;

  li {
    list-style: none;

    img {
      cursor: pointer;
      filter: invert(100%);
    }

    .t-leftArrow {
      transform: rotate(180deg);
    }
  }
}

.t-table-container-one {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.t-half-width {
  width: 47% !important;
  height: 20rem;

  @media (max-width: 480px) {
    width: 100% !important;
    margin-left: 0.8rem !important;
  }

  @media (max-width: 500px) {
    width: 100% !important;
  }

  @media (max-width: 800px) {
    width: 79% !important;
  }
}


.r-table-no-data{
  background-color: var(--screen-bg);
  text-align: center;
  height: 32vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
}

.reports-table-loader {
  width: 2rem;
  height: 2rem;
  border: 3px solid var(--table-text);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute; /* Overlay on .s-box */
  left: 45%;
  top: 45%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
