:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #404d66;
  --cchart-bg: #ffffff; /* Default light theme color */
  --cchart-border: #000000;
}

.dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #283347;
  --cchart-bg: #222b3c;
  --cchart-border: #ffffff;
}

.light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ffffff;
  --cchart-bg: #ffffff;
  --cchart-border: #000000;
}

.ch-expanded-boxes {
  width: 13.5rem !important;
  @media (max-width: 1280px) {
    width: 11.2rem !important;
  }
  @media (max-width: 1080px) {
    width: 9.5rem !important;
  }
}

.checkin-container {
  background-color: var(--screen-bg);
  color: var(--screen-bg);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // width: 91vw;
  // margin-left: -1.5rem;
  padding-bottom: 5vh;

  @media (max-width: 500px) {
    width: 100%;
  }
  .checkin-head {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    @media(max-width: 500px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .checkin-name-board {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--table-text);

      h1 {
        font-size: 21px;
        font-weight: 500;
      }
      @media (max-width: 500px) {
        font-size: xx-small;
      }
      @media (max-width: 412px) {
        font-size: xx-small;
      }
    }
    .checkin-date-filter-container{
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: pink;
    }
    // .dates {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 0.8rem;
    //   height: 6rem;
    //   .label-time-period {
    //     font-weight: 600;
    //     color: var(--table-text);
    //     @media (max-width: 800px) {
    //       display: none;
    //     }
    //   }
    //   .filter-toggle-btn-container {
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     gap: 0.6rem;

    //     .filter-toggle-btn {
    //       text-align: center;
    //       padding: 0.5rem;
    //       width: 10rem;
    //       height: 2rem;
    //       color: #000;
    //       background-color: #ccc;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       border: 1px solid #000;
    //       border-radius: 0.8rem;
    //       cursor: pointer;
    //       @media (max-width: 800px) {
    //         width: 12vw;
    //         height: 1rem;
    //         font-size: x-small;
    //       }
    //       @media(max-width: 500px){
    //         width: 24vw;
    //       }
    //       @media(max-width: 400px){
    //         width: 24vw;
    //       }
    //     }

    //     .filter-drop-down-options {
    //       position: absolute;
    //       top: 110%;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       width: 12rem;
    //       padding: 0.8rem;
    //       background-color: var(--navbar-bg);
    //       color: var(--navbar-text);
    //       border: 1px solid #ccc;
    //       box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    //       z-index: 999;
    //       border-radius: 1rem;
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       justify-content: center;
    //       @media (max-width: 800px) {
    //         width: 12vw;
    //         font-size: x-small;
    //       }
    //       @media(max-width: 500px){
    //         width: 24vw;
    //       }
    //       // @media(max-width: 400px){
    //       //   width: 24vw;
    //       // }

    //       p {
    //         margin: 0;
    //         padding: 0.5rem;
    //         cursor: pointer;
    //         border-radius: 0.6rem;
    //         text-align: center;
    //         &:hover {
    //           background-color: var(--navbar-text);
    //           color: var(--navbar-bg);
    //         }
    //         @media (max-width: 800px) {
    //           width: 5rem;
    //           font-size: x-small;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .ch-date-range-style {
    display: flex;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    width: 100%;

    .ch-dateLabel {
      align-items: flex-start;
      margin-right: 0.5rem;
      color: var(--table-text);
    }

    .ch-start-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
      margin-right: 1rem;
    }

    .ch-end-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }
  }
  .checkin-name-board-two {
    text-align: center;
    color: var(--table-text);

    h1 {
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    @media (max-width: 500px) {
      font-size: x-small;
    }
  }
  .overall-summary {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 800px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
    .box {
      height: 9rem;
      width: 14.8rem;
      border-radius: 1rem;
      background-color: var(--table-bg);
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: var(--table-text);
      border: 1px solid;
      @media (max-width: 1280px) {
        width: 12.3rem;
        font-size: smaller;
      }
      @media (max-width: 1080px) {
        width: 10.4rem;
        font-size: smaller;
      }
      @media (max-width: 800px) {
        width: 35%;
        font-size: smaller;
      }
      @media (max-width: 500px) {
        width: 85%;
        font-size: smaller;
      }
      h2 {
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
      }
      h3 {
        margin-bottom: 0rem;
        margin-top: 0rem;
      }
    }
  }

  .daily-summary-container{
    // background-color: pink;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    
    .daily-heading{
      // background-color: paleturquoise;
      h1 {
        color: var(--table-text);
        text-align: center;
        font-size:  x-large;
      }
    }
    .daily-summary-inner-container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5vw;
      // background-color: palegreen;
      @media(max-width: 500px){
        display: flex;
        flex-direction: column;
        gap: 1.5vh;
      }
    }
  }

  .three-boxes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    gap: 0.3rem;
    // background-color: pink;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 23.3%;
      height: 100%;
      text-align: center;
      border: 1px solid var(--table-text);
      border-radius: 1rem;
      color: var(--table-text);
      font-weight: 600;
      font-size: larger;
      background-color: var(--table-bg);
      .upper-value {
        margin-bottom: 0;
      }
      .lower-value {
        margin-top: 0;
      }
    }
  }
  .daily-checkin-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .canva-stacked-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .repeat-customers-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-boxes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18rem;
    gap: 0.3rem;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 35%;
      height: 100%;
      text-align: center;
      border: 1px solid var(--table-text);
      border-radius: 1rem;
      color: var(--table-text);
      font-weight: 600;
      font-size: larger;
      background-color: var(--table-bg);
      .upper-value {
        margin-bottom: 0;
      }
      .lower-value {
        margin-top: 0;
      }
    }
  }
  .dinein-time-chart-container {
    width: 69%;
    border-radius: 1rem;
    margin-left: 14.9%;
  }
  .day-of-the-week {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .day-of-the-week-inner {
      width: 70%;
      @media (max-width: 500px) {
        width: 85%;
      }
    }
  }
  @import "~react-datepicker/dist/react-datepicker.css";
  .date-picker-style {
    display: flex;
    justify-content: center;
    align-items: center;
    .react-datepicker {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 0.5rem;

      &__header {
        background-color: #007bff;
        border-bottom: 1px solid #007bff;

        .react-datepicker__current-month {
          font-size: 1.25em;
          color: #fff;
        }

        .react-datepicker__day-name {
          color: #e9ecef;
        }
      }

      &__day {
        color: #007bff;

        &:hover {
          background-color: #e9ecef;
        }

        &--today {
          font-weight: bold;
          color: #28a745;
        }

        &--selected {
          background-color: #28a745;
          color: #fff;

          &:hover {
            background-color: #218838;
          }
        }
      }
    }
  }
}
