.Item-Selection {
  // border: 1px solid rgba(0, 0, 0, 0.3);
  // width: 40.5rem;
// height: 331px;
height: 100%;

  .Item-Selection-heading {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #666666;
    padding-left: 0.625rem;
    padding-top:0.5rem ;
    // position: relative;
    // top: 1rem;
  }
  .Item-selection-Search {
    display: flex;
    justify-content: left;
    align-items: center;
   
    .searchicon {
      margin-left: -40px;
      display: flex;
      align-items: center;
    }
  }

  .Item-selction-input-Field {
    width: 18.9rem;
    height: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    padding-left: 0.625rem;
    border-radius: 0.313rem;
    color: #979797;
    font-weight: 400;
    // border: 0.063rem solid #000000;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0.625rem;
  }
  .Item-selction-itemset {
    margin: 0;
    padding: 0;
    height: 68%;
    // border: 1px solid red;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 0.5rem;
    // top: 1rem;
    // @media (max-width: 2400px) {
    //   height: 18rem;
       
    //    }

    // @media (max-width: 2000px) {
    //   height: 16rem;
       
    //    }
    //    @media (max-width: 1770px) {
    //    height: 13.5rem;
        
    //     }
    //    @media (max-width: 1600px) {
    //    height: 11.5rem;
    //     //  border: 1px solid blue;
        
    //     }

    // @media (max-width:1525px) {
    //   height: 10rem;
    // }


    @media (max-width:1400px) {
      height: 65%;
    }

    @media (max-width:1300px) {
      height: 60%;
    }
    // background-color: pink;

    &::-webkit-scrollbar {
      display: block;
      background-color: #8f8f8f;
      width: 8px;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4c4c4c;
      border-radius: 1rem;
      height: 3rem;
    }

    .Selected-Images {
        // background-color: pink;
        padding: 0;
        margin: 0;
      width: 100%;
      flex-direction: row;
      display: flex;
      // padding: 0.5rem;
      padding-bottom: 0;
      gap: 0.7rem;
      flex-wrap: wrap;

      .Selected-Image-Item {
        width: auto;
        border: 1px solid #b3b3b3;
        border-radius: 0.313rem;
        // padding: 0.5rem;
        // padding-bottom: 0;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        font-size: 12px;
        font-weight: 300;
        height: 20px;
        // white-space: nowrap;

        img{
          width: 15px;
          height: 15px;
        }

        .Selected-Image-Item-Deletion {
          cursor: pointer;
          opacity: 65%;
        }
      }
    }

    .AllergensImage {
      margin: 0;
      padding: 0;
      width: 100%;
      flex-direction: row;
      display: flex;
      // padding: 0.5rem;
      // padding-top: 0;
      gap: 0.5rem;
      flex-wrap: wrap;
      margin-top: 0.3rem;
    }
  }
  

  

  li {
    list-style: none;
  }
}


// .AllergensImage .Item-Selection-option {
  
//   transition: transform 0.3s ease;
//   width: 50px;
//   height: auto;
//   // padding: 20px;
//   cursor: pointer;
//   display: flex;
//   border: 1px solid red;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-size: 12px;
//   img{
//     width: 21px;
//     height: 21px;
//   }
//   .optionName{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     max-width: 100%;
//     height: auto;
//     border: 1px solid blue;
//     // height: 100px;
//     flex-wrap: wrap;
//     padding-left: 1rem;
//     padding-right: 1rem;
//     // max-width: 80px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }

// .AllergensImage .Item-Selection-option.clicked img,
// .AllergensImage .Item-Selection-option.clicked .optionName {
//   transform: scale(0.8); /* Shrink the size */
//   transition: transform 0.3s ease;
// }

// .Item-Selection
// {
//   border: 1px solid red;
//   height: 100%;
// }

// .Item-Selection-heading{
//   font-size: 14px;
//     font-family: "Poppins", sans-serif;
//     font-weight: 400;
//     color: #666666;
// }

// .Item-selection-Search{
//   height: 6.5vh;
//   width: 100%;
//   display: flex;
//   border: 1px solid blue;
// }

// .Item-selction-input-Field
// {
//   width: 288px;
// height: 44.25px;

// border-radius: 5px;
// border: 1px solid rgba(0,0,0,0.3);


// }
// .Item-selction-itemset
// {
//   width: 100%;
//   height: 75%;
//  border: 1px solid red;
//   margin: 0;

//   overflow-y: scroll;
//   overflow-x: hidden;
//   padding: 0;
// }
// .selected-items-div{
//   width: 100%;
//   height: auto;
//  border: 1px solid green;
//   margin: 0;
//   padding: 0;
// }

// .items-to-select
// {
// display: flex;
// width: 70%;
// margin: 0;
// padding: 0;
// border: 1px solid blueviolet;
// flex-wrap: wrap;
// }

.item-image-class
{
  width: 12px;
  height: 12px;
 
  
  
 

}
.Selected-Image-Item-Deletion
{
  width: 10px;
  height: 10px;
}
// .Item-Selection-option
// {

// }

.items-to-select-div
{
  margin: 0;
  padding: 0;
  padding-left: 0.5rem;
  // padding-right: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  // border: 1px solid blue;
}

.selected-items-div
{
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  padding-right: 0.5rem;
  // border: 1px solid blue;
}

.AllergensImage {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: center;
  // border: 1px solid green;
  gap: 15px; /* Space between items */
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: -1rem;
}

.Item-Selection-option {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
gap: 0.2rem;
  text-align: center;
  cursor: pointer;
  width: 75px; /* Adjust as needed */
  margin-top: 1rem;
}

.Item-Selection-option img.item-image-class {
  width: 50px; /* Adjust image size */
  height: 50px;
  object-fit: cover;
  border-radius: 50%; /* Make images circular */
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}



.optionName {
  // margin-top: 8px;
  font-family: "Poppins",sans-serif;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  line-height: 12px;
  text-align: center;
  flex-wrap: wrap;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  // border: 1px solid red;
  
}

.Item-Selection-no-options {
  text-align: center;
  font-size: 14px;
  color: #888;
}
