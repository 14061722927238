.sptoggleBtn {
  background-color:rgba(208, 223, 185, 1);
  width: 28.8px;
  height: 10px;
  border-radius: 6.188rem;
  border: 0.063rem solid #979797;
 // box-shadow: 0.063rem 0.063rem 0.063rem rgba(208, 223, 185, 1);
  position: relative;
  cursor: pointer;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  top: 2px;
  left: -10px;
}

.spthumb {
  border-radius: 8.188rem;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  position: absolute;
  left: -1px;
  transition: left 0.2s;
  box-shadow: 0px 0px 1.44px 0px rgba(0, 0, 0, 0.1) inset;

box-shadow: 0px 0px 1.44px 0px rgba(0, 0, 0, 0.4);

}

.sptoggleBtn.Toggled {
  background-color: #E52333;
  border-color: #E52333;
}

.sptoggleBtn.Toggled .spthumb {
  left: calc(100% - 13px);
}
