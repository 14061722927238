.category-menu{
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    list-style: none;
    gap: 80px;
    border-bottom: 1px solid #B3B3B3;
  }
  .bottomborder{
    margin-left: 40px;
    width: 100%;
    margin-top: -25px;
    color: gray;
  }
  .topNavbar{
    a{
      text-decoration: none;
      color: gray;
      font-weight: 500;
    }
  } 
  .category-menu .active {
    hr{
      border: none;
      border-top: 1px solid #7a7a7a;
      background: white;
      cursor: pointer;    
      height: 4px;
      border-radius: 5px;
      background-color: #6A8C3A;
    }
    a{
      font-weight: 700;
      color: #6A8C3A;
    }
  }
  
  @media(max-width:1280px){
    .topNavbar{
      a{
        font-size: 13px;
        text-decoration: none;
        color: gray;
        font-weight: 500;
      }
    } 
    .category-menu .active {
      hr{
        border: none;
        border-top: 1px solid #7a7a7a;
        background: white;
        cursor: pointer;    
        height: 3px;
        border-radius: 5px;
        background-color: #6A8C3A;
      }
      a{
        font-size: 13px;
        font-weight: 700;
        color: #6A8C3A;
      }
    }
  }
  