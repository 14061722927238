.employeeDetails {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headLine {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.headLine h3 {
    margin-left: 10px;
}

.headLine img {
    width: 35px;
    cursor: pointer;
}

.disabledImg{
    cursor: not-allowed;
}

.hamburgerMenu{
    border: 1px solid black;
    width: fit-content;
    padding: 5px 20px;
    position: absolute;
    right: 30px;
    top: 55px;
    z-index: 999;
    background-color: white;
    box-shadow: 0px 0px 4px 1px #5d5e5c;
}

.actionTab {
    display: flex;
    cursor: pointer;
}

.actionTab img {
    width: 25px;
    margin-right: 10px;
}

.detailContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 120px;
    width: 100%;
    justify-content: flex-start;
}

.title {
    display: flex;
    font-size: 14px;
    width: fit-content;
    font-weight: 500;
}

.tag {
    margin-left: 10px;
    width: 130px;
}

.value {
    margin-left: 50px;
    margin-right: 20px;
    /* width: 200px; */
    /* margin-right: 10px; */
}


.dropDown {
    border: 1px solid black;
    width: fit-content;
    padding: 5px 20px;
    position: absolute;
    right: 50px;
    top: 55px;
    z-index: 99;
    background-color: white;
}

.rolesContainer {
    border: 1px solid #67833E;
    padding: 20px 40px;
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 90%;  
    height: 300px;
    overflow: auto;
}

.rolesContainer h4 {
    color: black;
    margin-bottom: 30px;
    font-weight: 600;
}

.roles {
    display: flex;
    align-items: flex-start;
    gap: 60px;
    text-align: left;
    flex-wrap: wrap;
}

.rolesHeading {
    font-size: 14px;
}

.backBtn {
    width: 120px;
    height: 40px;
    margin-bottom: 50px;
    border: none;
    color: white;
    font-style: 13px;
    font-weight: 600;
    background-color: #E52333;
    border-radius: 4px;
    cursor: pointer; 
    margin-left: 890px;
}


/* Fixed header styling */
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  /* Menu icon styling */
  .menu-icon {
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Popover menu styling */
  .popover-menu {
    position: absolute;
    top: 70px; /* Adjust based on header height */
    right: 5px; /* Align with header padding */
    width: 150px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1001;
  }
  
  .menu-list-a {
    list-style: none;
    padding: 0px 0;
    margin: 0;
  }
  
  .menu-item {
    padding: 5px 5px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    font-size: 16px !important;
  }
  
  .menu-item:hover {
    background-color: #f0f0f0;
  }

  .menuIcon{
    width: 20px !important;
    height: 20px;
    margin-right: 20px;
  }
  

