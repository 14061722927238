.EyeModal-Container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal vertically */
  z-index: 1000; /* Ensure it appears above other content */
  width: 29%;
  //   height: 848px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.EyeModal-Window {
  margin-top: 30px;
  background-color: white;
  width: 310px;
  height: 377px;
  display: flex;
  // border: 1px solid blue;
  justify-content: start;
  align-items: start; /* Center the content inside the modal */
  border-radius: 8px; /* Optional: add rounded corners */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
  max-height: 80vh; /* Restrict modal height */
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  } /* Enable scrollbar when content overflows */
}

.EyeModal-Form {
  display: flex;
  flex-direction: column;
}

.HideItemHeading-SelectAll-Container {
  width: 320px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 20px;
  // border: 1px solid red;
}

.HideItemHeading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.Select-all-heading {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e52333;
  text-decoration: underline;
  position: relative;
  top: 0.8rem;
  left: -60px;
  cursor: pointer;
}

.Radio-items-container {
  width: 280px;
  display: flex;

  padding: 20px 0px 0px 20px;
  flex-direction: column;
  max-height: 210px; /* Limit height for the items container */
  overflow-y: auto;
  // border: 1px solid red;

  // &::-webkit-scrollbar
  // {
  //     display: none;
  // }

  &::-webkit-scrollbar {
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d9d9d9;
  }
}

.Radio-Items-Heading {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
}

.Radio-Items-Flex {
  width: 261px;
  display: flex;
  justify-content: space-between;

  .checkbox-Items {
    position: relative;
    left: 1px;
    width: 20px;
    height: 20px;
    accent-color: #e52333;
    transform: translateY(10px);
  }
}

.Radio-sub-Items-Flex {
  width: 242px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  .checkbox-Items {
    width: 20px;
    height: 20px;
    accent-color: #e52333;
    transform: translateY(10px);
  }
}

.Radio-sub-Items-Heading {
  font-family: Poppins;
  font-weight: 400;
  width: 5rem;
  font-size: 16px;
  line-height: 40px;
  color: #5f5f5f;
  display: flex;
  justify-content: right;
  // border: 1px solid red;
  // margin-left: -1rem;
}

.saveCancelContainer {
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  top: 20px;
  // border: 2px solid;
  // left: 1vw;
  left: 0.3rem;
}
.SavebtnEye {
  width: 120px;
  height: 40px;
  background-color: #e52333;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 3.47px;
}
.cancelbtnEye {
  width: 120px;
  height: 40px;
  background-color: white;
  color: #e52333;
  text-align: center;
  cursor: pointer;
  border-radius: 3.47px;
  border: 0.5px solid #929292;
}

.Hide-Loader {
  width: 40px;
  height: 40px;
  align-items: center;
}
.spinner path {
  fill: white;
}
