.main-section {
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
  // background-color: greenyellow;

  & .menu {
    z-index: 9;
    background-color: #fff;
    // min-width: 150px;
    width: 6vw;

    padding: 2.5% 1.5%;
    border-right: 1px solid #c4c4c4;
    height: 100vh;
    box-sizing: border-box;
    position: fixed;
    transition: width 0.6s ease;
    overflow: hidden;
    @media (max-width: 1397px) {
      width: 7vw !important;
    }

    & .magilhub {
      width: 5vw;
    }

    &.expanded {
      width: 18%;
      transition: width 0.6s ease;
      overflow: hidden;

      li {
        margin-top: 20px;
      }
      .drop-down {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10.8%;
        width: 110%;
      }
      .menu-items-SVG {
        padding-right: 12px;
        padding-left: 6px;
        // padding-top: 1rem;
        height: 20px;
        width: 20px;
        // padding-bottom: 1.1%;
      }
      @media (max-width: 1397px) {
        width: 19% !important;
        // border: 1px solid red !important;
      }
    }

    & h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 50px;
    }
    & ul {
      padding: 0;
      padding-top: 20px;
      list-style: none;
      margin: 0;
      // list-style-type: disc;
      & a {
        color: #000;
      }
      & div {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 16px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      & li {
        // margin-bottom: 12%;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }

      & a {
        // margin-bottom: 30px;
        display: flex;
        align-items: center;
        // justify-content: center;
        align-content: center;
      }

      & .menu-items-SVG {
        padding-right: 12px;
        padding-left: 6px;
        height: 28px;
        width: 28px;
        // padding-top: 2rem;
        // padding-bottom: 1rem ;
        // border: 1px solid red;
      }

      // & .menu-items-SVG path {
      //   fill: black !important;
      // }

      & .active {
        color: #E52333;
        list-style-type: disc;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        & .menu-items-SVG path {
          fill: #E52333 !important;
          height: 28px;
          width: 28px;

          //stroke: #E52333;
        }
      }

      & ul {
        // padding-left: 47px;
        padding-top: 0px;

        & li {
          font-size: 16px;
        }
      }

      & .drop-down {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30%;
      }
      .btn-nav {
        width: 13px;
        height: 15px;
        color: black;
        text-decoration: none;
        font-size: 10px;
        background-color: #E52333;
        border-radius: 50%;
        padding: 5px;
        color: #d9d9d9;
        position: fixed;
        top: 101px;
        left: -12px;
        cursor: pointer;
      }
      .menu-items-name {
        @media (max-width: 768px) {
          font-size: 9px;
        }
      }
    }
    @media (max-width: 1024px) {
      padding: 4.5% 20px 11% 20px;
    }

    @media (max-width: 768px) {
      justify-content: center !important;
    }
  }
  @supports (position: sticky) {
    .is-sticky {
      position: sticky;
      top: 0; // Threshold is required
    }
  }
  & .menu-sec {
    width: 100%;
  }
  & .menu-items {
    width: 80%;
    padding-left: 39px;
    padding-right: 108px;
    padding-top: 4.5%;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      padding-right: 39px;
    }
    @media (max-width: 768px) {
      padding: 0 15px;
    }

    & #employee_header {
      display: flex;
      width: 90%;
      justify-content: space-between;
      align-items: flex-start;

      & p {
        font-size: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    & .Dropdown-control {
      margin-top: 0px;
      & .placeholder-class {
        color: #979797;
        @media (max-width: 575px) {
          font-size: 12px;
        }
      }

      & .is-selected {
        color: #000000;
      }
    }
    & .header {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      //justify-content: space-between;
      padding-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid #dadada;
      & div {
        display: flex;
        flex-direction: column;
        //height: 40px;
        justify-content: space-between;

        & p {
          margin: 0px;
        }
      }
      & div p:nth-child(1) {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
      }
      & div p:nth-child(2) {
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        white-space: nowrap;
        padding-top: 5px;
      }
      & .user-profile {
        margin-left: 82%;
        width: 50px;
        height: 50px;
      }
      .logo-container {
        display: none;

        @media (max-width: 575px) {
          display: flex;
          width: 184px;
          justify-self: flex-start;
          flex-direction: row;
        }
      }

      @media (max-width: 575px) {
        justify-content: space-between;
        margin-top: 32px;
      }
    }
    & .header-menu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 25px;

      & .menu-items-SVG path {
        fill: #E52333 !important;
      }

      & div {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          text-align: center;
          font-size: 14px;
        }
        & h2 {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #E52333;
          margin-left: 10px;
          white-space: nowrap;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }

      & > div {
        @media (max-width: 768px) {
          width: 100%;
          justify-content: space-evenly;
          margin-bottom: 24px;
        }

        @media (max-width: 575px) {
          justify-content: space-between;

          &.Dropdown-root {
            justify-content: center;
            width: 100%;
          }
        }
      }

      @media (max-width: 575px) {
        display: none;
      }
    }
    & .drop-list {
      width: 40%;
    }

    & .empty-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 83px;
      
      & img {
        width: 245.41px;
        height: 215px;
        object-fit: contain;
        margin-bottom: 62px;
      }
      & h3 {
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
      }
    }
    @media (max-width: 575px) {
      width: 100%;

    }
  }

  & .add-custom {
    margin-top: 20px;
  }
  & .create-menu {
    width: 100%;
    text-align: right;
    & button {
      cursor: pointer;
      border: 0;
      background: #00b71d;
      border-radius: 50%;
      color: #fff;
      padding: 7px 8px;
      & svg {
        font-size: 34px;
      }
    }
  }

  & .menu-list {
    width: 100%;
    display: table;
    & table {
      border-collapse: collapse;
      & thead {
        & th {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          font-weight: normal;
          text-align: left;
          padding-bottom: 13px;
          border-bottom: 1px solid #c4c4c4;
          padding-left: 10px;
        }
      }

      & td {
        padding: 20px 0px;
        border-bottom: 1px solid #e5e5e5;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        position: relative;
        padding-left: 10px;
        & svg {
          cursor: pointer;
        }

        & ul {
          position: absolute;
          right: 20px;
          top: 24px;
          background: #fff;
          padding: 0;
          list-style-type: none;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 13px 17px 0px;
          z-index: 9999;
          box-sizing: border-box;

          & li {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #000000;
            margin-bottom: 17px;
          }
        }
      }
      & tr:nth-of-type(2n) {
        background: rgba(196, 196, 196, 0.4);
      }
      & .eye-icon {
        & img {
          width: 25px;
          height: 25px;
          object-fit: contain;
          cursor: pointer;
        }
      }
      & .item-type {
        & img {
          width: 11px;
          height: 11px;
          object-fit: contain;
          margin-right: 4px;
        }
      }
      & .custom {
        color: #0085ff;
        cursor: pointer;
      }
      & .edit-icon {
        padding-right: 10px;
        & svg {
          font-size: 20px;
          color: #0085ff;
          cursor: pointer;
        }
      }
      & .trash-icon {
        & svg {
          font-size: 20px;
          color: #000;
          opacity: 0.3;
          cursor: pointer;
        }
      }
      & .checkbox-label {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 26px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      & .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #9a9a9a;
        box-sizing: border-box;
        border-radius: 2px;
      }

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        background-color: #fff;
      }

      /* When the checkbox is checked, add a blue background */
      & input:checked ~ .checkmark {
        background-color: #2196f3;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      & .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      & input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      & .checkmark:after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    & .add-button {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1%;
      border-radius: 50%;
      background-color: #E52333;
      border: 0px;
      bottom: calc(var(--wHeight) * 0.1);
      right: 4.5%;
      align-self: flex-end;
      cursor: pointer;
    }
    & .add-button:focus {
      outline: none;
    }
  }
  .logout-user {
    img {
      @media (max-width: 768px) {
        height: 15px;
      }
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .menu.is-sticky {
    @media (max-width: 575px) {
      display: none;
    }
  }
}

iframe {
  width: 100%;
  overflow: scroll;
}

.menuClassName {
  display: flex;
  flex-direction: column;
}

.add-employee-dropdown {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.add-employee-text-input {
  padding-top: 10px;
  padding-bottom: 10px;
}

.add-employee-dropdown-arrow {
  margin-top: 5px;
}

.report-dropdown {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  width: 400px;
  margin-top: 25px;

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 1024px) {
    width: 200px;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 575px) {
    width: 80%;
  }
}

.report-dropdown-arrow {
  margin-top: 5px;
}

.MenuClass {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 575px) {
    width: 80%;
  }

  & div {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.error-msg {
  color: red;
  margin: 0px;
  position: absolute;
  margin-top: -23px;
}

.Dropdown-control .report-dropdown {
  margin-bottom: 0px !important;
  & .placeholder-class {
    color: #979797;
  }

  & .is-selected {
    color: #000000;
  }
}

.tab {
  margin-left: 3rem !important;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.selected {
  border-bottom: 3px solid #E52333;
  color: #E52333;
}

.unselected {
  border-bottom: 3px solid #fff;
  color: rgba(0, 0, 0, 0.5);
}

.logo-container {
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: -3vh;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.restaurant-logo {
  width: 60px;
  height: 60px;
  padding-top: 1rem;
}

.restaurant-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-align: start;

  @media (max-width: 575px) {
    text-align: left;
  }
}

.branch-dropdown {
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  margin-left: -4px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 120px;
  @media (max-width: 575px) {
    max-width: 115px;
  }
}

.restaurant-name-container {
  padding-left: 1rem;
  line-height: 1em;
  color: #000000;

  @media (max-width: 1024px) {
    padding-left: 0;
  }

  & > div {
    @media (max-width: 1224px) {
      text-align: start;
      margin-top: 8px;
    }
  }
}

.report-checkin-dropDown {
  @media (max-width: 575px) {
    display: none;
  }
}

.reportData-mobile {
  margin-top: 25px;
  // display: none;

  @media (max-width: 575px) {
    display: flex;
    padding-bottom: 50px;
  }
}

.reportData-desktop {
  display: flex;

  @media (max-width: 575px) {
    display: none;
  }
}

.magilhub-bottom-logo {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 5%;
  width: "100%";
  align-items: flex-end;
  justify-content: center;
  // align-self: center;
  // align-items: center;
}

.magilhub-logo1 {
  font-family: Righteous;
  font-weight: normal;
  color: #e52333;
  font-size: 20px;
  // margin-left: 1rem;
  // padding-left: 50px;
}

.powered-text1 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #000000;
  margin-right: 8px;
}

.merchant-footer-logo-container3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  /* margin-bottom: 1%; */
  margin-top: 1%;
  background-color: transparent;
  margin-right: 2%;
}

.headLine2 {
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  margin-top: -30px;
  padding-top: 20px;
  z-index: 999;
  // style={{
  //   position: 'fixed',
  //   marginBottom:'40px',
  //   width: '100%',
  //   zIndex: '1000',
  //   backgroundColor: '#fff',
  //   height:'100px',
  //   fontSize: '24px',
  //  lineHeight: '36px'
  //  }}
}

.branch-dropdown:disabled {
  appearance: none; /* Hide the default dropdown arrow */
  -moz-appearance: none; /* For Firefox */
  -webkit-appearance: none; /* For Safari and Chrome */
  // background-color: #e0e0e0; /* Optional: Gray out the background for disabled look */
}

.branch-dropdown:disabled::-ms-expand {
  display: none; /* Hide the arrow for Internet Explorer */
}

.btn-nav {
  width: 12px;
  height: 15px;
  color: black;
  text-decoration: none;
  font-size: 10px;
  background-color: #E52333;
  border-radius: 50%;
  padding: 5px;
  color: #d9d9d9;
  position: fixed;
  top: 101px;
  margin-left: -12px;
  cursor: pointer;
}

.btn-nav1 {
  width: 12px;
  height: 15px;
  color: black;
  text-decoration: none;
  font-size: 10px;
  background-color: #E52333;
  border-radius: 50%;
  padding: 5px;
  color: #d9d9d9;
  position: fixed;
  top: 101px;
  margin-left: -12px;
  cursor: pointer;
  transform: rotate(180deg);
}

.menuList {
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 1080px) {
  .menu-items-name {
    font-size: 13px;
  }

  .main-section .menu.expanded {
    width: 23%;
  }

  .main-section {
    .down {
      margin-left: 10px;
    }
    .menuList {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .main-section .expanded .drop-down {
    margin-left: -10px;
  }
  .expanded .active {
    margin-left: -10px;
  }
}

@media (max-width: 900px) {
  .menu-items-name {
    display: none;
  }
  .main-section .menu.expanded .menu-items-SVG {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
  }
  .magilhub-bottom-logo {
    display: none;
  }
}

@media (max-width: 575px) {
  .btn-nav {
    display: none;
  }
  .btn-nav1 {
    display: none;
  }
}

.menu-items-name {
  // padding-top: 2rem;
}

.menu-items-sidebar {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2.3rem !important;
}
.activePath {
  display: flex;
  gap: 1rem;
}

.not-active {
  display: flex;
  gap: 1rem;
}
.menu-items-icon {
  width: 24px;
  height: 20px;
  cursor: pointer;
}
.menu-items-name {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.not-active .menu-items-name {
  color: #000000 !important;
}
.activePath .menu-items-name {
  color: #E52333 !important;
}

.not-active .menu-items-icon path {
  fill: #000000 !important;
}
.activePath .menu-items-icon path {
  fill: #E52333 !important;
}
.offers-nav-list {
  margin: 0;
  padding: 0;
  height: auto !important;
  width: 90%;
  position: relative;
}

.menu-items-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.menuList-offers-sub-category {
  padding: 0;
  margin: 0;
  width: 4rem;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  white-space: normal; /* Allows wrapping of text */
  word-break: break-word; /* Breaks words when they're too long */
  display: inline-block;
  flex-wrap: wrap;
}

.reports-categories {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.logOutBtn {
  display: flex;
  gap: 15px;
}
