.addOutletContainer{
    width: 283px;
    height: 182px;
    position: relative;
    top: 80px;
    left: 30px;
    background: #FFFFFF;
    box-shadow: 0 0px 23px rgba(0,0,0,0.1);
    cursor: pointer;

}

.vtline{
    position: relative;
    left: 128px;
    top: 40px;
}

.hzLine{
    position: relative;
    left: 80px;
    top: -10px;
   

}