#not-found-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  margin-top: 10%;
  padding-left: 42.5%;
  & .login-again-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 45px;
    color: white;
    font-size: medium;
    background-color: #67833E;
    border: 0px;
    margin-top: 25px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "Poppins";
  } 
}

#not-found-container span {
  font-family: "Poppins";
  opacity: 0.6;
}

