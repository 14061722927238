.exploreContainer{
  display: flex;
  flex-direction: row;
  width: 80%;
}

.exploreMenu{
  .info{
    margin-top: 30px;
    margin-left: -24%;
  }
}
.restaurantVideo{
    margin-left: 25%;
    
    .videoTitle{
        margin-left: -90%;
        margin-top: 20px;
    }
}
.restaurantImage{
    margin-left: 25%;

    .imageTitle{
        margin-left: 2%;
        width: 250px;
        text-align: left;
        margin-top: 20px;
    }
}

.headers1{
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img{
    margin-left: -120px;
    margin-top: 22px;
    cursor: pointer;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptexts {
    visibility: hidden;
    width: 500px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 50%;
    right: 50%;
    margin-left: -70px; 
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptexts {
    visibility: visible;
    opacity: 1;
  }
}  

.headers2{
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img{
    margin-left: -90px;
    margin-top: 22px;
    cursor: pointer;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 500px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 50%;
    right: 50%;
    margin-left: -10px; 
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.previewContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;  
    top: 15%;            
    right: 90%;           
    .pImage {
      width: 400px;
      height: 400px;    
    }
    &.show-preview img {
      display: block;   
    }
  } 
  .image{
    width: 50%;
  }

@media (max-width:1280px){
  .previewContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;  
    top: 15%;            
    right: 90%;           
    .pImage {
      width: 300px;
      height: 300px;    
    }
    &.show-preview img {
      display: block;   
    }
  } 
  .headers1{
    img{
      margin-left: -140px;
      margin-top: 22px;
      cursor: pointer;
    }
  }
}