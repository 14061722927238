.r-hamburger-menu {
  display: none;

  .r-hamburger-icon {
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    @media(max-width: 400px){
      // margin-left: 5vw;
    }
  }

  .r-menu {
    display: flex;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 50px;
    width: 200px;
    height: fit-content;
    z-index: 1000;
    border-radius: 1rem;
    height: fit-content;
    font-size: small;

    .r-menu-item {
      padding: 15px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      border-bottom: 1px solid #444;
      border-radius: 1rem;

      &:hover {
        background-color: #555;
      }
    }
  }
}

@media (max-width: 800px) {
  .r-hamburger-menu {
    display: block;
  }
}
