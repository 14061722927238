// .reviewContainer{
//   display: "flex";
//    width: 93% ;
// }

.reviewContainer {
  display: flex;
  width: 105%;
  // background-color: pink;
}

.reviewContaineExpanded {
  display: flex;
  width: 112vw;
  border: 1px solid blue;

 
}
.reviewpage-whole-container
{
  height: 99vh;
}
.reviewpage {
  position: relative;
  top: 28px;
  width: 120%;
  height: 77vh;
  overflow-y: auto;
  left: 1rem;
  // background-color: aqua;
  &::-webkit-scrollbar {
    display: none;
  }

  .buttoncomponent {
    position: relative;
  }
  // @media  (max-wi:1300px) {
  //   height: 50vh;
  //   border: 1px solid red;
  // }
  // @media  (max-width:1900px) {
  //   height: 64vh;
  //   border: 1px solid blue;
  // }
  // @media  (max-width:1690px) {
  //   height: 78vh;
  //   border: 1px solid red;
  // }



}

.reviewheading {
  width: 83vw;
  // width: fit-content;
  height: 50px;
  left: 1rem;
  position: relative;
  top: 15px;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
  padding-left: 0;
  // background-color: pink;

  p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    color: #E52333;
  }
 
}
.reviewheading-Expanded
{
  width: 78vw;
  // width: fit-content;
  height: 50px;
  left: 1rem;
  position: relative;
  top: 15px;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
  padding-left: 0;
  // background-color: pink;

  p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    color: #E52333;
  }
}
.primaryreview {
  display: flex;
  flex-direction: column;
  // border-bottom: 1px solid #c4c4c4;
  height: fit-content;
  // background-color: lightsalmon;
}

.primaryreviewdetailspart1 {
  display: flex;
  flex-direction: column;
  width: 34vw;
  height: 600px;
  // border: 1px solid black;
  position: relative;

  .primaryreviews {
    display: flex;
    flex-direction: row;
    // border: 1px solid red;
    width: 34vw;
  }

  .primaryreviewheading {
    width: 199px;
    height: 27px;
    position: relative;
    top: 24px;

    p {
      font-size: 18px;
      font-weight: 500;
      margin-top: 0;
    }
  }

  .primaryreviewdetails {
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 500px;
    // border: 1px solid black;
    position: relative;
    top: 30px;
    margin-left: -1rem;

    .primaryreviewdetails1 {
      width: 200px;
      height: 580px;
      // border: 1px solid black;
      div {
        // margin-top: 30px;
        p {
          //                 font-family: "Poppins",sans-serif;
          // font-size: 12px;
          // font-weight: 400;
          // color: #6E6E6E;
        }

        span {
          //                 font-family: "Poppins",sans-serif;
          // font-size: 14px;
          // font-weight: 400;
          // color: #000000;
          // position: relative;
          // top: 5px;
        }
      }
    }
    .primaryreviewdetails2 {
      width: 200px;
      height: 480px;
      // border: 1px solid black;
      position: relative;
      left: 70px;
      top: -2px;

      div {
        // margin-top: 30px;
        //             p{
        //                 font-family: "Poppins",sans-serif;
        // font-size: 12px;
        // font-weight: 400;
        // color: #6E6E6E;

        //             }

        //             span{
        //                 font-family: "Poppins",sans-serif;
        // font-size: 14px;
        // font-weight: 400;
        // color: #000000;
        // position: relative;
        // top: 5px;

        //             }
      }
    }
  }
}
.primaryreviewdetailspart2 {
  width: 32rem;
  // height: 580px;
  height: fit-content;
  // border: 1px solid blue;
  // position: relative;
  margin-left: 2vw;
  padding-left:1vw ;
 

  .EditData
  {
    width: 100%;
    // border: 1px solid red;
  }
  

  .primaryimages {
    width: 500px;
    height: 215px;
    margin-top: 70px;
    top: 10px;
    bottom: 10px;
    p {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #6e6e6e;
      margin-bottom: 5px;
      margin-top: 0.5rem;
    }

    .images {
      width: 500px;
      min-height: 190px;
      // border: 1px solid black;
      position: relative;
      margin-left: -15px;

      margin-top: 10px;
      img {
        width: 65px;
        height: 65px;
        border-radius: 50px;
        margin-left: -10px;
      }
      .selectediagelist {
        display: flex;
        // flex-wrap: wrap;
        flex-direction: row;
        // border: 1px solid red;
        margin-top: 10px;
        width: 420px;

        li {
          padding-right: 30px;
          img {
            margin-left: -10px;
            width: 65px;
            height: 65px;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .primarydescription {
    // width: 500px;
    height: auto;
    position: relative;
    top: -15px;

    p {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
      color: #6e6e6e;
    }

    .description {
      width: 500px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: -0.3rem;
    

      p {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        width: auto;
        display: block;
        word-break: break-word;
        overflow-wrap: break-word;
      }
    }
  }

  .primarybestpairedfood {
    width: 500px;
    height: 75px;
    position: relative;
  margin-top: -0.5rem;

    P {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #6e6e6e;
      // margin-bottom: 5px;
    }

    .bestpairfoods {
      p {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .allergensandingredients {
    display: flex;
    flex-direction: row;
    width: 500px;
    // height: 105px;
    height: fit-content;
    // position: relative;
    top: 35px;
    // margin-bottom: 5px;
    margin-top: -1rem;
    gap: 2vw;
    // background-color: pink;

    .ingredients {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #6e6e6e;
    }
    .allergen {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #6e6e6e;
      position: relative;
      left: 5%;
    }
  }
}
.pricinganditemcustomize {
  width: 75%;
  height: 500px;
  // border: 1px solid red;
}
.imagesallergens {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100px;

  &::-webkit-scrollbar {
    display: none;
  }

  .selectedallergensimage {
    width: auto;
    height: 35px;
    // border: 1px solid red;
    display: flex;
    text-align: center;
    margin-right: 30px;
    margin-top: 20px;

    img {
      position: relative;
      width: 24px;
      height: 24px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      left: 5px;
    }
  }
}

.imagesingredients {
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100px;

  &::-webkit-scrollbar {
    display: none;
  }

  .selectedingredientsimage {
    width: auto;
    height: 35px;
    // border: 1px solid red;
    display: flex;
    text-align: center;
    // position: relative;
    margin-right: 30px;

    margin-top: 20px;

    img {
      position: relative;
      width: 24px;
      height: 24px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      left: 5px;
    }
  }
}

.pricingdetails {
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 600px;
  // border: 1px solid black;
  position: relative;

  .pricingreviews {
    display: flex;
    flex-direction: row;
  }

  .pricingdetailsheading {
    width: 399px;
    height: 27px;
    position: relative;
    top: 24px;

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .pricingreviewdetails {
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 580px;
    // border: 1px solid black;
    position: relative;
    top: 20px;

    .pricingdetailsdetails1 {
      width: 200px;
      height: 580px;
      // border: 1px solid black;
      div {
        margin-top: 30px;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: #6e6e6e;
        }

        span {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          position: relative;
          top: 5px;
        }
      }
    }
  }
}

.Edit-heading {
  margin-left: 5px;
}


.Edit-heading-primary
{
margin-left: 5px;
}

.saveandnextreview {
  display: flex;
  position: relative;
  // justify-content: flex-end;
  width: 95vw;
  background-color: white;
  // top: 50px;
  // left: -10px;
  height: 12vh;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  // padding: 16px 32px;
  // right: 90px;
  // border: 1px solid red;
  transition: 0.2s ease all;
  left: -0.6rem;
  // @media (max-width: 1280px) {
  //   width: 75vw;
  //   height: fit-content;
  //   // background-color: pink;
  // }
  // @media (max-width: 1470px) and (min-width: 1281px) {
  //   // width: 75vw;
  //   height: fit-content;
  //   // background-color: lightskyblue;
  // }

  // @media (min-width: 1700px) and (max-width: 1706.67px) {
  //   // background-color: #67833e;
  //   height: fit-content;
  //   width: 122%;
  // }

  // @media (min-width: 1900px) and (max-width: 1920px) {
  //   // background-color: #67833e;
  //   height: fit-content;
  //   width: 138%;
  // }

  // @media (min-width: 2000px) and (max-width: 2048px) {
  //   // background-color: pink;
  //   height: fit-content;
  //   width: 148%;
  // }

  .clearall1 {
    position: relative;
    // right: 0px;
    width: 170px;
    height: 44px;
    // padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
    // margin-left: 50%;
    cursor: pointer;
  }
  .saveall {
    position: relative;
    // right: 80px;
    border: none;
    width: 170px;
    height: 44px;
    // margin-left: 10px;
    height: 44px;
    // padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background: #E52333;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}


.save-and-next-button
{
  display: flex;
  width: 30vw;
  // border: 1px solid red;
 align-items: center;
 justify-content: center;
 gap: 1.5rem;
 position: absolute;
 right: 3vw;
 height: 13vh;
}
.save-and-next-button-extended
{
  display: flex;
  width: 30vw;
  // border: 1px solid red;
 align-items: center;
 justify-content: center;
 gap: 1.5rem;
 position: absolute;
 right: 10vw;
 height: 13vh;
}


.saveandnextreview1 {
  display: flex;
  position: relative;
  // justify-content: flex-end;
  right: 0;
  width: 94vw;
  height: 12vh;
  // height: fit-content;
  background-color: white;
  // background-color: pink;
  // border: 1px solid red;
  // top: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  // padding: 16px 32px;
  transition: 0.2s ease all;
  gap: 0.5rem;
  
  // @media (max-width: 1470px) and (min-width: 1281px) {
  //   // width: 120%; /* Adjusted width for smaller screens */
  //   width: 90vw;
  //   height: fit-content;
  //   top: 76px;
  //   padding: 16px 16px;
  //   transition: 0.2s ease all;
  //   right: 0 !important;
  //   // background-color: lightseagreen;
  // }
  // @media (max-width: 1280px) {
  //   // width: 70vw;
  //   // width: 86.2vw;
  //   height: fit-content;
  //   // background-color: pink;
  // }

  .clearall {
    // position: relative;
    // right: 30px;
    width: 170px;
    // position: absolute;
    // top: 4.4vh;
    // right: 16vw;
    height: 44px;
    // padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
    // margin-left: 35%;
    cursor: pointer;
  }
  .clearall1 {
    position: relative;
    right: 30px;
    width: 170px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
    margin-left: 50%;
    cursor: pointer;
  }
  .saveall {
    // position: relative;
    // right: 30px;
    border: none;
    width: 170px;
  
    // margin-left: 10px;
    height: 44px;
    // padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    top: 4.4vh;
    background: #E52333;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    // position: absolute;
    // right: 5vw;

  }
}
// .buttoncomponentreview {
//   width: 100%;
//   margin-top: 90%;
//   height: 30px;
// }

.imagewitherror {
  // cursor: progress;
  .errromsg {
    position: relative;
    top: -3rem;
    left: 0.4rem;
    font-size: 14px;
    display: flex;
    width: 20px;
    height: 30px;
    flex-wrap: wrap;
  

  }
  .eerroremptyimage {
    // cursor: progress;
  }
  .imgfile {
    display: none;
  }
}

.reviewLoaders {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #929496;
  width: 20px;
  height: 20px;

  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: relative;
  top: -1px;
  left: 39%;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// @media (max-width: 1470px) {
//   .saveandnextreview1 {
//     display: flex;
//     position: relative;
//     justify-content: flex-end;
//     width: 120%; /* Adjusted width for smaller screens */
//     height: 25px;
//     background-color: white;
//     top: 25px;
//     box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
//     padding: 16px 16px; /* Reduced padding for smaller screens */
//     transition: 0.2s ease all;
//   }
// }

// .part-two {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   // margin-left: 20px;
//   // justify-content: center;
// width: 70%;
//   background-color: lightgreen;

//   .verticalLine {
//     width: 5px;
//     height: 180%;
//     background-color: #c4c4c4;
//     // background-color: red;
//     position: relative;
//     top: 145%;
//     left: -479px;
//     @media(max-width: 1080px){
//       left: -545px;
//     }
//   }
// }

.part-two {
  display: flex;
  height: 100%;
  // border: 1px solid red;
}
.verticalLine {
  width: 1px;
  height: 63rem;
  background-color: #c4c4c4;

 
  margin-top: 2%;
 position: absolute;
 left: 38vw;
 
}
.verticalLineExpand {
  width: 1px;
  height: 63rem;
  background-color: #c4c4c4;
  // background-color: red;
  // position: relative;
  // top: 145%;
  margin-top: 2%;
  position: absolute;
  left: 38vw;
  // @media(max-width: 1080px){
  //   left: -545px;
  // }
}


.primary-and-edit-heading
{
  display: flex;
  width: 83vw;
  height: 3rem;
  // border: 1px solid red;
  justify-content: left;
  position: relative;
  align-items: center;
  
}
.primary-and-edit-heading-extended
{
  display: flex;
  width: 78vw;
  height: 3rem;
  // border: 1px solid red;
  justify-content: left;
  position: relative;
  align-items: center;
  
}
.edit-primary-data
{
  color: #E52333;

  font-family: "Poppins",sans-serif;

font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.uploaded-image,.empty-image
{
  cursor: context-menu !important;
}
.primarypageedit {
   
  display: flex;
// width: 100vw;
// border: 1px solid red;
// margin-left: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  text-decoration: none;
  gap: 0.4rem;
  position: absolute;
  right: 1vw;
  top: 2px;

  // margin-left: 700px;
  .link {
    list-style: none;
    text-decoration: none;
    color: #67833e;
    cursor: pointer;
  }
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  // p {
  //   //    margin-right: 10px;
  //   position: relative;
  //   font-family: "Poppins", sans-serif;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #67833e;
  //   border: 3px solid red;
  //   // top: -5px;
  // }
}