//live reports page css
:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #404d66;
  --new-bg:#ccc;
}

.dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #2e3a4f;
  --new-bg: #404d66;
}

.light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ffffff;
  --new-bg: #ccc;
}

.l-expanded-width-sales {
  width: 83% !important;
  // margin-left: -1.5rem;
}

.live-reports {
  background-color: var(--screen-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 91vw;
  padding-bottom: 5vh;

  @media (max-width: 500px) {
    width: 104vw !important;
  }
  @media(max-width: 400px){
    width: 100vw !important;
  }
  // .liveHeader {
  //   font-size: 21px;
  //   font-weight: 500;
  // }
  .cust-insights-head {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-top: 30px;
    @media (max-width: 500px) {
      width: 90%;
      height: 6rem;
    }
    @media (max-width: 480px) {
      width: 100%;
      height: 6rem;
      // background-color: pink;
      text-align: center;
    }
    .name-board {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--table-text);
      @media(max-width: 480px){
        // background-color: palegoldenrod;
        // transform: translateX(20vw);
        width: 100vw;
      }
      .liveHeader {
        font-size: 21px;
        font-weight: 500;
        @media(max-width: 480px){
          // background-color: palegoldenrod;
          transform: translateX(3vw);
        }
      }
    }
    .dates {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      height: 6rem;
      width: 33%;
      @media (max-width: 480px) {
        gap: 0.8rem;
        height: 6rem;
        width: 40% !important;
      }
      @media (max-width: 500px) {
        width: 50% !important;
      }
      @media (max-width: 1280px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        height: 6rem;
        width: 22%;
      }
      .filter-toggle-btn-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.6rem;

        .filter-toggle-btn {
          text-align: center;
          padding: 0.5rem;
          width: 10rem;
          color: #000;
          background-color: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #000;
          border-radius: 0.8rem;
          cursor: pointer;
          height: 2rem;
        }

        .filter-drop-down-options {
          position: absolute;
          top: 110%;
          left: 50%;
          transform: translateX(-50%);
          width: 12rem;
          padding: 0.5rem;
          background-color: var(--navbar-bg);
          color: var(--navbar-text);
          border: 1px solid #ccc;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          z-index: 999;
          border-radius: 1rem;

          p {
            margin: 0;
            padding: 0.5rem;
            cursor: pointer;
            border-radius: 0.6rem;
            &:hover {
              background-color: var(--navbar-text);
              color: var(--navbar-bg);
            }
          }
        }
      }
      .start-date {
        height: 2rem;
        width: 7rem;
        padding: 0.5rem;
        border-radius: 1rem;
        border-style: thin;
        @media (max-width: 480px) {
          height: 2rem;
          width: 5rem;
          padding: 0.5rem;
          border-radius: 1rem;
          border-style: thin;
          font-size: smaller;
        }
        @media (max-width: 500px) {
          height: 2rem;
          width: 5rem;
          font-size: smaller;
        }
      }
      .end-date {
        height: 2rem;
        width: 7rem;
        padding: 0.5rem;
        border-radius: 1rem;
        border-style: thin;
        @media (max-width: 480px) {
          height: 2rem;
          width: 5rem;
          padding: 0.5rem;
          border-radius: 1rem;
          border-style: thin;
          font-size: smaller;
        }
        @media (max-width: 500px) {
          height: 2rem;
          width: 5rem;
          font-size: smaller;
        }
      }
    }
  }
  .location-name {
    h1 {
      color: var(--table-text);
      text-align: center;
      font-size: 21px;
      font-weight: 600;
      margin-top: 30px;
    }
  }
  .l-live-boxes-container{
    // background-color: pink;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;

    .l-live-inner-box{
      // background-color: lightskyblue;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2vw;
      // flex-wrap: wrap;
      width: 63vw;
      margin: 0;
      @media(max-width: 800px){
        flex-wrap: wrap;
      }
      @media(max-width: 500px){
        flex-direction: column;
        // background-color: pink;
        width: 100%;
      }
      .l-live-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;
        background-color: var(--table-bg);;
        border: 1px solid var(--table-text);
        border-radius: 1vw;
        color: var(--table-text);
        width: 15vw;
        text-align: center;
        @media(max-width: 800px){
          font-size: small;
        }
        @media(max-width: 576px){
          width: 60vw;
          // background-color: lightsalmon;
        border-radius: 3vw;
        }
        @media(max-width: 500px){
          // width: 78vw;
          width: 75vw;
          // background-color: palevioletred;
          font-size: large;
          border-radius: 3vw;
        }
        h2{
          width: 100%;
          // background-color: lightgreen;
        }
        // // h3
        .l-label-tooltip-container {
          display: flex;
          align-items: center;
          gap: 1vw;
      
          .l-tooltip-wrapper {
            position: relative;
            display: inline-block;
            // background-color: orange;
            height: 2.9vh;
            border-radius: 1vw;
      
            .l-tool-tip-image {
              cursor: pointer;
              height: 2.8vh;
              // background-color: pink;
              border-radius: 1vw;
            }
      
            .l-tool-tip-content {
              position: absolute;
              top: 120%; // Adjust to position the tooltip below the icon
              left: 50%;
              transform: translateX(-50%);
              background-color: #f9f9f9;
              color: #333;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 0.5rem;
              width: max-content;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              z-index: 10;
              font-size: 0.9rem;
              text-align: center;
              white-space: nowrap;
              opacity: 0; // Hidden by default
              visibility: hidden; // Prevent interaction when hidden
              transition: opacity 0.3s ease, visibility 0.3s ease;
              @media (max-width: 800px) {
                top: 110%; 
                font-size: 0.8rem; 
                padding: 0.4rem; 
                max-width: 60vw; 
                white-space: normal;
                text-align: left;
              }
              @media(max-width: 500px){
                left: -10%
              }

            }
      
            &:hover .l-tool-tip-content {
              opacity: 1; // Show tooltip on hover
              visibility: visible;
            }
          }
        }
      }
    }
    .s-live-no-data{
      background-color: var(--new-bg);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 0;
    }
    .l-live-inner-box-expanded{
      // background-color: lightcoral;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2vw;
      // flex-wrap: wrap;
      width: 57vw;
      margin: 0;
      @media(max-width: 800px){
        flex-wrap: wrap;
        width: 100vw;
      }
      @media(max-width: 500px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: pink;
      }
      .l-live-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;
        background-color: var(--table-bg);
        border: 1px solid var(--table-text);
        border-radius: 1vw;
        width: 15vw;
        color: var(--table-text);
        text-align: center;
        @media(max-width: 800px){
          font-size: small;
        }
        @media(max-width: 576px){
          width: 30vw;
          // background-color: lightsalmon;
          border-radius: 3vw;
        }
        @media(max-width: 500px){
          width: 75vw;
          // background-color: palevioletred;
          font-size: large;
          border-radius: 3vw;
        }
        h2{
          width: 100%;
          // background-color: lightgreen;
          // @media(max-width: 500px){
          //   font-size: x-small;;
          // }
        }
        // h3
        .l-label-tooltip-container {
          display: flex;
          align-items: center;
          gap: 1vw;
      
          .l-tooltip-wrapper {
            position: relative;
            display: inline-block;
            // background-color: orange;
            height: 2.9vh;
            border-radius: 1vw;
      
            .l-tool-tip-image {
              cursor: pointer;
              height: 2.8vh;
              // background-color: pink;
              border-radius: 1vw;
            }
      
            .l-tool-tip-content {
              position: absolute;
              top: 120%; // Adjust to position the tooltip below the icon
              left: 50%;
              transform: translateX(-50%);
              background-color: #f9f9f9;
              color: #333;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 0.5rem;
              width: max-content;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              z-index: 10;
              font-size: 0.9rem;
              text-align: center;
              white-space: nowrap;
              opacity: 0; // Hidden by default
              visibility: hidden; // Prevent interaction when hidden
              transition: opacity 0.3s ease, visibility 0.3s ease;
              @media (max-width: 800px) {
                top: 110%; 
                font-size: 0.8rem; 
                padding: 0.4rem; 
                max-width: 60vw; 
                white-space: normal;
                text-align: left;
              }
            }
      
            &:hover .l-tool-tip-content {
              opacity: 1; // Show tooltip on hover
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .canva-pie-chart-cont {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--table-bg);
    width: 98%;
    border: 1px solid var(--table-text);
  }
  .tables-container-one {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .live-orders-non-dine-in{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .tables-container-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    gap: 0.8rem;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
