.MLSearch-Container {
  position: relative;
  width: 40vw;
  // left: -5vw;

  .MLsearchbox {
    width: 100%;

    .MLHeader-Search {
      width: 100%;
      height: 48px;
      gap: 0px;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      opacity: 0.3px;
      padding-left: 52px;
    }

    .MLHeader-Search-term{
      width: 100%;
      height: 44px;
      gap: 0px;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      opacity: 0.3px;
      padding-left: 10px;
    }

    .MLSerchIcon-Header {
      position: absolute;
      left: 15px;
      top: 13px;
    }

    .MLClearIcon-Header {
      position: absolute;
      right: 20px;
      top: 15px;
      width: 15px;
      height: 15px;
    }

    .MLHeader-Search1 {
      width: 100%;
      height: 48px;
      gap: 0px;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      opacity: 0.3px;
      padding-left: 52px;
    }

    .MLHeader-Search1-term{
      width: 100%;
      height: 44px;
      gap: 0px;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      opacity: 0.3px;
      padding-left: 10px;
    }

    .MLSerchIcon-Header1 {
      position: absolute;
      left: 15px;
      top: 13px;
    }
    .MLClearIcon-Header1 {
      position: absolute;
      right: 20px;
      top: 15px;
      width: 15px;
      height: 15px;
    }
  }

  .MLSearch-Container-options-menu {
    width: 39.8vw;
    height: 45vh;
    z-index: 99 !important;
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 14px 0px #00000040;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .MLsearchBoxContainer {
      // width: 42vw;
      // height: 100%;

      display: flex;
      gap: 0.6rem;
      flex-direction: column;

      li {
        list-style: none;
        padding: 10px;
        padding-left: 5px;
        cursor: pointer;

        // &:hover {
        //   width: 30vw;
        //   margin-left: -16px;
        //   padding-left: 20px;
        //   background-color: rgba(0, 0, 0, 0.1);
        //   border-top-right-radius: 50px;
        //   border-bottom-right-radius: 50px;
        //   // border-left: 5px solid #E52333;

        // }
      }
      .hover {
        &:hover {
          width: 30vw;
          margin-left: -16px;
          padding-left: 18px;
          background-color: lightgray;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          // border-left: 5px solid #E52333;
        }
      }

      .MLSearch-Container-options-none {
        width: 32vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        margin-left: -2rem;

        .MLNotFoundImage {
          width: 300px !important;
          height: 300px !important;
          margin-left: 54px;
        }

        .MLheading-none {
          color: #e52333;
          font-size: 23px;
          font-weight: 500;
          margin-top: -2rem;
          margin-left: 65px;
        }
      }
    }
  }

  .MLhighlighted {
    width: 30vw;
    margin-left: -18px;
    // padding-left: -30px;
    background-color: lightgray;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-left: 5px solid #e52333;
  }

  .list-of-item-name-padding {
    padding-left: 15px;
    // margin-left: 2rem;
  }

  .MLSearch-Container-options1 {
    width: 39.8vw;
    height: 45vh;
    z-index: 9;
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 14px 0px #00000040;
    overflow-y: scroll;
    z-index: 99 !important;
    &::-webkit-scrollbar {
      display: none;
    }

    .MLsearchBoxContainer1 {
      // width: 42vw;
      // height: 100%;
      display: flex;
      gap: 0.6rem;
      flex-direction: column;

      li {
        list-style: none;
        padding: 10px;
        padding-left: 5px;
        cursor: pointer;

        // &:hover {
        //   width: 30vw;
        //   margin-left: -18px;
        //   padding-left: 18px;
        //   background-color: lightgray;
        //   border-top-right-radius: 50px;
        //   border-bottom-right-radius: 50px;
        //   // border-left: 5px solid #E52333;
        // }
      }

      .hover {
        &:hover {
          width: 30vw;
          margin-left: -16px;
          padding-left: 18px;
          background-color: lightgray;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          // border-left: 5px solid #E52333;
        }
      }
      .MLSearch-Container-options1-none {
        width: 32vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        margin-left: -2rem;

        .MLNotFoundImageExpanded {
          width: 300px !important;
          height: 300px !important;
          margin-left: 54px;
        }

        .MLheading-none-expanded {
          color: #e52333;
          font-size: 23px;
          font-weight: 500;
          margin-top: -2rem;
          margin-left: 65px;
        }
      }
    }
  }
}
