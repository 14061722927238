:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #404d66;
}

.dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #2e3a4f;
}

.light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ffffff;
}

.e-expanded-width-sales {
  width: 83% !important;
  // margin-left: -1.5rem;
  // padding-bottom: 5vh;
}

.employee-container {
  background-color: var(--screen-bg);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // width: 91vw !important;
  // margin-left: -1.5rem;
  padding-bottom: 5vh;

  @media (max-width: 500px) {
    width: 105vw !important;
  }
  .employee-head {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    // background-color: pink;

    @media(max-width: 500px){
      display: flex;
      flex-direction: column;
      gap: 2vh;
    }
    .name-board {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--table-text);

      h1 {
        font-size: 21px;
        font-weight: 500;
      }
    }
    // .dates {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 0.8rem;
    //   height: 6rem;
    //   .label-time-period {
    //     font-weight: 600;
    //     color: var(--table-text);
    //     @media (max-width: 800px) {
    //       display: none;
    //     }
    //   }
    //   .filter-toggle-btn-container {
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     gap: 0.6rem;

    //     .filter-toggle-btn {
    //       text-align: center;
    //       padding: 0.5rem;
    //       width: 10rem;
    //       height: 2rem;
    //       color: #000;
    //       background-color: #ccc;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       border: 1px solid #000;
    //       border-radius: 0.8rem;
    //       cursor: pointer;
    //       @media (max-width: 800px) {
    //         width: 5rem;
    //         height: 1rem;
    //         font-size: smaller;
    //       }
    //       @media(max-width: 500px){
    //         width: 24vw;
    //       }
    //       @media(max-width: 400px){
    //         width: 30vw;
    //       }
    //     }

    //     .filter-drop-down-options {
    //       position: absolute;
    //       top: 110%;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       width: 12rem;
    //       padding: 0.5rem;
    //       background-color: var(--navbar-bg);
    //       color: var(--navbar-text);
    //       border: 1px solid #ccc;
    //       box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    //       z-index: 999;
    //       border-radius: 1rem;
    //       text-align: center;
    //       @media (max-width: 800px) {
    //         width: 5rem;
    //         font-size: x-small;
    //       }
    //       @media(max-width: 500px){
    //         width: 24vw;
    //       }
    //       @media(max-width: 400px){
    //         width: 30vw;
    //       }

    //       p {
    //         margin: 0;
    //         padding: 0.5rem;
    //         cursor: pointer;
    //         border-radius: 0.6rem;
    //         &:hover {
    //           background-color: var(--navbar-text);
    //           color: var(--navbar-bg);
    //         }
    //       }
    //     }
    //   }
    // }
    .employee-date-filter-container{
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: pink;
    }
  }
  .employee-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .e-date-range-style {
  //   display: flex;
  //   height: 60px;
  //   right: 1rem;
  //   justify-content: flex-end;
  //   align-items: center;
  //   padding-right: 1rem;
  //   width: 100%;

  //   .e-dateLabel {
  //     align-items: flex-start;
  //     margin-right: 0.5rem;
  //     color: var(--table-text);
  //   }

  //   .e-start-date {
  //     height: 2rem;
  //     width: 9rem;
  //     padding: 0.5rem;
  //     border-radius: 0.6rem;
  //     border-style: thin;
  //     margin-right: 1rem;
  //   }

  //   .e-end-date {
  //     height: 2rem;
  //     width: 9rem;
  //     padding: 0.5rem;
  //     border-radius: 0.6rem;
  //     border-style: thin;
  //   }
  //   @media (max-width: 500px) {
  //     justify-content: center;
  //   }
  // }
  .name-board-two {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--table-text);

    h1 {
      font-size: 21px;
      font-weight: 600;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .employee-vs-sales {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .employee-vs-sales-inner {
      width: 70%;
    }
  }
  .dynamic-chart-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // border: 1px solid black;
    gap: 2vh;
    .employee-tips-pie-chart{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--chart-bg);
      width: 71%;
      border: 1px solid var(--table-text);
      border-radius: 1rem;
      overflow: hidden;
    }
    .employee-sales-chart{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 71%;
    }
    .chart-options-config-header{
      display: flex;
      // background-color: pink;
      width: 71%;
      align-items: center;
      justify-content: space-between;
      h2{
        color: var(--table-text);
        // background-color: pink;
      }
      label{
        color: var(--table-text);
        font-weight: 600;
      }
      .rep-label-chart-option-cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1vw;
        // background-color: palegreen;
      }
    }
  }
}
