.otp_login {
  // width: 100vw;
  // height: 100vh;
  text-align: center;
  padding-top: 80px;
  // margin: 0 30px;
  // background-color: pink;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  @media(max-width: 600px){
    // background-color: orange;
    width: 100%;
    height: 100%;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & .logo {
    margin-bottom: 40px;
    & img {
      width: 250px;
    }
  }

  & h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  & form {
    display: flex;
    flex-direction: column;
    min-width: 40%;
    max-width: 680px;
    margin: auto;

    & input {
      // background: rgba(103, 131, 62, 0.2);
      background-color: white;
      border-radius: 5px 5px 0px 0px;
      border: 1px solid rgba(103, 131, 62, 0.2);
      padding: 10px 20px;
      box-sizing: border-box;
      border-bottom: 2px solid #E52333;
      //   font-size: 20px;
      line-height: 30px;
      color: #000000;
      opacity: 0.6;
      font-family: "Poppins", sans-serif;
      margin-bottom: 25px;
      @media(max-width: 600px){
        // background-color: pink;
        width: 80vw;
      }
    }
    & button {
      background: #E52333;
      border: 0px solid;
      color: #fff;
      font-family: "Poppins", sans-serif;
      padding: 16px 0px;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 18px;
      // background-color: pink;
      width: 100%;
      @media(max-width: 600px){
        width: 80vw;
      }
    }
    .login--password {
      position: relative;
      svg {
        top: 30%;
        transform: translateY(-50%);
        padding-right: 15px;
      }
    }
  }
  & p {
    font-size: 14px;

    & span {
      color: #2196f3;
      cursor: pointer;
    }
  }
}

.f_psd {
  margin: 0;
  text-align: left;
  /* font-size: 20px; */
  line-height: 30px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 30px;
  padding-left: 30px;
}

.basic_details {
  width: 100%;
  text-align: center;
  padding-bottom: 50px;

  & .logo {
    margin-bottom: 55px;
    & img {
      width: 335px;
    }
  }

  & h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  & form {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: auto;

    & .upload-sec {
      margin-top: 28px;
      & img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
      }
      & h4 {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        font-weight: 400;
      }
    }

    & > div {
      margin-bottom: 30px;
    }

    & input {
      padding: 16px 20px;
    }

    & button {
      background: #E52333;
      border: 0px solid;
      color: #fff;
      font-family: "Poppins", sans-serif;
      padding: 16px 0px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-top: 12px;
      cursor: pointer;
    }
  }
  & p {
    font-size: 14px;

    & span {
      color: #2196f3;
      cursor: pointer;
    }
  }

  .Dropdown-control {
    padding: 16px 20px;
    border: 1px solid #dadada;
    text-align: left;
    & .placeholder-class {
      color: #979797;
    }

    & .is-selected {
      color: #000000;
    }
  }

  .Dropdown-arrow {
    top: 26px;
  }
}
// @media (max-width: 768px) {
//   .otp_login {
//     & .logo {
//       margin-bottom: 75px;
//       img {
//         width: 250px;
//       }
//     }
//   }
// }


.magilhub-logo2 {
  font-family: Righteous;
  font-weight: normal;
  color: #E52333;
  font-size: 3rem;
  margin-left: 1rem; 
  // padding-left: 50px;
}

.logoContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.magilhub-login-logo {	

  width: '100%';
  // align-self: center;
  // align-items: center;
}