input:focus ~ .floating-label,
input:focus:valid ~ .floating-label {
  top: -5px;
  /* <!--  bottom: 10px; --> */
  left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  opacity: 1;
  background-color: #fff;
  color: #979797;
}

.value-floating-label {
  top: -5px;
  left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  opacity: 1;
  background-color: #fff;
  position: absolute;
  /* left: 12px; */
  /* top: 5px; */
  transition: 0.2s ease all;
  color: #979797;
  /* color: #979797; */
}

.inputText {
  font-size: 14px;
  color:black;
  /* color: #979797; */
  /* width: 200px; */
  /* height: 35px; */
}

.floating-label {
  position: absolute;
  font-size: 14px;
  /* pointer-events: none; */
  left: 12px;
  top: 9px;
  transition: 0.2s ease all;
  color: #979797;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
