* {
  font-family: "Poppins", sans-serif;
}

.navigation {
  position: relative;
  height: 2.25rem;
  top: 0.363rem;
  left: 35px;
  width: 90vw;
}

.navigationExpanded{
  position: relative;
  height: 2.25rem;
  top: 0.363rem;
  left: 35px;
  width: 80vw;
}

.Mainheading {
  width: 400px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #E52333;
  line-height: 36px;
  display: flex;
  // justify-content: ;
  align-items: center;
 gap: 2px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.listofnavigation {
  display: flex;
  flex-direction: row;
  width: 90vw;
  gap: 3rem;
  position: relative;
  left: -3vw;
  // padding-left: -3vw !important;
  // border: 1px solid red;
}

.listofnavigationExpanded {
  display: flex;
  flex-direction: row;
  width: 80vw;
  gap: 3rem;
  position: relative;
  left: -3vw;
}

.lists-navigation, .listsExpanded-navigation {
  list-style: none;
  cursor: pointer;
}

.lists-navigation {
  width: 25vw;
  // border: 1px solid red;
}

.listsExpanded-navigation {
  width: 23vw;
}

/* Navbar styling */
.navbar, .navbarExpanded {
  height: 1px;
  padding-bottom: 0.313rem;
  transition: background-color 0.3s;
  border-radius: 0.625rem;
  background-color: #979797;
  // cursor: pointer;
}

.navbar {
  width: 21vw;
  // border: 1px solid blue;
}

.navbarExpanded {
  width: 25vw;
  // border: 1px solid blue;
}


.navbar.active, .navbarExpanded.active {
  background-color: #E52333;
}

.list-text {
  font-size: 16px;
  font-weight: 500;
  color: #979797;
  position: relative;
  margin-bottom: 6px;
  // cursor: pointer;
}

.list-text.activetext {
  color: black;
  font-weight: 500;
}
