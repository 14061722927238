* {
  font-family: "Poppins", sans-serif;
}

.PricingSlider-Container {

  
  padding-top: 20px;
  padding-left: 10px;


}

.PricingSlider-Heading {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
}

.Onprem-Ofprem {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.Onprem-Heading {
  margin-left: -4px;
  font-size: 16px;
  font-weight: 400;
}

.SectionA {
  width: 200px;
  height: 35px;
  margin-left: 27px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SectionA-Heading {
  font-size: 16px;
  font-weight: 400;
  color: #5f5f5f;
  margin-left: 5px;
  width: 5rem;
}

.OrderType-Name {
  font-size: 16px;
  font-weight: 400;
  // color:#5F5F5F;
  margin-left: 5px;
  width: 5rem;
}

.ordertypes-prices {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 5%;
}

.SectionInput {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-top: 4%;
}

.SectionA-Input {
  margin-left: 20px;
  border: 1px solid #000000;
  width: 80px;
  // text-align: center;
  height: 28px;
  border-radius: 3.18px;
  padding-left: 8.07px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: end;
  align-items: end;
}

.Priceing-input-field {

  outline: none;
  // margin-top: 0.25rem;
  width: 80px;

  background: transparent;
  // position: relative;
  // left: -0.7rem;
  // top: 0.05rem;
  // text-align: left ;
  // display: flex;
  // justify-content: left;
  height: 24px;
  z-index: 1;
 
  position: relative;
  // top: 0.5px;
  // padding-left: -1rem !important;
  // margin-left: -0.9rem;
  border: none;
  // border: 1px solid red;
  // background-color: lightgreen;
  // border: none;
  // padding-left: 25px;
  font-size: 16px;
  // border: 1px solid red;
  margin-left: 0.7rem;
  text-align: left;
  padding: 0;
  &::placeholder {
    // text-align: center;
    // background-color: #00b71d;
    // margin-right: 7px;
  }
}

.PricingSlider-Heading {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
}
.Onprem-Ofprem {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.Onprem-Heading {
  margin-left: -4px;
  font-size: 16px;
  font-weight: 400;
}
.SectionA {
  width: 200px;
  height: 35px;
  margin-left: 27px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SectionA-Heading {
  font-size: 16px;
  font-weight: 400;
  color: #5f5f5f;
  margin-left: 5px;
  width: 5rem;
}

.priceSymbol-price {
  width: 20px;
  font-size: 16px;
  font-weight: 400;
  z-index: 0;
  position: relative;
  left: 0.5rem;
  top: -1px;
  // top: -0.7px;
  // border: 1px solid red;
  // position: relative;
  // left: 25%;
  // top: 5%;
}

.OrderType-Name {
  font-size: 16px;
  font-weight: 400;
  // color:#5F5F5F;
  margin-left: 5px;
  width: 5rem;
}

.ordertypes-prices {
  display: flex;
  justify-content: left;
  align-items: center;
  // margin-top: 3%;
  width: fit-content;
  // margin-bottom: 4%;
  // border: 1px solid red;
  margin-left: 5%;
}

.SectionInput {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-top: 4%;
}

.SectionA-Input {
  display: flex;
  margin-left: 20px;
  border: 1px solid #000000;
  width: 90px;
  text-align: center;
  justify-content: left;
  align-items: center;
  height: 28px;
  border-radius: 3.18px;
  padding-left: 8.07px;
  font-size: 16px;
  font-weight: 400;
}

.PricingSlider-Container {
 width: 100%;
 

}

.PricingSlider-Heading {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
}

.Onprem-Ofprem {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.Onprem-Heading {
  margin-left: -4px;
  font-size: 16px;
  font-weight: 400;
}

.SectionA {
  width: 200px;
  height: 35px;
  margin-left: 27px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SectionA-Heading {
  font-size: 16px;
  font-weight: 400;
  color: #5f5f5f;
  margin-left: 5px;
  width: 5rem;
}

.OrderType-Name {
  font-size: 16px;
  font-weight: 400;
  // color:#5F5F5F;
  margin-left: 5px;
  width: 5rem;
  display: flex;
  justify-content: right;
  // border: 1px solid blue;
}
.ordertypes-prices {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 3%;
}

.p-slider-input-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.OnSectionLabelInput-Heading {
  font-size: 16px;
  font-weight: 400;
}

.compare-Image {
  margin-left: 2%;
  // background-color: pink;
  width: 30px;
  height: 23px;
  .measuring-scale-tooltip-container {
    // position: fixed;
    // margin-left: 2%;
    // background-color: lightgreen;
    width: 30px;
    height: 23px;
  }
}

.OnPremZomatoInhouseSwiggy {
  width: 320px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.OnPremZomatoInhouseSwiggyInput {
  display: flex;
  justify-content: flex-start;
  // border: 1px solid red;
  justify-content: left;

  align-items: center;
  padding: 5px;
  margin: 0;
}
.OnPremZomatoInhouseSwiggyInputOrg {
  border: 1px solid #000000;
  width: 100px;
  text-align: center;
  height: 28px;
  border-radius: 3.18px;
  padding-left: 8.07px;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  box-sizing: border-box;
}
.OnPremZomatoInhouseSwiggyInput-Heading {
  width: 125px;
  // border: 1px solid blue;
  font-size: 16px;
  font-weight: 400;
  color: #5f5f5f;
}

.Compare {
  margin-left: 10px;
}
.percentage {
  margin: 0;
  padding: 0;
  // width: 80px;
  padding-right: 10px;
  margin-left: 10%;
  // border: 1px solid red;
}

.input-price {
  display: flex;
  // border: 1px solid red;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.percentage-diff {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 15px;
  gap: 5px;
  // border: 1px solid red;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #00b71d;
  margin: 0;
  padding: 0;
  margin-left: -10px;
  margin-top: -5px;
}
.rev-triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #e52333;
  transform: rotate(180deg);
  margin-left: -10px;
  // margin-top: -5px;
}

.compare-Image {
  position: relative;
  display: inline-block;
}

.comparision {
  cursor: pointer;
}

.tooltip-texts {
  visibility: hidden;
  width: 260px;
  background-color: #E52333;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 10%;
  transform: translateX(-70%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  .arraow-image {
    position: relative;
    top: 1.35rem;
    rotate: 180deg;
  }
}

.compare-Image:hover .tooltip-texts {
  visibility: visible;
}
