.customize-container {
  // width: 394px;
  // height: auto;
  // margin-left: 10px;
  // padding-top: 10px;
  // padding-left: 10px;

  // height: 40vh;
  // border: 1px solid red;
  height: auto;
}

.customize-heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  align-items: Center;
  margin-left: 1.3rem;
}

// {****************Items****************************************************************************************}

.items-container {
  width: 308px;
  height: auto;
  margin-top: -10px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

}
.item-toggle-container-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: -1%;
  margin-left: -5%;
  width: 200px;
}

.item-heading {
  display: flex;
  // width: 300px;
  // border: 1px solid red;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-size: 16px;
  // line-height: 40px;
 
  width: 70%;
  // height: 1rem;
display: flex;
white-space: normal; 
word-break: break-word; 
display: inline-block;
// margin-left: -5px;
  
}

.toggle-container {
  position: relative;
  top: -2px;
  width: 30%;
  // left: -3rem;
  // top: 12px;
//   left: 20px;
}
// .input-subitem-field
// {
//     position: relative;
//     top: -1rem;
//     left: 1rem;

// }

// {-------------------------------------SubItems--------------------------------------------------------------------}
.subitems-toggle-container-flex-direction {
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
  justify-content: left;
  align-items: center;
  // margin-left: -2rem;
}

.subitems-toggle-container-flex {
 width: 100%;
  display: flex;

 gap: 1rem;
  margin-left: 10px;
  .input-subitem {
    position: relative;
    left: 40px;
  }
}

.subItemToggle {
  position: relative;
  top: 4px;
  // left: -20px;
}

.input-subitem {
  position: relative;
  top: -20px;
  left: 20px;
  width: 80px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 28px;
  border-radius: 3.18px;
  border: 0.64px solid rgba(0, 0, 0, 0.3);
  padding-left: 20px;
  padding-top: 4px;
  color: #000000;
}

.priceSymbol-customize {
  //   border: 1px solid red;
  position: relative;
  // left: 25%;
  //   top: 4%;
  top: -1px;
}

.input-subitem-field {
  border: none;
  height: 25px;
  // width: 65px;
  outline: none;
  position: relative;
  // left: -0.5rem;
  // border: 1px solid red;
  padding: 0;
  padding-left: 2px;
}

.subitem-heading {

  font-family: "Poppins",sans-serif;
  font-size: 16px;
  color: #5f5f5f;
  width: 150px !important;
  display: flex;
  white-space: normal; 
  word-break: break-word; 
  display: inline-block;
  // border: 1px solid red;
  margin-left: 10px;
}
