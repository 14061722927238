// @media (max-width: 1280px) {
//   .saveandnextfooter {
//     width: 74.5%;
//     height: auto;
//     display: flex;
//     flex-direction: row;
//     padding: 32px 32px 32px 560px;
//     margin-left: 20px;
//     box-shadow: 3px 0px 10px 0px #00000040;
//     background-color: white;

//     .clearall {
//       width: 135px;
//       height: 44px;
//       padding: 10px 14px 10px 14px;
//       gap: 10px;
//       border-radius: 4px;
//       background: white;
//       color: #67833e;
//       border: 1px solid #67833e;
//       font-size: 16px;
//       font-weight: 500;
//       cursor: pointer;
//     }

//     .saveall {
//       border: none;
//       width: 135px;
//       margin-left: 10px;
//       height: 44px;
//       padding: 10px 14px 10px 14px;
//       gap: 10px;
//       border-radius: 4px;
//       background: #67833e;
//       color: white;
//       font-size: 16px;
//       font-weight: 500;
//     }
//   }
// }

.saveAndNextComponent {
  z-index: 9;
  width: 100% !important;
  flex: 1 !important;

  .saveandnextFooterExpanded {
    position: fixed; 
    bottom: 0; 
    width: 80.3%; 
    display: flex;
    justify-content: flex-end;
    background-color: white; 
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
    padding: 4vh 0px; 
    padding-right: 50px;
    gap: 10px;
    transition: width 0.6s ease;
    right: 2px;
    z-index: 2;
    margin-left: 0.1vw;
    // background-color: white;
    // background-color: pink;
    @media(max-width: 1536px){
      // background-color: lightseagreen;
      width: 78.7%; 
    }
    @media(max-width: 1280px){
      // background-color: lightseagreen;
      width: 74.5%; 
    }
    @media(min-width: 1281px) and (max-width: 1440px){
      // background-color: lightseagreen;
      width: 78.3%; 
    }

  .clearall {
    width: 135px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    background: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .saveall {
    border: none;
    width: 135px;
    margin-left: 10px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    background: #E52333;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: width 0.6s ease;
  }
}

.saveandnextfooter {
    position: fixed; 
    bottom: 0; 
    width: 92%; 
    display: flex;
    justify-content: flex-end;
    background-color: white; 
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
    padding: 4vh 0px; 
    padding-right: 50px;
    margin-left: 0.1vw;
    // right: 2px;
    gap: 20px;
    z-index: 0;
    // background-color: white;
    // background-color: lightgreen;
    z-index: 2;
    @media(max-width: 1536px){
      // background-color: lightsalmon;
      // width: 85.7%;
    }
    @media(max-width: 1280px){
      // background-color: lightsalmon;
      // width: 84.9%;
    }
    // @media(min-width)
  

  .clearall {
    width: 135px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background-color: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
    right: 100px;
    cursor: pointer;
  }
  .saveall {
    border: none;
    width: 135px;
    // margin-left: 10px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    // opacity: 0px;
    background-color: #E52333;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    z-index: 1;
  }
}

.link {
  text-decoration: none;
}
}
