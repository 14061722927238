.TrashModal-Container {
  position: fixed;
  top: 0rem;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal vertically */
  z-index: 1000; /* Ensure it appears above other content */
  width: 29%;
  // height: 848px;
}

.TrashModal-Window {
  margin-top: -20vh;
  background-color: white;
  width: 377px;
  height: 184px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the content inside the modal */
  border-radius: 8px; /* Optional: add rounded corners */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
}

.TrashModal-Form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center the text */
}

.TrashImage {
  margin-top: 5px;
  margin-bottom: 1px; /* Add space between the image and text */
}

.Trash-Heading {
  font-size: 16px;
  font-weight: 400;
}
.Trash-Button {
  margin-top: 7px;
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}
.TrashButton1 {
  width: 138.83px;
  height: 46.28px;
  border-radius: 3.47px;
  background-color: white;
  color: rgba(95, 95, 95, 1);
  border: 1px solid rgba(95, 95, 95, 1);
  cursor: pointer;
  font-family: Poppins;
  font-size: 16.2px;
  font-weight: 400;
  line-height: 24.3px;
}

.TrashButtonLoad1 {
  width: 138.83px;
  height: 46.28px;
  border-radius: 3.47px;
  background-color: white;
  color: rgba(95, 95, 95, 1);
  border: 1px solid rgba(95, 95, 95, 1);
  cursor: not-allowed;
  


}

.TrashButton2 {
  width: 138.83px;
  height: 46.28px;
  border-radius: 3.47px;
  background-color: #e52333;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #e52333;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16.2px;
  font-weight: 400;
  line-height: 24.3px;
  
}

.TrashButtonLoad2 {
  width: 138.83px;
  height: 46.28px;
  border-radius: 3.47px;
  background-color: #e52333;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #e52333;
  cursor: not-allowed;
}

@media (max-width: 1461px) {
  .TrashModal-Window {
    margin-top: 20px;
    background-color: white;
    width: 377px;
    height: 184px;
    display: flex;
    justify-content: center;
    align-items: center; /* Center the content inside the modal */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
  }
}

@media (max-width: 1677px) and (min-width: 1525px) {
  .TrashModal-Window {
    margin-top: -20vh;
  }
}

