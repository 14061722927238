.OffersHeaderContainer {
    display: flex;
    justify-content: flex-start;
}

.OffersHeadersWindow {
    width: 83%;
    height: 10%;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.OffersHeadersWindow1{
    width: 60%;
    height: 10%;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.SpecialPriceContainer {
    width: 183%;
    display: flex;
    justify-content: space-between;
}
.SpecialPriceContainer2{
    width: 116%;
    display: flex;
    justify-content: space-between;
}

.SpecialPriceHeading {
    font-size: 24px;
    color: #E52333;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-left: 35px;
}

.ActiveCompletedHeading {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}

.OfferListContainer {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 0;
    margin: 0;
    padding-left: 2rem;
    list-style: none;
}

.OfferListItem {
    display: flex;
    align-items: center;
    color: #9a9fa4; 
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer; 
    
}

.OfferList-Section {
    display: flex;
    align-items: center;
}

.Offerlist-Heading {
    margin-left: 20px;
}

.ActiveCompletediv {
    border: 1px solid #e9e9e9;
    width: 100vw;
}
.ActiveCompletediv2 {
    border: 1px solid #e9e9e9;
    width: 100vw;
}

.AddOfferButton {
    transform: translateY(20px);
    width: 154px;
    height: 38px;
    background-color: #E52333;
    padding: 0;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    border-radius: 7px;
    border-color: #E52333;
    border: none;
    cursor: pointer;
}

.OfferListItem.active-link {
    color: #E52333; 
    font-weight: 500;
    // text-decoration: underline;
    // text-underline-offset:4px ;
    // text-decoration-thickness: 2px;
}

// .active-link{
//     color: #67833e; 
//     font-weight: bold;
   
//     text-decoration: underline;
//     text-underline-offset:4px ;
//     text-decoration-thickness: 2px;

    
// }
 // border-bottom: 4px solid #67833e;
    // border-bottom-right-radius: 5px;
    // border-bottom-left-radius: 5px;
    .active-link {
        color: #E52333;
        font-weight: 500;
        position: relative; 
    }
    .active-link::after {
        content: "";
        display: block;
        height: 3px; 
        background-color: #E52333;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
       
        position: absolute;
        top: 1.37rem;
        left: 0;
        right: 0;
    }