* {
  font-family: "Poppins", sans-serif;
}

.pricingDetails {
  display: flex;
  width: 100%;
  // background-color: pink;
}

.pricingDetailsExpanded {
  display: flex;
  width: 100%;
}

.pricingdetails-containerExpanded {
  position: absolute;
  top: 150px;
  margin-left: -10px;
  overflow-x: hidden;
  // height: 38.5rem;
  // height: 29rem;
  max-height: 70vh;

  // height: fit-content;
  width: 100%;
  overflow-y: auto;
  // background-color: pink;
  // @media (min-width: 1441px) and (max-width: 1536px){
  //   background-color: lightseagreen;
  //   height: 38rem;
  // }
  @media (max-width: 3100px) {
    height: 78vh;
    // border: 1px solid violet;
    // border: 1px solid red;
   
   }
 
  @media (max-width: 2305px) {
    height: 74vh;
    // border: 3px solid pink;
   
   }
   @media (max-width:1920px) {
   
    height: 70vh;
    // border: 1px solid brown;
  }

  @media (max-width: 1710px) {
    height: 67.5vh;
    // border: 1px solid blue;
   
   }
   @media (max-width: 1536px) {
   height: 64.5vh;
    // border: 1px solid green;
   
   }
  @media (max-width: 1400px) {
    height: 62vh;
    // border: 1px solid rosybrown;
    
    
  &::-webkit-scrollbar {
    display: none;
  }
  
  }
  @media (max-width: 1300px) {
    height: 58vh;
    
    // border: 1px solid goldenrod;
 
  
  }
  &::-webkit-scrollbar {
    display: none;
  }
  
}

.pricingdetails-container {
  position: absolute;
  top: 150px;
  // padding-left: -2rem;
  margin-left: -1rem;
  overflow-x: hidden;
  // height: 37.5rem;
  // height: 42rem;
  // height: 29rem;
  // max-height: 70vh;
  width: 93.3%;
  overflow-y: auto;


  @media (max-width: 3100px) {
    height: 78vh;
    // border: 1px solid violet;
    // border: 1px solid red;
   
   }
 
  @media (max-width: 2305px) {
    height: 74vh;
    // border: 3px solid pink;
   
   }
   @media (max-width:1920px) {
   
    height: 70vh;
    // border: 1px solid brown;
  }

  @media (max-width: 1710px) {
    height: 67.5vh;
    // border: 1px solid blue;
   
   }
   @media (max-width: 1536px) {
   height: 64.5vh;
    // border: 1px solid green;
   
   }
  @media (max-width: 1400px) {
    height: 62vh;
    // border: 1px solid rosybrown;
    
    
  &::-webkit-scrollbar {
    display: none;
  }
  
  }
  @media (max-width: 1300px) {
    height: 58vh;
    
    // border: 1px solid goldenrod;
 
  
  }
  &::-webkit-scrollbar {
    display: none;
  }
  // // background-color: pink;
  // @media (max-width: 2200px) {
  //   // background-color: burlywood;
  //   max-height: 70vh;
  // }
  // @media (max-width: 1930px) {
  //   // background-color: red;
  //   max-height: 70vh;
  // }

  // @media (min-width: 1400px) and (max-width: 1440px) {
  //   // background-color: lightsalmon;
  //   max-height: 70vh;
  // }

  // @media (max-width: 1600px) {
  //   // background-color: lightseagreen;
  //   overflow-x: hidden;
  //   max-height: 70vh;
  // }

  // @media (min-width: 1641px) and (max-width: 1766px) {
  //   // background-color: plum;
  //   max-height: 70vh;
  // }
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.pricing-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 40px;
  // overflow-y: auto;
  // height: 82vh;
  padding-bottom: 95px;
  // height: 100%;
  overflow-x: hidden;
  // background-color: pink;
  // @media (min-width: 1400px) and (max-width: 1440px){
  //   background-color: palegreen;
  //   height: 82vh;
  // }
  // @media (min-width: 1441px) and (max-width: 1536px) {
  //   padding-left: 5px;
  //   // background-color: lightgreen;
  //   // height: 65vh;
  //   height: 100%;
  //   overflow-y: scroll;
  // }
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // font-size: 14px;
  .buttonContainer {
    width: 100%;
    position: absolute;
    left: 15.5px;
  }
  .buttonContainerExpanded {
    width: 100%;
    position: absolute;
    left: 10px;
  }
}

.KitchenRelated {
  display: flex;
  // align-items: center;
  height: auto;
  // border: 1px solid red;
}
// .KitchenRelated > div {
//   flex: 1; /* This will make the divs take equal space */
// }
.D1kitchen {
  // margin-top: -80px;
  // margin-left: -20px;
  height: 6rem;
  width: fit-content;
  // border: 1px solid red;

  display: flex;
  align-items: center;
  .kitche-stations-dropdown {
    position: relative;
  }
}

.Prepartiontime {
  margin-top: -1.2rem;
  .heading {
    position: relative;
    top: -0.5rem;
    color: #424242;
    font-family: "Poppins", sans-serif;
    font-size: 14.38px;
    font-weight: 400;
    line-height: 21.56px;
    letter-spacing: 0.004em;
    text-align: left;
  }
}

.Prepartiontime-input-fileds {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  top: -0.3rem;
  color: #000;
  font-family: Poppins;
  font-size: 14.38px;
  font-weight: 400;
  line-height: 21.56px;
  letter-spacing: 0.004em;
  text-align: left;
}

.Prepartiontime-input-hours {
  width: 48px;
  height: 40px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28.75px;
  letter-spacing: 0.0015em;
  text-align: center;
  border-radius: 5.41px;
  border: 1px solid #9f9f9f;
}

// .Prepartiontime-input-mins {
//   width: 48px;
//   height: 40px;
//   font-weight: 400;
//   border-radius: 5.41px;
//   font-family: Poppins;
//   border: 1px solid #9f9f9f;
// }

.Prepartiontime-input-mins {
  width: 48px;
  height: 40px;
  border-radius: 5.41px;
  text-align: center;
  border: 1px solid #9f9f9f;
  // &::placeholder {
  //   color: ;
  // }
}

.timeSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 18.5px;
  font-weight: 400;
  line-height: 28.75px;
  letter-spacing: 0.0015em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.KitchenRelatedHeading {
  margin-top: 20px;
  transform: translateY(15px);
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.ErrorsForm {
  font-size: 0.75rem;
  color: red;
  font-weight: 500;
}
.KitchenInput {
  -webkit-appearance: none;
  width: 18.75rem;
  height: 44px;
  border-radius: 0.313rem;
  font-size: 0.875rem;
  color: #979797;
  font-weight: 400;
  padding-left: 15px;
  background-size: 1.5rem;
}
.Tool {
  display: flex;
  justify-content: flex-start;
}
.ToolKitchen {
  transform: translateY(22px);
  opacity: 40%;
  border: none;
  outline: none;
}
.Imgarrow {
  position: relative;
  top: 15px;
  left: -380px;
  height: 8px;
  width: 16px;
  z-index: 1;
}
.Imgarrow2 {
  position: relative;
  left: -80px;
  top: 20px;
  height: 8px;
  width: 16px;
}
.checkbox1-Kitchen {
  accent-color: #E52333;
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-left: -1px;
  transform: translateY(5px);
  cursor: pointer;
}
.Kitchen-checkbox {
  width: fit-content;
  height: fit-content;
  margin-top: 14px;
  margin-left: 5px;
}
.services-Heading {
  height: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-left: 6px;
  margin-bottom: -5px;
  margin-top: 10px;
}

.InventoryToggle {
  margin-top: -17rem;
  display: flex;
  justify-content: flex-start;
  gap: 25px;
}
.IHeading {
  font-size: 1rem;
  font-weight: 500;
}
.toggleI {
  transform: translateY(1.5px);
}
.toggleII {
  transform: translateY(16px);
}
.toggleIII {
  transform: translateY(16px);
  margin-left: -3px;
}
.toggleIV {
  transform: translateY(16px);
}
.toggleV {
  transform: translateY(16px);
}
.InventorySection {
  display: flex;
  flex-direction: column;
}
.InventoryHeading {
  // margin-top: 10px;
  // display: flex;
  // gap: 3.75rem;
  // font-size: 0.875rem;
  // font-weight: 400;
  // color: #979797;
  // margin-left: 1.8px;
}
.InventoryInput {
  display: flex;

  gap: 1rem;
  input {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}
.ErrorsFormi2 {
  font-size: 0.75rem;
  color: red;
  font-weight: 500;
  margin-left: 305px;
  margin-top: -19px;
}
.Inventorycheck {
  margin-left: 5px;
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  // background-color: lightgreen;
  height: fit-content;
}
.ToolInventory {
  transform: translateY(22px);
  margin-left: -30px;
  opacity: 40%;

  width: 20px;
  height: 20px;
}
.ToolInventory1 {
  transform: translateY(23px);
  // margin-left: -30px;
  opacity: 40%;
  margin-left: -29.5px;

  width: 20px;
  height: 20px;
}
.I1 {
  width: 244px;
  font-size: 14px;
  color: #979797;
  height: 41px;
  border-radius: 0.313rem;
  border: 1px solid #979797;
  padding-left: 15px;
}
.I1red {
  width: 244px;
  font-size: 14px;
  color: #979797;
  height: 44px;
  border-radius: 0.313rem;
  padding-left: 15px;
}

.bg-grey2 {
  position: relative;
  top: -44px;
  left: 494px;
  // top:-44px;
  // left:409px;
  left: -61px;
  top: 1px;

  background-color: #d9d9d9;
  width: 44px;
  height: 42.5px;
  // border-radius:5px;
  margin-bottom: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.threshold {
  margin-left: 0.1rem;
}

.uparrowdownarrow2 {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  // top:-77px;
  // left:522px;
  top: 10px;
  left: 15px;
  display: flex;
  flex-direction: column;
  gap: 0.563rem;
}
.arrow {
  width: 0.875rem;
  height: 0.5rem;
}

.Inventcheckbox {
  margin-top: 60px;
  .checkboxI {
    margin-top: -55px;
  }
  .checkbox2 {
    margin-top: 5px;
  }
}

.InventoryHeadingII {
  margin-left: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #979797;
}
.checkbox1-color {
  accent-color: #E52333;
  width: 1.25rem;
  height: 1.25rem;
  transform: translateY(5px);
}

.NormalSpecial {
  display: flex;
  justify-content: flex-start;
  gap: 60px;
  margin-top: 10px;
  accent-color: #E52333;
}

.N1 {
  margin-left: 3px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.S1 {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 500;
}
.S1radio {
  width: 20px;
  height: 20px;
  transform: translateY(5px);
}
.N1radio {
  width: 20px;
  height: 20px;
  transform: translateY(5px);
}

.buttoncomponentpricing {
  // margin-bottom: 10px;
  position: relative;
  margin-top: 2rem;
  // top: 200px;
  left: -75px;
  display: flex;
  width: 1290px;
  border: 1px solid white;
}
.buttoncomponentpricing1 {
  // margin-bottom: 10px;
  position: relative;
  top: 200px;
  margin-top: 2rem;
  left: -55px;
  display: flex;
  width: 1500px;
  border: 1px solid white;
}

.saveandnextPricingExpanded {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;
  right: 2px;
  gap: 10px;

  .clearallPricing {
    width: 135px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 9px;
    border-radius: 4px;
    opacity: 0px;
    background: white;
    color: #67833e;
    border: 1px solid #67833e;

    font-size: 16px;
    font-weight: 500;
  }
  .saveall {
    border: none;
    width: 135px;
    margin-left: 10px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    opacity: 0px;
    background: #E52333;
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}
.saveandnextPricing {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;
  right: 2px;
  gap: 10px;
  z-index: 2;

  .clearallPricing {
    width: 135px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    opacity: 0px;
    background: white;
    color: #E52333;
    border: 1px solid #E52333;
    font-size: 16px;
    font-weight: 500;
  }
  .saveall {
    border: none;
    width: 135px;
    margin-left: 10px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 10px;
    border-radius: 4px;
    opacity: 0px;
    background: #67833e;
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}
.link {
  text-decoration: none;
}
.Button-Save {
  margin-left: 380px;
  position: relative;
  top: 10px;
}
.Button-SaveExtended {
  position: relative;
  top: 10px;
  margin-left: 100px;
}

.tool-tip-kitchen-detail {
  position: relative;
  // background-color: orange;
  top: 1.4rem;
  left: 0.5rem;
  width: fit-content;
  margin-top: -0.5rem;
}
