*{
    font-family: "Poppins",sans-serif;
 }
 .AvailSlider-Container{
  

   //   width:395px;
   //   height: auto;
 
   //   overflow: hidden;
   //   margin-left: 20px;
   //   margin-bottom: 20px;
   padding-top: 20px;
   padding-left: 10px;
   height: auto;
   
 }
 .AvailSlider-Heading{
     
     font-size: 20px;
     font-weight: 500;
     margin-left: 0.7rem;
     
 }
 .AvailHeading-Section{
   display: flex;
   justify-content: flex-start;
   gap:30px;
   margin-top: -2%;
   
   .sub-head
   {
  
font-size: 16px;
font-weight: 400;
line-height: 40px;
text-align: right;


   }
   .toggle-btnfor-subhead
   {
      margin-top: 9%;
      // border: 1px solid red;
   }
   
 }
 .SectionASectionBSection{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  
    margin-bottom: 10px;
    margin-left: -25%;
 }
 .AvailOnprem-Ofprem{
    
     margin-top: 20px;
     margin-left: 20px;
     display: flex;
     flex-direction:column ;
 }
 .SectionASectionBSectionHeading{
    width:100px;
    font-size: 16px;
    font-weight: 400;
    color:black;
   //  border: 1px solid red;
    display: flex;
    justify-content: right;
    margin-left: -1.5rem;

       
 }
 .SectionASectionBSectionHeadingBlack{
    width: 100px;
    color:black;
    font-size: 16px;
    font-weight: 400;
 }
 .Avail-SectionAB{
    display: flex;
    justify-content: flex-start;
 }
 .TypeHeading{
    display: flex;
    justify-content: flex-start;
    gap:15px;
    margin-top: 10px;
    margin-bottom: 10px;
 }
 .TypeHeading1{
   display: flex;
   justify-content: flex-start;
   gap:15px;
   margin-top: 10px;
   margin-bottom: 10px;
   // width: 90px;
}
 .AvailOnprem-Heading{
     font-size: 16px;
     font-weight:400;
 }
 
 .OnpremToggle{
    margin-top: 3px;
 }
 .AvailSectionA{
     margin-left: 27px;
     margin-top: 10px;
     display: flex;
     justify-content: flex-start;
 }
.PickupDeliveryAvail{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
 .SectionAToggle{
    margin-left: 35px;
    margin-top: 3px;
 }
 .subcategorySection{
    margin-top: 10px;
    display: flex;
    gap:20px;
    justify-content: flex-start;
 }
 .AvailSectionA-Image{
     margin-left: 10px;
 }
 .AvailWeighImages{
     margin-left: 10px;
     width:30px;
     height: 23px;
     border-color:#D9D9D9;
 }
 .TypesSection{
   margin-top: -7%;
 margin-left: -65%;
    display: flex;
    flex-direction: column;
 }