.switch-container {
  padding: 0 0 0;

  input[type="checkbox"] {
    width: 30px;
  }

  input[type="checkbox"].switch-input {
    position: absolute;
    opacity: 0;
  }

  input[type="checkbox"].switch-input + div {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border-radius: 999px;
    background-color: #f1f1f1;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
  }

  input[type="checkbox"].switch-input + div span {
    position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
  }

  input[type="checkbox"].switch-input + div span:nth-child(1) {
    margin-left: 15px;
  }

  input[type="checkbox"].switch-input + div span:nth-child(2) {
    margin-left: 55px;
  }

  input[type="checkbox"].switch-input:checked + div {
    width: 25px;
    background-position: 0 0;
    background-color: #3b89ec;
  }

  input[type="checkbox"].switch-input + div {
    width: 30px;
    height: 9px;
  }

  input[type="checkbox"].switch-input:checked + div {
    background-color: #4fef68;
  }

  input[type="checkbox"].switch-input + div > div {
    float: left;
    width: 17px;
    height: 17px;
    border-radius: inherit;
    background: #dadada;
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: -4px;
    margin-left: 1px;
  }

  input[type="checkbox"].switch-input:checked + div > div {
    -webkit-transform: translate3d(12px, 0, 0);
    transform: translate3d(12px, 0, 0);
    background-color: #00b71d;
    opacity: unset;
  }

  input[type="checkbox"].bigswitch-input.switch-input + div > div {
    width: 44px;
    height: 44px;
    margin-top: 1px;
  }

  input[type="checkbox"].switch-input:checked + div > div {
    -webkit-transform: translate3d(12px, 0, 0);
    transform: translate3d(12px, 0, 0);
  }
}

// search

.search-sec {
  position: relative;
  width: 27%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  & input {
    font-family: "Poppins", sans-serif;
    margin: 0px;
    border: 0px;
    width: 100%;
    margin-left: 30px;
  }
  & img {
    position: absolute;
    right: 15px;
    top: 8px;
  }
}

// dropdown

.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;

  select {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    border: 1px solid #dadada;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    margin-bottom: 20px;
    width: 100%;
    background: transparent;
    appearance: none;
    font-family: "Poppins", sans-serif;
    @media (max-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  select:hover,
  select:focus {
    color: #9b9b9b;
    background: #fff;
  }

  select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .select__arrow {
    position: absolute;
    top: 30%;
    right: 15px;
    font-size: 24px;
  }
}

// input

input {
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 5px;
  color: black;
  padding: 5px 12px;
  // margin-bottom: 20px;
  width: 100%;
  &::placeholder {
    color: #979797;
    font-family: "Poppins", sans-serif;
  }
}

// button

.form-cta {
  display: flex;
  justify-content: flex-end;
  margin-top: 15%;
  margin-right: 30px;
  & button {
    margin-right: 30px;
    min-width: 200px;
  }
}

.button {
  & button {
    background: #fff;
    border: 1px solid !important;
    color: #fff;
    font-size: 18px;
    line-height: 23px;
    font-family: "Poppins", sans-serif;
    padding: 13px 33px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      font-size: 27px;
      vertical-align: middle;
      margin-right: 23px;
    }
  }
}

.checkbox-circle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 13px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #afafaf;
}

/* On mouse-over, add a grey background color */
.checkbox-circle:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-circle input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark::before {
  content: "";
  position: absolute;
  display: block;
}

.checkbox-circle .checkmark::before {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-circle input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-circle .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.green-txt {
  color: #67833e;
}

.m-b-15 {
  margin-bottom: 15px;
}

.gift-img {
  display: block;
  margin: 0 auto;
  width: 700px;
}

.border-btn {
  border: 1px solid #67833e;
  color: #67833e;
  background-color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
}

.offer-btn {
  background: #67833e;
  text-align: center;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  min-width: 150px;
  cursor:pointer;
}

.tab_border {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 30px;
}

.d-inline-block {
  display: inline-block;
}

.top_btn {
  padding: 10px;
  font-size: 14px;
}

.float-right {
  float: right;
}

.srch_inpt {
  width: auto;
  float: right;
  margin-right: 15px;
}

.radio_btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.row {
  display: flex;
  margin: 0 -15px;
}

.col-md-6 {
  width: 50%;
  padding: 0 15px;
}

.day_select span {
  // padding: 0 10px;
  color: #b5b5b5;
  border-radius: 50%;
  width: 25px;
  display: inline-block;
  height: 25px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}

.day_select span.select {
  color: #67833e;
  border: 1px solid #67833e;
}

.m-t-20 {
  margin-top: 20px;
}

.w-50 {
  width: 50%;
}

.top_shadow {
  -webkit-box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
}

.tabs-titles {
  list-style: none;
  padding: 0px;
  margin: 0;
}

.tab-title {
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  color: #c7c6c2;
  cursor: pointer;
  margin-left: 1px;
}

.tab-title--active {
  background-color: #f5f5f5;
  color: #00070a;
}

.tab-content {
  background-color: #f5f5f5;
  padding: 5px;
  margin: 0;
}

.create_tab {
  font-size: 20px;
  border-width: 4px;
}

.create_tab.unselected {
  border-bottom: 4px solid #ccc;
}

.Dropdown-placeholder.placeholder-class.is-selected {
  font-size: 14px;
}

.form-check {
  .form-check-input {
    width: auto;
    margin-right: 5px;
  }
}

.ant-select-selection-item {
  .form-check {
    .form-check-input {
      display: none;
    }
  }
}
.main-section .menu ul li {
  list-style: none;
}
.Offertextinput {
  margin: 2px 0px 14px 0px;
  padding: 8px;
}

.offer-btn.cancel-btn {
  margin: 0 20px 0 0;
  background: none;
  color: #8c8c8c;
  border: 1px solid #8c8c8c;
  display: inline-block;
  font-size: 16px;
  height: 15px;
  width: 150px;
  line-height: 15px;
}
.drop-down .dropdown-arrow {
  width: 10px !important;
  height: 6px !important;
}
.footer {
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.25);
}

.mainpage_boxshade {
  box-shadow: 4px 0px 6px 3px #e9e9e9;
  padding: 30px 50px;
  margin: 0;
}

.buttons_Section {
  margin: 30px 50px 30px 0;
}

.preview_hdng {
  font-size: 14px;
  color: #999;
  margin-bottom: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #67833e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #67833e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  display: none;
  max-width: 250px;
  border-radius: 6px;
  font-size: 14px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  font-weight: normal;
  padding: 12px;
  position: absolute;
  z-index: 1;
  bottom: -6px;
  left: 90%;
  width: 240px;
  text-align: left;
}

.tooltip:hover .tooltiptext {
  display: block;
}
.m-0 {
  margin: 0;
}
.d-flex {
  display: flex;
}
.me-3 {
  margin-right: 20px;
}
.primary_details {
  max-width: 80%;
  width: 100%;
}
.subheadingsize {
  font-size: 16px;
  font-weight: normal;
}
.ml-8 {
  margin-left: 8px;
}
.w-100 {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .primary_details {
    max-width: 100%;
  }
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
 
  color: #000;
  border: 0px !important;
  border-radius: 0.3rem;
  background: #ffffff !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25) !important ;
  display: inline-block;
  position: relative;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #979797 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__month-container {
  background-color: white;
  color: #969eac;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  .react-datepicker__month {
    padding: 1rem 0;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0.2rem;
    &:hover {
      background-color: #67833e !important;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 2.3rem;
    background-color: #E52333;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 2.3rem;
    background-color: #E52333;
    color: #fff !important;
  }
}
.react-datepicker__header {
  text-align: center;
  background-color: #ffff !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__month-container .react-datepicker__month {
  padding:  0 !important; 
}
 tbody tr{border-bottom:0.001em inset #ECECEA!important ;}