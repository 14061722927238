.dropdown-containercomponentitem {
  position: relative;
  width: 300px;
  height: 0px;
  z-index: 1;
  position: relative;
  left: 20px;
  top: 20px;
}

.dropdowncomponentitem {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cPdropdown-arrow {
  position: relative;
  margin-left: -10%;
  opacity: 60%;
}

.cPdropdownimageclosed {
  rotate: 180deg;
  cursor: pointer;
}

.cPdropdownimageopen {
  cursor: pointer;
}

.cPDropdown-Error-message {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  transform: translateY(-7px);
  margin-bottom: -20.5px;
  margin-top: 8px;
  // margin: 0;
  // background-color: pink;
  height: fit-content;
  width: fit-content;
}

.cPdropdown-search {
  width: 308px;
  height: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  padding-right: 2rem;
  padding-left: 0.625rem;
  border-radius: 0.313rem;
  color: black;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.3);
  &:focus {
    outline: none;
  }
}

.cPdropdownBodyContainer {
  position: relative;
  .cPdropdownbody {
    position: absolute;
    width: 308px;
    // height: 10.8rem;
    z-index: 5;
    background: #ffffff;
    box-shadow: 1px 1px 10px 0px #00000040;
    display: flex;
    flex-direction: column;

    .cPedititem {
      margin-left: -10px;
    }

    .cPDropdown-lists-and-edit {
      display: flex;
      justify-content: space-between;
      // padding-right: 10px;

      .cPediticonimage {
        margin-top: 0.5rem;
        position: relative;
        left: -2rem;
        font-size: 16px;
        font-weight: 400;
        color: #67833e;
        text-decoration: underline;
      }
    }

    .cPdropdown-options {
      max-height: 7rem;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 0;
      padding: 0;
      margin-top: 0.5rem;
      // border: 1px solid red;
      cursor: pointer;
      &::-webkit-scrollbar {
        display: none;
      }
      .cPdropdown-option-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        // border: 1px solid red;
      }
      .cPdropdown-option-delete {
        display: flex;
        position: relative;
        color: #e52333;
        font-size: 12px;
        width: 400px;
        right: 1.3rem;
        .disabled-delete {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .cPdropdown-option {
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        padding-left: 1rem;
        // border: 1px solid red;

        .cPdropdon-option-inputfield {
          accent-color: #E52333;
          width: 1.25rem;
          height: 1.25rem;
          // border: 1px solid red;
        }

        .cPdropdon-option-label {
          padding-left: 0.5rem;
          width: 200px;
          // border: 1px solid red;
        }
      }
    }
    .cPdropdown-Addbutton {
      height: 3rem;
      // border: 1px solid blue;
      position: relative;
      right: 5px;
      .cPdropdown-addnew {
        height: 3rem;
        display: flex;
        align-items: center;
        position: relative;
        left: 6.5%;
        // border: 1px solid red;
        // padding: 0.5rem;
        .cPdropdown-addnew-input-and-button {
          display: flex;
          width: 92%;
          height: 2rem;
          border: 1px solid #779749;
          border-radius: 0px 4px 4px 0px;

          .cPdropdown-addnew-input-filed {
            width: 75%;
            height: 90%;
            border: none;
            padding-left: 10px;

            &:focus {
              border: none;
              outline: none;
            }
          }
          .cPdropdown-addnew-button {
            width: 25%;
            border: none;
            outline: none;
            background-color: #779749;
            color: #ffffff;

            border-radius: 0px 4px 4px 0px;
            cursor: pointer;
          }
        }
      }

      .cPdropdown-no-options {
        width: 20rem;
        height: 120px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 10rem;
        top: 20%;
        color: red;
      }

      .cPimgLoader1 {
        // width: 30px;
        // height: 30px;
        // position:relative;
        top: 12vw;
        left: 21.5vw;
        animation: rotate 1s linear infinite;
      }

      .cPAddnew-edit-fields {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
        right: 0.1rem;
        bottom: 1px;

        .cPdropdown-edit-button {
          width: 3rem;
          position: relative;
          .cPdropdown-edit-done {
            color: #67833e;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid #67833e;
            text-align: center;
            border-radius: 4px;
            width: 65px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .cPdropdown-addbutton {
          width: 6.813rem;
          height: 2.063rem;
          color: #ffffff;
          border-radius: 0.25rem;
          background: #779749;
          outline: none;
          border: 1px solid #779749;
          cursor: pointer;
        }
      }
    }
  }
}

.cPspecialTypeContainer {
  margin: 0;
}

.ArrowPricingitem {
  width: 16px;
  height: 8.67px;
  opacity: 30%;
}

.ArrowPricingRotateitem {
  transform: rotate(180deg);
  width: 16px;
  height: 8.67px;
  opacity: 30%;
}

.dropdown-contentitem {
  position: absolute;
  top: 100%; /* Align dropdown content directly below the trigger */
  left: 0;
  width: 100%;
  height: 145px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  padding: 10px 0; /* Adjust padding as needed */
}

.optionsPricingitem {
  height: 90px; /* Adjust height as needed */
  overflow-y: auto;
  scrollbar-width: 0px;
  overflow-x: hidden;

  /* Add border to separate options and button */
  width: 300px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.optionsPricing label {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  // margin: -10px;
}

// .optionsPricing label:hover {
//   background-color: #f0f0f0;
// }

.radiobtPricingitem {
  accent-color: green;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px; /* Added margin to the right */
}

.labelDropitem {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
}

.options-btnPricingitem {
  display: flex;
  width: 58px;
  height: 35px;
  font-family: Poppins;
  font-size: 14px;
  color: white;
  background-color: #779749;
  text-align: center;
  border-radius: 13px;
  cursor: pointer;
  margin-left: -55px;
  margin-top: -10px;
  padding: 6px;
}

.inputntnoptionsPricingitem {
  margin-top: -10px;
  margin-left: -5px;
  width: 292px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid grey;
}

.addbtnPricingitem {
  display: flex;
  align-items: center;
  padding: 10px;
}

.btnADDPricingitem {
  position: relative;
  left: 188px;
  width: 99px;
  height: 34px;
  border: 1px solid #779749;
  background-color: #779749;
  color: #fff;
  top: -10px;
  width: 109px;
  height: 33px;
  border: 1px solid #779749;
  background-color: #779749;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: -3px;
  left: 185px;
  border-radius: 4px;
}

.cPdropdown-no-options-msg {
  width: 18rem;
  height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // left: 5%;
  // border: 1px solid red;
  top: 30%;
}
.cPdropdown-no-options {
  width: 18rem;
  height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // left: 5%;
  // border: 1px solid red;
  // top: 30%;
}
