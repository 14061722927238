.summary-box {
    height: 8rem;
    width: 11.8rem;
    border-radius: 1rem;
    background-color: var(--table-bg);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--table-text);
    border: 1px solid;
    position: relative;

    // @media(max-width: 1536px){
    //   height: 20vh;
    //   width:  50vw;
    //   background-color: darksalmons;
    // }

    @media (max-width: 1280px) {
      width: 9.7rem;
    }

    @media (max-width: 1080px) {
      width: 35%;
    }

    @media (max-width: 800px) {
      width: 80%;
    }

    @media (max-width: 500px) {
      width: 80vw !important;
      // background-color: pink;
    }

    h2 {
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
      @media(max-width: 800px){
        font-size: small;
      }
      @media(max-width: 500px){
        font-size:  x-large;
      }
    }

    h3 {
      margin-bottom: 0rem;
      margin-top: 0rem;
      @media(max-width: 800px){
        font-size: x-small;
      }
      @media(max-width: 500px){
        font-size:  large;
      }
    }
    .label-tooltip-container {
      display: flex;
      align-items: center;
      gap: 1vw;
  
      .s-tooltip-wrapper {
        position: relative;
        display: inline-block;
        // background-color: orange;
        height: 2.9vh;
        border-radius: 1vw;
  
        .s-tool-tip-image {
          cursor: pointer;
          height: 2.8vh;
          // background-color: pink;
          border-radius: 1vw;
        }
  
        .tool-tip-content {
          position: absolute;
          top: 120%; // Adjust to position the tooltip below the icon
          left: 50%;
          transform: translateX(-50%);
          background-color: #f9f9f9;
          color: #333;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 0.5rem;
          width: max-content;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 10;
          font-size: 0.9rem;
          text-align: center;
          white-space: nowrap;
          opacity: 0; // Hidden by default
          visibility: hidden; // Prevent interaction when hidden
          transition: opacity 0.3s ease, visibility 0.3s ease;
          @media (max-width: 800px) {
            top: 110%; 
            font-size: 0.8rem; 
            padding: 0.4rem; 
            max-width: 60vw; 
            white-space: normal;
            text-align: left;
          }
        }
  
        &:hover .tool-tip-content {
          opacity: 1; // Show tooltip on hover
          visibility: visible;
        }
      }
    }
  }