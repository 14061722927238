.KitchenRelatedHeadingNormal {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.063rem;
  margin-top: 10px;
}
.AvailableServiceHeading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.063rem;
  margin-top: 10px;
  color: rgba(0, 0, 0, 1);
}

.dayschecking {
  // margin-top: -1.2rem;
  // margin-bottom: 0.5rem;
  // position: relative;
  // left: 10px;
  // height: 60px;
  // border: 1px solid red;
  // margin-left: -10px;
  margin-top: -1.1rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  height: 5rem;
  gap: 0.2rem;
  // border: 1px solid red;
  justify-content: left;
  margin-left: 10px;
  .Note {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    color: #5f5f5f;
    margin-top: -0.5rem;
    
    // border: 1px solid blue;
  }
  .Note-error {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    color: #5f5f5f;
    
  }
}
.ServiceDropDown {
  transform: translateY(60px);
  z-index: 1;
}
.PickupDrop {
  transform: translateY(60px);
}
.DeliveryDrop {
  height: 0px;
  width: 0px;
  margin-top: 60px;
  margin-left: -10px;
  // border: 2px solid red;
}
.ThirdDeliveryDrop1 {
  transform: translateY(60px);
}
.ThirdDeliveryDrop {
  transform: translateY(60px);
  z-index: 100000;
}

.DineInRelatedHeadingNormalAvail {
  font-size: 0.875rem;
  font-weight: 500;
  // margin-top: 1rem;
}
.DineInRelated {
  display: flex;
  justify-content: left;
  gap: 2rem;
  align-items: center;
  width: fit-content;
  height: 30px;
  margin-top: 3rem;
  // border: 1px solid red;
}
.dine-in-toggle
{
  margin-top: -0.1rem;
}
.Online-delivery {
  margin-top: 1rem;
  // height: 5rem;
  // border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  position: relative;
  .Dine-In-Price {
    display: flex;
    flex-direction: column;
    .Errormsg {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #e52333;
    }
  }
}

.Online-delivery:nth-child(1) {
  z-index: 1;
}

.Online-delivery:nth-child(2) {
  z-index: 2;
}
.DeleteButtonDine {
  margin-top: 12px;
  margin-left: 15px;
  color: #e52333;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.dayspickup {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  // background-color: pink;
  width: fit-content;
  margin: 0;
}
.dayspick-pickup {
  margin-top: -1.95rem;
  // margin-bottom: 1.5rem;
  // background-color: lightcyan;
  width: fit-content;
// height: 4rem;
display: flex;
flex-direction: column;
// margin-bottom: 1rem;
  // border: 1px solid red;
  .pickupdays-errormsg
  {
    color: #e52333;

  }
}
.dayspickup-normal {
  
  // border: 1px solid red;
  
    margin-top: -1.7rem;
    // margin-bottom: 1.5rem;
    
    width: fit-content;
  height: 4rem;
  display: flex;
  flex-direction: column;
    // border: 1px solid red;

    // margin-top: -7rem;
    .deliverydays-error
    {
      color: #e52333;
      font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  margin-top: -0.3rem;
      
    }
    
  
}

.DineInInput11Normal {
  display: flex;
  justify-content: flex-start;
  margin-top: 7px;
  gap: 1.8rem;
  position: relative;
  .Dine-In-Price {
    display: flex;
    flex-direction: column;
    .Errormsg {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #e52333;
    }
  }
  // margin-top: 0.3rem;
}
.DineInInput11Normal:nth-child(1) {
  position: relative;
  // top: 2rem;
  z-index: 1;
}

.DineInInput11Normal:nth-child(2) {
  position: relative;
  // top: 2rem;
  z-index: 2;
}
.LabelPrice {
  // transform: translateY(4px);
  margin-left: 1px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  // margin-top: -0.2rem;
}
.LabelPrice-delivery {
  transform: translateY(15px);
  margin-left: 1px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.Thrid-party-price {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1px;
}
.Service {
  margin-top: -25px;
  margin-left: -10px;
  z-index: 10;
  .Errormsg {
    position: relative;
    top: 1.3rem;
    left: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
  }
}

.DineInInput2Normal {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 18.75rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  font-size: 0.875rem;
  color: #979797;
  padding-left: 5px;
  font-weight: 400;
}
.DineInInput2Normalpickup {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 18.75rem;
  height: 2.75rem;

  font-size: 0.875rem;
  color: #979797;
  padding-left: 5px;
  font-weight: 400;
  margin-left: 20px;
}

.DineInInput1Normal {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 9.75rem;
  height: 2.75rem;
  border-radius: 5px;
  font-size: 14px;
  color: black;
  padding-left: 10px;
  font-weight: 400;
  // border: 1px solid red;
  // background-color: pink;
}
.PriceInput1Normal-input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 9.8rem;
  height: 2.7rem;
  border-radius: 5px;
  font-size: 14px;
  color: black;
  padding-left: 10px;
  font-weight: 400;
  // border: 1px solid red;
}
.DeliveryInput1Normal {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 9.75rem;
  height: 2.7rem;
  border-radius: 5px;
  font-size: 14px;
  color: black;
  padding-left: 10px;
  font-weight: 400;
}

.DineInInput1Normal::placeholder {
  font-size: 0.875rem;
  color: #979797;
  font-weight: 400;
  // padding-left: 0.813rem;
}
.DineInInputServiceNormal {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.313rem;
  width: 12.5rem;
  height: 2.75rem;
  font-size: 0.875rem;
  color: #979797;
  font-weight: 400;
}

.dineInChooseDayContainer {
  display: flex;
  justify-content: start;
  gap: 0.313rem;
  margin-bottom: 25px;
  margin-top: -0.7rem;
}
.Mealz {
  margin-top: -25px;
  margin-left: -10px;
  z-index: 1;
  // border: 1px solid red;

  .Errormsg {
    position: relative;
    top: 1.3rem;
    left: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
  }
}

.dineInChooseDayContainerHeading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  transform: translate(0px, 21px);
}

.dineInChooseDayContainer-chooseheading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #67833e;
  margin-top: 0px;
  cursor: pointer;
  transform: translateY(22px);
}
.daycheckvalidation {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  margin: 0;
  padding: 0;
 position: relative;
 top: -0.5rem;
  
}

.AddentryNormal {
  position: relative;
  margin-bottom: 19px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #0085ff;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  width: fit-content;
  margin-top: 81px;
}
// .react-datepicker__month-container .OnlineRelatedNormal {
//   display: flex;
//   justify-content: flex start;
//   gap: 1.625rem;
//   margin-top: 8px;
// }
.OnlineRelatedNormal {
  display: flex;
  justify-content: flex start;
  gap: 2.2rem;
  // margin-top: 7px;
}
.OnlineRelatedHeadingNormal {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1rem;
}
.OnlineSectionNormal {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-left: 1.875rem;
  padding-bottom: 100px;
}
.PickupRelatedNormal {
  display: flex;
  justify-content: flex start;
  gap: 2.35rem;
}
.PickupRelatedHeadingNormal {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1rem;
}
.PickupSectionNormal {
  display: flex;
  flex-direction: column;
  // margin-top: -10px;
  // margin-bottom: 0.2rem;
  height: 6.8rem;
  // border: 1px solid red;
}
.PrizeD {
  // margin-top: -25px;
  // margin-left: -12px;
  // z-index: 30;
  // border: 1px solid blue;
}

.DeliveryD {
  margin-top: -25px;
  margin-left: -30px;
  z-index: 1;
  // border: 1px solid black;
}
.LabelPrice1 {
  transform: translateY(13px);
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.LabelPrice2 {
  transform: translateY(27px);
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}

.PickupInput11Normal {
  display: flex;
  justify-content: flex-start;
  // margin-top: 1.25rem;
  // height: 5rem;
  // border: 1px solid red;
  gap: 2.7rem;
}
.PickupInput11Normal:nth-child(1) {
  z-index: 1;
}

.PickupInput11Normal:nth-child(2) {
  z-index: 2;
}
.DeliveryRelatedNormal {
  display: flex;
  justify-content: flex start;
  gap: 2rem;
 margin-top: 1rem;
//   margin-bottom: 1.5rem;
  // margin-top: 0.5rem;
}
.DeliveryRelatedNormalopen {
  


  display: flex;
  justify-content: flex start;
  gap: 2rem;
  margin-top: 0.4rem;
}
.DeliveryRelatedHeadingNormal {
  font-size: 0.875rem;
  font-weight: 500;
}
.toggleV {
  margin-top: -1rem;
}
.DeliverySectionNormal {
  display: flex;
  flex-direction: column;
  margin-top: -1.5rem;
}
.DeliverySectionNormalclose {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.ThirdDeliveryRelatedHeadingNormal {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.2rem;
  margin-bottom: -1.2rem;
}

.thirdpartyContainer {
  height: fit-content;
}

.DeliveryInput2Normal {
  border-radius: 0.313rem;
  width: 18.75rem;
  height: 2.75rem;
  margin-bottom: 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #979797;
  font-size: 14px;
}
.DeliveryInput2::placeholder {
  // padding-left: 0.625rem;
}
.Delivery12 {
  border-radius: 0.313rem;
  margin-bottom: 0.825rem;
  margin-top: 30px;
  display: flex;
  z-index: 100;
  justify-content: flex-start;

  gap: 0.625rem;
  input {
    // padding-left: 10px;
  }

  .DineInInput2Normal {
    margin-left: 20px;
  }
}
.Delivery13 {
  border-radius: 0.313rem;
  margin-bottom: 0.625rem;
  z-index: -1;

  display: flex;
  justify-content: flex-start;
  .DineInInput2Normal {
    margin-left: 20px;
  }
  gap: 0.625rem;
}

.ThridParty-dropdown {
  position: relative;

  border-radius: 0.313rem;
  z-index: 0;
  width: 200px;
  height: 4rem;
  // border: 1px solid red;
  margin-bottom: 10px;
  input {
    padding-left: 15px;
  }
}

.AvailableDaysHeadingNormal {
  font-size: 14px;
  font-weight: 400;
  margin-top: 22px;
  margin-bottom: 0.1rem;
  margin-left: 10px;
}
.AvailDaycheck {
  // margin-top: 200px;
  .AvailDaycheck-Heading {
    width: 170px;
    height: 40px;
    // border:1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

    .tooltip {
      width: 50px;
      height: 50px;
      // border:1px solid red;
      position: relative;
      left: 0.5rem;
      top: 0.2rem;
    }
  }
}

.onlineselected {
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  // background-color: lightblue;
  height: fit-content;
}
.Third1 {
  margin-left: -2px;
  z-index: 2;
}
.Third2 {
  margin-left: -2px;
  z-index: 2;
}
.LabelSwiggyInputDropDown {
  display: flex;
  align-items: center;
  // width: 600px;
  height: 100px;
  margin-bottom: -1rem;

  // border: 1px solid red;
}
.swiggyZomato-input {
  // margin-top: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: black;
  width: 200px;
  height: 44px;
  font-weight: 400;
}
.thridparties-dropdown {
  margin-top: 1.3rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
height: 3rem;
// border: 1px solid red;
.thirdpartyprice
{
  margin-top: 1.7rem;
  margin-bottom: 1rem;

}
}
.swiggyZomatoHeading {
  transform: translateY(24px);
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.LabelSwiggyInput {
  display: flex;
  flex-direction: column;
  margin-top: 4.8rem;
  height: 8rem;
  // border: 1px solid blue;
}
.ErrormsgPickup {
  position: relative;
  top: 20px;
  left: 30px;
  color: red;
  font-size: 14px;
  font-weight: 400;
}
.Errormsg {
  // position: relative;
  // top: 20px;
  // left: 10px;
  color: red;
  font-size: 14px;
  font-weight: 400;
}
.ErrormsgPrice {
  position: relative;
  color:#e52333;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.3rem;
  // border: 1px solid red;
}
.Thirdparty-price-error
{
  position: relative;
  color:#e52333;
  font-size: 14px;
  font-weight: 400;
}
.ErrormsgPickupPrice {
  position: relative;
  top: 44px;
  right: 10rem;
  color: red;
  font-size: 14px;
  font-weight: 400;
}

.DineIn-Fields {
  position: relative;
  top: 5px;
  margin-bottom: 26px;
  // border: 1px solid green;
}

.PickupChooseDayContainer {
  display: flex;
  justify-content: left;
  gap: 0.313rem;

  // border: 1px solid red;
  margin-top: -0.75rem;
  //cursor: pointer;
  width: 400px;
  // height: 2rem;
  align-items: center;
  // margin-bottom: 37px;
}
.error-msg-container-pickup
{
  display: flex;
  align-items: center;
  justify-content: left;
  // border: 1px solid red;

}
.Mealz {
  margin-top: -25px;
  margin-left: -10px;
  z-index: 1;
  // border: 1px solid red;

  .Errormsg {
    position: relative;
    top: 1.3rem;
    left: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
  }
}

.pickupChooseDayContainerHeading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
  margin-top: 10px;
}
.pickupChooseDayContainerHeading
{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
  margin-top: 10px;
}
.pickupChooseDayContainer-chooseheading1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #67833e;
  // margin-top: 0.8rem;
  cursor: pointer;
  // width: 200px;
  height: 2rem;
  text-align: left;
  z-index: 1;
  margin-top: 22px;
  // margin-top: 22px;
  // border: 1px solid red;
}
.pickupChooseDayContainer-chooseheading2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #67833e;
  // margin-top: 0.8rem;
  cursor: pointer;
  // width: 200px;
  height: 2rem;
  text-align: left;
  margin-top: 22px;
  // margin-top: 22px;
  // border: 1px solid red;
}

.deliveryChooseDayContainer {
  display: flex;
  justify-content: left;
  gap: 0.313rem;
  position: relative;
  // bottom: 0.5rem;
  //cursor: pointer;
  width: 400px;
  height: 2rem;
  align-items: center;
  // margin-top: -1.25rem;
}
.Mealz {
  margin-top: -25px;
  margin-left: -10px;
  z-index: 1;
  // border: 1px solid red;

  .Errormsg {
    position: relative;
    top: 1.3rem;
    left: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
  }
}

.deliveryChooseDayContainerHeading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
}

.deliveryChooseDayContainer-chooseheading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  color: #67833e;
  margin-top: 0.2rem;
  cursor: pointer;
  height: 1.5rem;
  text-align: left;
  // border: 1px solid red;
}
.ThirdPartyChooseDayContainer {
  display: flex;
  justify-content: left;
  gap: 0.313rem;
  // border: 1px solid red;
  margin-top: 1.5rem;
  margin-bottom: -0.6rem;
  cursor: pointer;
  width: 400px;
  height: 2rem;
  align-items: center;

  // margin-bottom: 10px;
}
.Mealz {
  margin-top: -25px;
  margin-left: -10px;
  z-index: 1;
  // border: 1px solid red;

  .Errormsg {
    position: relative;
    top: 1.4rem;
    left: 0rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
  }
}

.ThirdPartyChooseDayContainerHeading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
  margin-top: 64px;
  margin-bottom: 40px;
}

.ThirdPartyChooseDayContainer-chooseheading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #67833e;
  margin-top: 106px;
  cursor: pointer;
  //width: 200px;
  height: 2rem;
  text-align: left;
  margin-bottom: 70px;
  // border: 1px solid red;
}


.pickupprice-errormsg {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  margin-top: 0.2rem;
}
.pickuperrormsg {
  // margin-top: -1rem;
  // margin-left: -0.5rem;
  
  // position: relative;
 
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
}
.pickuperrormsgmealType {
  // margin-left: -0.5rem;
  // margin-left: 6.2rem;
  // position: relative;
  // left: 6.2rem;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  // width: 20rem;
  // border: 1px solid red;
  // margin-top: 1rem;
  // position: relative;
  // top: 1.7rem;
}

.delivery-price-errormsg {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  // border: 1px solid red;
  color: #e52333;
}
// .deliverypriceerrormsg {
//   margin-top: -1.2rem;
//   margin-left: -0.5rem;
//   margin-bottom: 2rem;
//   font-family: "Poppins", sans-serif;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 21px;
//   text-align: left;
//   color: #e52333;
// }
.deliverymealtypeerrormsg {
  // margin-left: -0.5rem;
  // margin-bottom: 2rem;
  // margin-top: 20px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
}



.dienInDays-and-error
{
  display: flex;
  flex-direction: column;
  // border: 1px solid red;
  gap: 1.5rem;

  .dineIn-days-error
  {
    color: #e52333;
  }
}



.dine-In-Container
{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 11rem;

  // margin-top: 1.5rem;
  // border: 1px solid red;
}


.dine-In-inputprice-and-mealType
{
  height: 5rem;
  // border: 1px solid blue;
  display: flex;
  gap: 2rem;
  justify-content: left;
  align-items: center;
}

.DineInprice-input-field
{
  width: 156px;
height: 44px;


border-radius: 5px ;
border: 1px solid rgba(0, 0, 0, 0.3);
// opacity: 0.3px;

}
.DineInMealType-input-field
{
  margin-top: -1.9rem;
  // height: 44px;
}

.ChooseDay-container-Dinein
{

  display: flex;
  gap: 0.5rem;
  margin-top: -1.2rem;
  .Back-and-default-dineIn{
    font-family: "Poppins",sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  .defult-and-chooseday-dineIn
  {
    //width: 250px;
    // border: 1px solid red;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #67833E;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
    
  }
}

.DineInPrice-input-field-and-errormsg
{
  display: flex;
  flex-direction: column;
  // height: 7rem;
  // margin-top: 2.5rem;
  // border: 1px solid red;
}


.price-error-for-dineIn
{
  
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
 
  // margin-bottom: 20px;
}

.mealTypeError {
  // top: 1.75rem;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  // top: 1.5rem;
  // position: relative;
  // margin-bottom: 20px;
}

.DaysCheckbox-container-Dinein
{
  height: 4rem;
.checbox-dineIn
{
margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  // border: 1px solid red;
}

  .Dine-Available-Days-Error
  {
    font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  top: 1rem;
  bottom: 1rem;
  position: relative;
  }
}

.pickupdays-errormsg
{
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #e52333;
  margin-top: -0.3rem;
//  margin-top: 1rem;
//  margin-bottom: 1rem;
}


.LabelPricePickup {
  // transform: translateY(20px);
  margin-left: 1px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.pricing-section
{
  height: 100%;
  margin-left: 10px;
  margin-top: -0.7rem;
}
.Error-row-of-dineIn
{
  display: flex;
  justify-content: left;
  align-items: center;
  // border: 1px solid green;
  margin-top: -1.2rem;
  
}

.error-msg-delivey
{
  display: flex;
  justify-content: left;
  align-items: center;
 

  .deliverypriceerrormsg
  {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #e52333;
    width: 14rem;
  }
 
}

.ThirdParty-Available-Days-Error
{
  color: #e52333;
  font-family: "Poppins", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;


}