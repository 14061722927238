.toggleBtnAvail {

    background-color: #D9D9D9;
    width: 20.81px;
    height:14px;
    border-radius: 6.188rem;
    border: 0.063rem solid #D9D9D9;
    
    position: relative;
    cursor: pointer;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;
    top:2px;
    left: -10px;
  }
  
  .thumbAvail {
    border-radius: 7.88rem;
    width: 7.57px;
    height: 8px;
    background-color:rgba(255, 255, 255, 1);
    position: absolute;
    left: 0.188rem;
    transition: left 0.2s;
  }
  .thumbAvailDark{
    background-color:rgba(255, 255, 255, 0.2);  }
  
  .toggleBtnAvail.ToggledAvail {
    background-color: #E52333
    ;
    border-color: #E52333

  }
  
  .toggleBtnAvail.ToggledAvail .thumbAvail {
    left: calc(100% - 10px);
  }
  