* {
  font-family: "Poppins", sans-serif;
}

.dropdown-containerPricing {
  position: relative;
  width: 300px;
  height: 44px;
  margin-left: 30px;
}


.dropdownPricingList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  height: 24px;
  margin-left: -2rem;
  border-radius: 5px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // border: 1px solid red;
  z-index: 0;
  .valuePlaceholder {
    color: #979797;
    font-size: smaller;
  }
}

.dropdownPricingred {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  // border: 1px solid red;
  cursor: pointer;
  width: 200px;
  height: 44px;
  border-radius: 5px;
}

.optionsPricing {
  position: absolute;
  left: -2rem;
  width: 320px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 1px 10px 0px #00000040;
  z-index: 25;
  max-height: 125px;
  overflow-y: auto;
  // display: flex;

  
// &::-webkit-scrollbar {
//   display: none;
// }
&::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

&::-webkit-scrollbar-track {
  background: #b3b3b3;
}

&::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 10px;
  height: 40px;
  
}

&::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
}
}

.optionsPricingz {
  position: absolute;
  left: -2rem;
  width: 320px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 1px 10px 0px #00000040;
  // z-index: 25;
  max-height: 150px;
  overflow-y: auto;
  // display: flex;
  
&::-webkit-scrollbar {
  display: none;
}
}

.optionsPricing label {
  display: flex;
  align-items: center;
  color: #979797;
  font-size: 14px;
  z-index: 10;
  width: fit-content;
  margin: 0 !important;
  padding: 0;

  p{
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 15px;
    margin: 0;
    padding-top: 10px;
  }
}

.optionsPricing input[type="checkbox"] {
  margin-right: 8px;
  z-index: 10;
}

.addbtnPricing {
  padding: 10px;
}

.add-item-btn,
.btn-add-item {
  padding: 5px 10px;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

// .add-item-btn:hover,
// .btn-add-item:hover {
//   background-color: #0056b3;
// }

.checkboxPricing {
  width: 20px;
  height: 20px;
  // transform: translateY(5px);
  accent-color: #E52333;
  margin: 0px 0px 0px 10px;
  padding: 0;
  cursor: pointer;
}

.valuePricing {
  font-size: 14px;
  font-weight: 400;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrowdropPricing {
  width: 16px;
  z-index: 0;
  height: 8.67px;
  opacity: 30%;
  position: absolute; /* Fixes the arrow position */
  right: 10px; /* Always positions the arrow on the right */
}

.arrowrotatePricing {
  z-index: 0;
  width: 16px;
  height: 8.67px;
  transform: rotate(180deg);
  opacity: 30%;
  position: absolute;
  right: 10px;
}

.droplabelPricing {
  font-weight: 400;
  color: #666666;  
  font-size: 14px;
  
  margin-left: -1.8rem;
  margin-bottom: 0.3rem;
  // border: 1px solid red;
  z-index: 30;
}
.No-Option-Availble-dropdown {
  width: 300px;
  height: 120px;
  z-index: 1;
  // border: 1px solid red;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #E52333;
  position: relative;
}
