.radio-button-group {
  display: flex;
  flex-direction: row;
  gap: 3.8rem;
  // background-color: plum;
  height: fit-content;
}

.radio-button {
  //   background-color: pink;
  height: fit-content;
  .radio-button-input-and-label {
    font-size: 0.875rem;
    color: #979797;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
  font-weight: 500;

  }

  .radio-button-input {
    width: 20px;
    height: 20px;
    accent-color: #E52333;
    border: 1px solid #E52333;
    opacity: 100%;
  }
}
