.AvailCalenderContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 416px;
  height: 878px;
}
.date-picking-div {
  border: none;
  box-shadow: none;
  // border: 1px solid red;
  .react-datepicker {
    border: none;
    box-shadow: none;
    border: 1px solid red;
  }
  .react-datepicker__current-month {
    font-family: "Poppins", sans-serif;
    font-size: 10.15px;
    font-weight: 500;
    line-height: 15.23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #494e50;
  }

  .react-datepicker__day-names {
    background-color: #f5f7fa;
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.23px;
    letter-spacing: 0.001em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .react-datepicker__navigation-icon::before {
    border-color: black;
    width: 4.71px;
    height: 4.71px;
  }

  .react-datepicker__day {
    padding: 0.3rem;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.23px;
    letter-spacing: 0.001em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: #E52333;
    color: #fff;
    border-radius: 5.86px;
  }
}
.AvailCalenderWindow {
  margin-top: -140px;
  background-color: white;
  width: 322px;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  .react-datepicker {
    width: 320px;
    font-family: "Poppins", sans-serif;
    font-size: 10.15px;
    font-weight: 500;
    line-height: 15.23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .react-datepicker__month-container {
    width: 100%; // Ensure the content fits the new width
  }
}
.AvailCalenderForm {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Center items vertically */
}
.AvailCalenderHeading {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}
// .react-datepicker {
//     width: 320px;  // Set your desired width
//   }

//   .react-datepicker__month-container {
//     width: 100%; // Ensure the content fits the new width
//   }
.AvailCalenderInputContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-left: 30px;
  margin-top: 10px;
  // border: 1px solid red;
  align-items: center;
}
.time-input-fields {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.AvailCalenderInput1 {
  width: 49px;
  height: 32.02px;
  font-family: "Roboto", sans-serif;
  font-size: 18.3px;
  font-weight: 500;
  line-height: 27.45px;
  letter-spacing: 0.0015em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  border: 1px solid #b3b3b3;
  padding: 0 !important;
}
.AvailCalenderAm {
  border: 1px solid #e52333;
  color: white;
  width: 33.08px;
  height: 30.02px;
  padding: 2px;
  color: black;
  border-top-left-radius: 5.16px;
  border-bottom-left-radius: 5.16px;
  text-align: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.datepicking-div {
  border: 1px solid red;
  display: flex;

  width: 22rem;

  // .react-datepicker__day--keyboard-selected,
  // .react-datepicker__day--selected {
  //   background-color: #5a773b;
  //   color: #fff;
  //   font-weight: bold;
  // }
}
.AvailCalenderPm {
  border: 1px solid #e52333;
  color: white;
  width: 33.08px;
  height: 30.02px;
  padding: 2px;
  color: black;
  display: flex;
  text-align: center;

  transform: translate(-20px);
  border-top-right-radius: 5.16px;
  border-bottom-right-radius: 5.16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.Calselected {
  cursor: pointer;
  background-color: #e52333;
  color: white;
  width: 40px;
}
.AvailCalenderButton {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-left: 30px;
}
.AvailCancel {
  width: 120px;
  height: 40px;
  border: 1px solid #e52333;
  font-size: 14px;
  background-color: white;
  color: #e52333;
  cursor: pointer;
  border-radius: 3.47px;
}
.AvailChange {
  width: 120px;
  height: 40px;
  border: 1px solid #e52333;
  font-size: 14px;
  background-color: #e52333;
  color: white;
  border-radius: 3.47px;
  cursor: pointer;
}

// .Am-Heading{
//   transform: translateY(-15px);
// }
// .Pm-Heading{
//   transform: translateY(-15px);

// }
