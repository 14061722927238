.v2-tooltip {
    position: absolute;
    width: fit-content;
    height: fit-content;
    border: 1px solid #525252;
    background-color: #525252;
    color: white;
    font-size: 14px;
    font-weight: 500;
    bottom: 48px;
    margin-right: 92%;
    padding: 3px 12px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    z-index: 50;

    // Tooltip arrow 
    &::after {
      content: "";
      position: absolute;
      bottom: -10px; 
      left: 52%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #525252; 
    }
  }
  