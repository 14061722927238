.menu-details{
  margin-top: 30px;
  margin-left: 30px;
  width: 84%;
  height: 100%;

  & h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 31px;
    color: #000;
    display: flex;
    align-items: center;
  }
  .title {
    cursor: pointer;
    & svg {
      color: #000;
      font-size: 30px;
      margin-right: 20px;
    }
  }

  h3{
    margin: 10px 0 10px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .inputLabel{
    color: #646262;
    font-size: 16px;
  }

  .errorLabel{
    color: #FF0505;
    font-size: 16px;
  }

  .flexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
  }


  .date-picker-container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .dateInput {
    flex: 1;
    padding-right: 30px;
  }
  
  .inputBox{
    width: 813px;
    height: 50px;
  }

  .nickNameBox{
    width: 400px;
    height: 50px;
  }

  .addressField{
    margin-top: 20px;
  }

  .dateInput {
    height: 50px;
    cursor: pointer;
    position: relative; 
  }
  

  .errorInput{
    height: 50px;
    border-color: #FF0505;
  }

  .countryCodeMo{
    display: flex;
    flex-direction: row;
    height: 47px;
  }

  .errorInputBox{
    width: 813px;
    height: 50px;
    border-color: #FF0505;
  }

  .errorInputBox::placeholder {
    color: #FF0505;
  }

  .errorInput::placeholder {
    color: #FF0505;
  }

  .errorNickNameBox{
    width: 400px;
    height: 50px;
    border-color: #FF0505;
  }

  .errorNickNameBox::placeholder{
    color: #FF0505;
  }

  .checkBox{
    display: flex;
    gap: 10px;
    color: #979191;

    input{
      width: 15px;
    }

    p{
      font-size: 14px;
    }
  }

  .phoneContainer{
    input{
      width: 360px;
      height: 50px;
      border-radius: 0 4px 4px 0;
    }
  }

  .phoneErrorInput input{
    width: 360px;
    height: 50px;
    border-color: #FF0505;
    border-radius: 0 4px 4px 0;
  }

  .phoneErrorInput::placeholder {
    color: #FF0505;
  }

  .addEmployeeEmail{
    width: 400px;

    input{
      width: 395px;
      height: 50px;
    }
  }

  .addEmployeeEmailError{
    width: 400px;
    input{
      width: 395px;
      height: 50px;
      border-color: #FF0505;
    }
  }


.add-employee-dropdown {
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.Dropdown-placeholder{
  display: flex;
  margin-left: -300px;
  margin-top: -47px;
  padding-left: -70px; 
  font-size: 14px;
  height: 50px; 
  width: 300px; 
  box-sizing: border-box; 
  border: none;
  text-align: left;
  margin-right: 50px;
}

  .checkbox {
    display: none;
  }

  .checkBoxContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 60px); 
    overflow-y: auto;
    // border-bottom: 1px solid #ccc;
  }
  
  .checkbox + p::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #979797;
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox:checked + p::before {
    background-color: #E52333;
    border-color: #E52333;
    content: '✔';
    color: white;
    font-size: 10px;
    text-align: center;
    line-height: 15px;
}

  .flexContainer input{
    width: 400px;
    // height: 50px;
  }

  .flexBox{
    display: flex;
    gap: 20px;
  }
  
  .placeholder-no-border {
    border: none;
  }

  .acess-flex{
    width: 813px;

    .tooltipContainer {
      position: relative;
      display: flex;
      gap: 7px;
      margin-bottom: 7px;
  }
  
  .infoToolTip{
    cursor: pointer;
    width: 20px;
  }

  .hoverImage {
    visibility: hidden;
    width: 250px; 
    background-color: #E52333;
    color: #fff;
    // text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 25%;
    left: 31%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .hoverImage::after {
    content: '';
    position: absolute;
    top: 70%; 
    left: -19px;
    transform: translateY(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent #E52333 transparent transparent;
  }
  
  .infoToolTip:hover + .hoverImage{
    visibility: visible;
    opacity: 1;
  }
  }

  .rfLoading{
    display: flex;
    justify-content: center;
  }
  
  .selectContainer{
    position: relative;
    width: 400px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc;
    // margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;

  
    img{
      width: 20px;
    }
  
    .selectOutlet{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      color: rgba(51, 51, 51, 0.8);
      font-size: 14px;
      border: 1px solid #ccc;
    }
  
    .outletDropDown{
      color: rgba(51, 51, 51, 0.8);
      width: 390px;
      position: absolute;
      background-color: white;
      border: 1px solid #ccc;
      // z-index: 999;
      border-radius: 4px;
      padding: 5px;
      margin-top: -2px;
    }
  }

  .errorCustomInput{
    position: relative;
    width: 400px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #FF0505;
    border-color: #FF0505;
    margin-bottom: 20px;
    margin-right: 10px;
    // z-index: 0;
  
    img{
      width: 20px;
    }
  
    .selectOutlet{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      color: rgba(51, 51, 51, 0.8);
      font-size: 14px;
      border: 1px solid #FF0505;
      border-color: #FF0505;

    }
  
    .outletDropDown{
      color: rgba(51, 51, 51, 0.8);
      width: 390px;
      position: absolute;
      background-color: white;
      border: 1px solid #FF0505;
      border-color: #FF0505;
      border-radius: 4px;
      padding: 5px;
      margin-top: -2px;
    }

    .Dropdown-placeholder{
      display: flex;
      margin-left: -300px;
      margin-top: -47px;
      padding-left: -70px; 
      font-size: 14px;
      height: 50px; 
      width: 200px; 
      box-sizing: border-box; 
      border: none;
      text-align: left;
      margin-right: 150px;
      color: #FF0505;
    }
  }
    
  .pinInput{
    width: 200px;
    border-radius: 100px;
  }

  .btn{
    position: sticky;
    bottom: 10px;
    margin-top: 150px;
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    // margin-left: 200px;

    .clear-all-btn{
      background-color: #fff;
      color: #E52333;
      width: 130px;
      border-color: #E52333;
      cursor: pointer;
      font-weight: 600;
    }
    .save-btn{
      background-color: #E52333;
      color: #fff;
      width: 130px;
      cursor: pointer;
      font-weight: 600;
    }

    .clear-all-btn:disabled{
      cursor: not-allowed;
    }
    .save-btn:disabled{
      cursor: not-allowed;
    }
  } 

  .modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
  }
  .modalContainer{
    border: 1px solid #ccc;
    background-color: white;
    padding: 50px;
    border-radius: 7px;
    text-align: center;
  }
  .modalBtn{
    display: flex;
    gap: 10px;
    .noBtn{
      background-color: white;
      color: #E52333;
      font-weight: 600;
      cursor: pointer;
    }
    .yesBtn{
      background-color: #E52333;
      color: white;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.menu-details .add-employee-dropdown {
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  width: 400px;
  // margin-top: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
  border: none;
  z-index: 0;
}

  /* Circular Progress Loader */
  .loaders{
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #929496;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: 55px;
    margin-top: -35px;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media(max-width:1280px){
  .menu-details{
    .btn{
      position: static;
      margin-top: 30px;
      display: flex;
      margin-bottom: 10px;
      gap: 10px;
      margin-left: -700px;
  
      .clear-all-btn{
        background-color: #fff;
        color: #E52333;
        width: 130px;
        border-color: #E52333;
        cursor: pointer;
        font-weight: 600;
      }
      .save-btn{
        background-color: #E52333;
        color: #fff;
        width: 130px;
        cursor: pointer;
        font-weight: 600;
      }
    }   
  }
}

@media(max-width:1080px){
  .menu-details{
    .btn{
      position: static;
      margin-top: 30px;
      display: flex;
      margin-bottom: 10px;
      gap: 10px;
      margin-left: -700px;
  
      .clear-all-btn{
        background-color: #fff;
        color: #E52333;
        width: 130px;
        border-color: #E52333;
        cursor: pointer;
        font-weight: 600;
      }
      .save-btn{
        background-color: #E52333;
        color: #fff;
        width: 130px;
        cursor: pointer;
        font-weight: 600;
      }
    } 
  }
}




.passwordAlertHint{
  margin-top: 4px;
  margin-bottom: 4px;

}

.passwordAlertHintUL{
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 30px !important;
  padding: 0px;
}

.employeeError{
  margin-top: -13px;
  font-size: 12px;
  color: #FF0505;
}