.role_sec {
  width: 60%;
  & .role_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid #c4c4c4;
    padding-left: 30px;

    & h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }

  & .role_list {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 16px;

    & .role_view {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;

      & h6 {
        margin: 0;
        padding-left: 30px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #000000;
      }

      & p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        text-decoration-line: underline;

        color: #0085ff;
      }
    }
  }

  & .expand_list {
    position: relative;
    padding-left: 30px;
    margin-top: 21px;

    & > div {
      display: flex;
      margin-bottom: 30px;

      & h7 {
        flex: 0 0 30%;
        width: 30%;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #000000;
      }
    }
  }
}
