* {
  font-family: "Poppins", sans-serif;
}

.Main-Primary-Page {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.Main-Primary-Page-Expanded {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.Primary-page {
  position: relative;
  top: 7vh;
  width: 120%;
  // height: 32rem;
  overflow-y: auto;
  overflow-x: hidden;
  left: 1rem;
  // border: 2px solid pink;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 3100px) {
    height: 85vh;
    // border: 1px solid violet;
    // border: 1px solid red;
   
   }
 
  @media (max-width: 2305px) {
    height: 70vh;
    // border: 3px solid pink;
   
   }
   @media (max-width:1920px) {
   
    height: 68vh;
    // border: 1px solid brown;
  }

  @media (max-width: 1710px) {
    height: 67vh;
    // border: 1px solid blue;
   
   }
   @media (max-width: 1536px) {
   height: 64vh;
    // border: 1px solid green;
   
   }
  @media (max-width: 1400px) {
    height: 23rem;
    // border: 1px solid red;
    padding-bottom: 4rem;
    
  &::-webkit-scrollbar {
    display: none;
  }
  
  }
  @media (max-width: 1300px) {
    height: 20rem;
    // border: 1px solid goldenrod;
    padding-bottom: 4rem;
    
  &::-webkit-scrollbar {
    display: none;
  }
  
  }

  .ToolKitchen {
    // transform: translateY(20px);
    // border: 1px solid darkred;
    opacity: 40%;
    border: none;
    outline: none;
    // margin-top: -1.4rem;
   
    
    // border: 1px solid red;
    // width: 10px;
    // height: 8px;
    // margin-top: -1.2rem;
    // @media (min-width: 1700px) and (max-width: 1706.67px) {
    //   // border: 1px solid darkred;
    // }
  }
  .tool-tip-portion-cont
  {
// border: 1px solid red;



  }

  .Tool-tax-class
  {
    opacity: 40%;
       border: none;
    outline: none;
    margin-top: 0.7rem;
    margin-left: -0.3rem;

  }
  
  // .Tool-tax-class {
  //   transform: translateY(22px);
  //   opacity: 40%;
  //   border: none;
  //   outline: none;

  //   @media (min-width: 1599px) and (max-width: 1600px) {
  //     transform: translate(28px, 20px);
  //     // border: 1px solid darkslategrey;
  //   }

  //   @media (min-width: 1700px) and (max-width: 1706.67px) {
  //     transform: translate(-20px, 20px);
  //     // border: 1px solid darkslategrey;
  //   }
  //   @media (min-width: 1900px) and (max-width: 1920px) {
  //     transform: translate(5vw, 20px);
  //     // border: 1px solid darkslategrey;
  //     // background-color: darkorange;
  //   }
  //   @media (min-width: 2000px) and (max-width: 2048px) {
  //     transform: translate(6vw, 20px);
  //     // border: 1px solid darkslategrey;
  //     // background-color: darkorange;
  //   }
  // }
 
  .Tool-item-code {
    // transform: translateY(8px);

    opacity: 40%;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
 position: relative;
 top: 8px;
  

    // @media (min-width: 1700px) and (max-width: 1706.67px) {
    //   transform: translate(-30px, 20px);
    //   // border: 1px solid darkgoldenrod;
    // }
    // @media (min-width: 1900px) and (max-width: 1920px) {
    //   transform: translate(90px, 20px);
    //   // border: 1px solid darkgoldenrod;
    // }
    // @media (min-width: 2000px) and (max-width: 2048px) {
    //   transform: translate(120px, 20px);
    //   // border: 1px solid darkgoldenrod;
    // }
  
     
  }
  .Primary-Page-inputfiled-and-tooltip-itemcode
  {
    display: flex;
    align-items: center;
    justify-content: left;
    // position: relative;
    // top: 1rem;

    width: 100vw;
  
  }

  .Primary-Page-inputfiled-and-tooltip {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: fit-content;

    .tool-tip-best-pair {
      margin-top: -14px;
      width: 1px;
      margin-left: -5px;
      // border: 1px solid red;
      // @media(max-width: 1280px){
      //   background-color: pink;
      //   margin-left: -15px;
      //   // width: fit-content;
      //   // height: fit-content;
      //   // margin-right: 8rem;
      //   // transform: translateX(-15px);
      // }
    }
   

    // .tool-tip-item-code {
    //   width: fit-content;
    //   margin-top: -0.3rem;
    //   margin-left: -16px;
    //   // @media (max-width: 1280px) {
    //   //   // background-color: pink;
    //   //   // margin-left: -50px;
    //   //   // margin-right: 8rem;
    //   //   transform: translateX(-35px);
    //   // }
    //   // @media (min-width: 1281px) and (max-width: 1440px) {
    //   //   // background-color: pink;
    //   //   margin-left: -1.7rem;
    //   // }
    //   // @media (min-width: 1441px) and (max-width: 1536px) {
    //   //   // background-color: pink;
    //   //   margin-left: -2.3rem;
    //   // }
    //   @media (max-width: 2500px) {
       
    //     // border: 1px solid violet;
    //     margin-left: 6rem;
       
    //    }
    //    @media (max-width:2000px) {
       
      
    //     // border: 1px solid brown;
    //     margin-left: 4.5rem;
    //   }
    
    //   @media (max-width: 1800px) {
       
    //     // border: 1px solid blue;
    //     margin-left: 2rem;
       
    //    }
    //    @media (max-width: 1600px) {
      
    //     // border: 1px solid green;
    //     margin-left: 0rem;
       
    //    }
    //   @media (max-width: 1525px) {
       
    //     // border: 1px solid red;
    //     margin-left: -2rem;
   
     
      
    //   }
    // }

    .tool-tip-portion-size {
      position: relative;
      // background-color: orange;
      left: -2rem;
      width: 1px;
    }

  }

  .Primary-page-container-one {
    display: flex;
    flex-direction: row;
    margin-left: 20px;

    .Primary-page-container-pairone {
      width: 28vw;
      height: fit-content;
      // border: 1px solid red;
    }

    .Primary-page-InputFields {
      height: 6rem;
      width: fit-content;

      .KitchenRelated {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .alcoholradiobutton {
      display: flex;
      flex-direction: column;
      position: relative;
      top: -2%;
      gap: 0.5rem;
      height: 4rem;
      h3 {
        font-family: "Poppins", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: #000000;
      }
    }
    .barcode {
      height: 6rem;
      width: fit-content;
      margin-top: 20px;
    }
    .Primary-Page-description-field {
      position: relative;
      .description {
        width: 20vw;
        height: 8rem;
        resize: none;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 0.313rem;
        padding: 0.5rem;
        color: black;

        &:focus {
          outline: none;
        }
      }
      .Primary-page-description-charcount {
        position: absolute;
        right: 27.5%;
        margin-top: -7%;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: right;
      }
    }
    .Primary-Page-Foodimages {
      margin-top: 1.3rem;
      height: 11rem;
      width: fit-content;
      margin-bottom: 1rem;
     

      .image-container {
        position: relative;
        width: 100px;
        height: 100px;


       

        .imcrossstyres {
          position: absolute;
          top: 18px;
          left: 69px;
          width: 20px;
          height: 20px;
          border-radius: 50px;
          border: none;
          background-color: #f23d3d;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .uploaded-image {
          position: relative;
          top: 0.063rem;
          width: 5.5rem;
          height: 5.5rem;
          border-radius: 3.125rem;
          padding: 0.313rem;
          position: relative;
          top: 1rem;
        }
      }
      
      .addingimg {
        width: 5rem;
        height: 5rem;
        border-radius: 3.125rem;
        padding: 0.313rem;
        position: relative;
        top: 1rem;
        // border: 1px solid red;
      }

      .imagealignment {
        display: flex;
        flex-direction: row;
        // background-color: red;
        width: fit-content;
        @media (max-width: 1525px) {
          position: relative;
          top: -1.4rem;
          
              // border: 1px solid red;
              
           
            
            }
      }

      h3 {
        font-family: "Poppins", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;

        color: #000000;
      }
      p {
        font-size: 0.75rem;
        font-weight: 300;
        color: #979797;
        position: relative;
        top: 0.0623rem;
      }

      .imgfile {
        display: none;
      }
    }

    .PopularItem {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -1.2%;
      input {
        width: 20px;
        height: 20px;
        accent-color: #E52333;
      }
      span {
        font-size: 16px;
      }
    }

    .Primary-Page-categories-field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .Primary-page-Allergens-selection {
      width: 43vw;
      height: 38vh;
      border-radius: 0.313rem;
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin-top: 3%;
    }
    .tool-tip-Allergen {
      margin-left: 96.2%;
      margin-top: -6.8%;
    }

    .Primary-page-container-pairtwo {
      width: 45vw;
      height: 90vh;
      // margin-left: 2rem;
      // border: 1px solid red;
    }
  }

  .Primary-page-container-two-heightdynamic {
    margin-left: 20px;
    height: 80vh;
    margin-top: -8.5%;
  }
  .Primary-page-container-two {
    margin-left: 20px;
    height: 80vh;
    // margin-top: -11.5%;
    // background-color: yellow;
    width: fit-content;
  }
  .Primary-page-ingredients-selection {
    width: 45vw;
    height: 43vh;
    border-radius: 0.313rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    // background-color: pink;
    margin-top: 2rem;
    @media (max-width: 3100px) {
      margin-top: -20rem;
      // border: 1px solid red;
     
     }
   
    @media (max-width: 2305px) {
      margin-top: -6rem;
      // border: 1px solid blue;
     
     }
     @media (max-width: 2048px) {
      margin-top: 2rem;
      // border: 1px solid blueviolet; 
     
     }
     @media (max-width:1920px) {
     
      margin-top: 2rem;
      // border: 1px solid green;
    }
  
    @media (max-width: 1710px) {
      margin-top: 2rem;
      // border: 1px solid black;
     
     }
     @media (max-width: 1536px) {
      margin-top: 2rem;
     
     }
    @media (max-width: 1400px) {
      margin-top: 2rem;
      
    &::-webkit-scrollbar {
      display: none;
    }
    
    }
    @media (max-width: 1300px) {
      margin-top: 2rem;
      
    &::-webkit-scrollbar {
      display: none;
    }
    
    }
    
  }
  .Primary-page-ingredients-selection1 {
    width: 45vw;
    height: 40vh;
    border-radius: 0.313rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    // background-color: pink;
    margin-top: 5rem;
    @media (max-width: 1525px) {
      position: relative;
      top: 1rem;
      
          // border: 1px solid red;
          
       
        
        }
  }
  .Primary-Page-Other-Details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    top: 3%;
    @media (max-width: 2305px) {
      position: relative;
      top: 3rem;
      // border: 1px solid blue;
       
       }
    @media (max-width: 2048px) {
      position: relative;
      top: 3rem;
      // border: 1px solid red;
       
       }
       @media (max-width: 1920px) {
        position: relative;
        top: 3rem;
        // border: 1px solid green;
         
         }
       @media (max-width: 1770px) {
       position: relative;
       top: 1rem;
        // border: 1px solid black;
       
       }
    //   @media (max-width: 1600px) {
    //     position: relative;
    //     top: 2rem;
    //     // border: 1px solid blue;
       
    //    }
    // @media (max-width: 1525px) {
    //   position: relative;
    //   top: -0.5rem;
    //   padding-bottom: -15rem;
    //       // border: 1px solid red;
          
       
        
    //     }
        @media (max-width: 1536px) {
          position: relative;
          top: 2rem;
          
          }
         @media (max-width: 1400px) {
          position: relative;
          top: 2rem;
           
       
        }
    .Primary-Page-Other-Details-heading {
      font-family: "Poppins", sans-serif;
      font-size: 0.875rem;
      font-weight: 400;

      color: #000000;
    }
    .Primary-Page-Other-Detail {
      display: flex;
      flex-direction: row;
      height: fit-content;
      align-items: center;
      // justify-content: center;
      gap: 30px;
      // background-color: red;
      // background-color: lightcoral;
      width: 100%;
      @media (min-width: 1700px) and (max-width: 1706.67px) {
        // border: 1px solid darkblue;
        gap: 3vw;
      }
      @media (min-width: 1900px) and (max-width: 1920px) {
        // border: 1px solid black;
        gap: 5vw;
      }
      @media (min-width: 2000px) and (max-width: 2048px) {
        // border: 1px solid red;
        gap: 5vw;
      }
      .Caloriepointradio {
        // position: relative;
        // left: 1%;
        // // border: 1px solid black;
        // //hehe
        // @media (min-width: 1700px) and (max-width: 1706.67px) {
        //   // border: 1px solid darkorchid;
        // }
      }
     
    }
  }

  .Primary-Page-Formsubmitbutton {
    margin-top: 2%;
  }

  // .portionSize-Radio {
  //   position: relative;
  //   left: 0.8rem;
  //   top: 0.7rem;
  // }
  .buttonContainer {
    width: 100%;
    position: absolute;
    left: -17px;
  }
  .buttonContainerExpanded {
    // width: 100%;
    // position: fixed;
    // left: 0px;
  }
}

// @media (min-width: 1462px) and (max-width: 1661px) {
//   .tool-tip-item-code {
//     margin-left: -20px;
//     transform: translateX(30px);
//   }

//   // .tool-tip-tax-class {
//   //   transform: translateX(50px);
//   // }
// }

// @media (min-width: 1662px) and (max-width: 1826px) {
//   .tool-tip-item-code {
//     margin-left: -20px;
//     transform: translateX(80px);
//   }

//   .tool-tip-tax-class {
//     transform: translateX(80px);
//   }
// }


.tax-with-tooltip
{
  display: flex;
  align-items: center;
  // gap: 0.1rem;
}
.Primary-Page-inputfiled-and-tooltip-taxclass
{
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  .tool-tip-tax-class
  {
    // margin-top: -1.8rem;
    // margin-left: -8rem;
    width: 20px;
    height: 20px;
    // border: 1px solid red;

    // @media (max-width: 3100px) {
   
    //   border: 1px solid red;
    //   margin-left: 0.1rem;
     
    //  }
    //  @media (max-width: 2305px) {
      
    //   border: 1px solid blue;
    //   margin-left: 0.1rem;
     
    //  }
    //  @media (max-width: 2048px) {
   
    //   border: 1px solid blueviolet; 
    //   margin-left: -8rem;
     
    //  }
    //  @media (max-width:1920px) {
     
      
    //   border: 1px solid green;
    //   margin-left: -6rem;
    // }
  
    // @media (max-width: 1710px) {
   
    //   border: 1px solid black;
    //   margin-left: -6rem;
     
    //  }
    //  @media (max-width: 1536px) {
    
    //   border: 1px solid brown;
    //   margin-left: -6rem;
    //  }
    // @media (max-width: 1400px) {
     
      
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    
    // }
    // @media (max-width: 1300px) {
    //   margin-top: 2rem;
      
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    
    // }

  // @media (max-width:2305px) {
  //     margin-left: -6.5rem;
  //      border: 1px solid red;
      
  //   }
  //   @media (max-width:2048px) {
  //     margin-left: -6.5rem;
  //      border: 1px solid red;
      
  //   }
  //   @media (max-width: 1800px) {
  //     margin-left: 0.6rem;
     
  //    }
  //    @media (max-width: 1600px) {
  //   margin-left: -0.5rem;
      
  //     }
  }
 
}

.Primary-Page-inputfiled-and-tooltip-portionsize
{
  display: flex;
  width: 90vw;
// gap: 6rem;
align-items: center;
  // border: 1px solid red;
}

.Primary-Page-inputfiled-and-tooltip-portion {
  display: flex;
  flex-direction: row;
  // gap: 10px;
  // border: 1px solid blue;
  align-items: center;
  margin-left: -0.1rem;
  width: fit-content;
  // margin-left: 8rem;
  // margin-top: 0.3rem !important;
  .tool-tip-portion-cont {
    // margin-left: -0.3rem;
    width: 30px;
    height: 20px;
    margin-top: -2.8rem;
  }
}

.Primary-Page-Other-Detail-colorie
{
  width: 90vw;
  display:flex ;
  // border: 1px solid red;
}
.Caloriepointradio
{
  margin-top: 0.6rem;
}