:root {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #404d66;
  --cchart-bg: #ffffff; /* Default light theme color */
  --cchart-border: #000000;
}

.p-dark-theme {
  --navbar-bg: #222b3c;
  --navbar-text: #6e7d7d;
  --table-bg: #222b3c;
  --table-text: #ffffff;
  --chart-bg: #222b3c;
  --screen-bg: #2e3a4f;
  --cchart-bg: #222b3c;
  --cchart-border: #ffffff;
}

.p-light-theme {
  --navbar-bg: #e0e0e0;
  --navbar-text: #333333;
  --table-bg: #ffffff;
  --table-text: #333333;
  --chart-bg: #ffffff;
  --screen-bg: #ffffff;
  --cchart-bg: #ffffff;
  --cchart-border: #000000;
}

.p-expanded-width-sales {
  width: 95% !important;
  // margin-left: -1.5rem;
}

.p-product-insights-container {
  background-color: var(--screen-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
  // width: 91vw !important;
  // margin-left: -1.5rem;
  padding-bottom: 5vh;

  @media (max-width: 500px) {
    width: 100vw !important;
    margin-left: 0;
  }

  .p-prod-insights-head {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    width: 95%;

    .p-name-board {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--table-text);

      h1 {
        font-size: 21px;
        font-weight: 500;
      }
    }

    .p-dates {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      height: 6rem;

      .p-label-time-period {
        font-weight: 600;
        color: var(--table-text);
        @media (max-width:700px) {
          display: none;
        }
      }

      .p-filter-toggle-btn-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.6rem;

        .p-filter-toggle-btn {
          text-align: center;
          padding: 0.5rem;
          width: 10rem;
          height: 2rem;
          color: #000;
          background-color: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #000;
          border-radius: 0.8rem;
          cursor: pointer;
          @media (max-width: 800px) {
            width: 18vw;
            height: 1rem;
            font-size: smaller;
          }
          @media (max-width: 500px) {
            width: 20vw;
            font-size: xx-small;
          }
        }

        .p-filter-drop-down-options {
          position: absolute;
          top: 110%;
          left: 50%;
          transform: translateX(-50%);
          width: 12rem;
          padding: 0.5rem;
          background-color: var(--navbar-bg);
          color: var(--navbar-text);
          border: 1px solid #ccc;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          z-index: 999;
          border-radius: 1rem;
          @media (max-width: 800px) {
            width: 18vw;
            font-size: x-small;
          }
          @media (max-width: 500px) {
            width: 20vw;
            font-size: xx-small;
          }

          p {
            margin: 0;
            padding: 0.5rem;
            cursor: pointer;
            border-radius: 0.6rem;
            text-align: center;
            &:hover {
              background-color: var(--navbar-text);
              color: var(--navbar-bg);
            }
          }
        }
      }
    }
  }

  .p-date-range-style {
    display: flex;
    height: 60px;
    right: 1rem;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    width: 100%;

    .p-dateLabel {
      align-items: flex-start;
      margin-right: 0.5rem;
      color: var(--table-text);
    }

    .p-start-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
      margin-right: 1rem;
    }

    .p-end-date {
      height: 2rem;
      width: 9rem;
      padding: 0.5rem;
      border-radius: 0.6rem;
      border-style: thin;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }
  }

  .p-location-name {
    h1 {
      color: var(--table-text);
      text-align: center;
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  .p-top-twenty-popular-items {
    border-radius: 1rem;
    width: 69%;
  }

  .p-least-twenty-popular-items {
    border-radius: 1rem;
    width: 69%;
  }

  .p-top-ten-voided-items {
    border-radius: 1rem;
    width: 69%;
  }

  .p-top-cancelled-items {
    border-radius: 1rem;
    width: 69%;
  }

  .p-product-summary-dropdown-cont {
    width: 60vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 800px) {
      width: 58vw;
    }
    @media (max-width: 412px) {
      width: 74vw;
    }

    p {
      font-weight: 600;
      color: var(--table-text);
      @media (max-width: 800px) {
        font-size: small;
      }
    }

    .p-filter-toggle-btn-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.6rem;

      .p-filter-toggle-btn {
        text-align: center;
        padding: 0.5rem;
        color: #000;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        border-radius: 0.8rem;
        cursor: pointer;
        width: 10rem;
        height: 2rem;
        @media (max-width: 800px) {
          width: 5rem;
          height: 1rem;
          font-size: xx-small;
        }
        @media(max-width: 500px){
          width: 6rem;
          height: 1rem;
          font-size: xx-small;
        }
      }

      .p-filter-drop-down-options {
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        width: 12rem;
        padding: 0.5rem;
        background-color: var(--navbar-bg);
        color: var(--navbar-text);
        border: 1px solid #ccc;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 999;
        border-radius: 1rem;
        @media (max-width: 800px) {
          width: 5rem;
        }

        p {
          margin: 0;
          padding: 0.5rem;
          cursor: pointer;
          border-radius: 0.6rem;
          text-align: center;
          &:hover {
            background-color: var(--navbar-text);
            color: var(--navbar-bg);
          }
          @media (max-width: 800px) {
            font-size: xx-small;
          }
        }
      }
    }
  }
}
