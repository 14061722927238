.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 0;
  z-index: 1;
  border: none;
  outline: none;
  // width: 5px;
  // background-color: yellow;
}

.tooltip-message {
  visibility: hidden;
  background-color: white; /* Tooltip background color */
  color: #E52333;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  border: 1px solid #E52333;
  padding: 5px;
  position: absolute;
  z-index: 2;
  top: -10px;
  left: 280%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tooltip-container:hover .tooltip-message {
  visibility: visible;
  opacity: 1;
}
.Text {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  border: none;
  outline: none;
}

.icon-background {
  // background-color: white;
  border-radius: 50%;
  padding: 5px;
  display: inline-flex;
  position: relative;
  top: 10px;
  left: -15px;
  border: none;
  outline: none;

  // border: 1px solid black;

  img {
    opacity: 70%;
  }
}

.icon-backgrounddel {
  // background-color: white;
  border-radius: 50%;
  padding: 5px;
  display: inline-flex;
  position: relative;
  top: 1px;
  left: -15px;
  border: none;
  outline: none;
}
