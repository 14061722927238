.menuV2-container{
  width: 100%;
  height: 100%;
  display: flex;

  .v2-menu{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }

  .v2-menuList-header{
    width: 94%;
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 30px;
    // background-color: palegreen;

    .menuRight{
      display: flex;
      gap: 100px;
    }

    p{
      font-size: 14px;
      color: #8D8D8D;
    }
  }

  .v2-menuContainer {
    max-height: 80vh; 
    overflow-y: auto;
    // background-color: powderblue;

    @media(max-width: 1920px){
      max-height: 75vh;
    }
  }

  .v2-itemHeader{
    width: 96%;
    height: 44px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: #F4F4F4;
    margin-right: 0px;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1 !important;
    cursor: pointer;

    p{
      display: flex;
      align-items: center;
      padding-left: 10px;
    }

    img{
      margin-right: 15px;
    }
  }

  .v2-item-container {
    max-height: 0;
    opacity: 0;
    // overflow: hidden;
  }
  
  .v2-item-container.open {
    max-height: 100%;
    opacity: 1;
    transform: translateY(0);
    position: relative;
    z-index: 9 !important
  }
  

  .v2-itemData{
    width: 96%;
    height: 44px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 0px;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #525252;
    border-bottom: 1px solid #F4F4F4;
    cursor: pointer;
    position: relative;
    z-index: 0 !important;
    // background-color: plum;

    .v2-itemName{
      width: fit-content;
    }

    .itemRight{
      display: flex;
      gap: 160px;
      position: relative;
      justify-content: center;
      align-items: center;
      // background-color: pink;

      img{
        width: fit-content;
        display: flex;
        justify-content: center;
        // background-color: orange;
      }
    }

    p{
      padding-left: 10px;
    }

    .v2-item-price{
      display: flex;
      text-align: left;
      width: 23px;
      margin-right: 50px;
      font-size: 14px;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .v2-sub-item{
    width: 95.29%;
    height: 44px;
    background-color: #FCFCFC;
    align-items: center;
    display: flex;
    margin-right: 0px;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #6F6F6F;
    border-bottom: 1px solid #F5F5F5;
    cursor: pointer;
    gap: 20px;
    padding-left: 10px;

    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #6F6F6F;
    }

    img{
      margin-right: 40px;
    }
  }

  .subCategory-arrow {
    transition: transform 0.3s ease; 
    position: relative;
    z-index: 99 !important;
  }
  
  .subCategory-arrow.open {
    transform: rotate(180deg);
    position: relative;
    z-index: 99 !important;
  }

  .loader-conatainer{
    width: 100%;
    height: 75vh;
    
    .v2-imgLoader2{
      height: 100px;
      width: 100px;
      position: relative;
      top: 35%;
      left: 45%;
    }
  }

  .no-results-found{
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    justify-self: center;

    img{
      margin: 0;
      padding: 0;
    }

    p{
      margin: 0;
      padding: 0;
      color: #E52333;
      margin-top: -60px;
      font-size: 17px;
      font-weight: 500;
    }
  }
}