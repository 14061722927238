.header {
  // padding-left: 4.5%;
  padding-bottom: 1.5vw;
  font-size: 1.5rem;
  color: #67833e;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.svgContainer {
  //   margin-top: 5%;
  display: flex;
  justify-content: center;
}

.addItem-container {
  display: flex;
  flex-direction: column;
  // padding-left: 3%;
  // padding-right: 3%;
  padding-top: 3%;
  // padding-bottom: 50px;
  width: 80%;
}

.button {
  // background: #67833e;
  color: #fff;
  // padding: 10px 30px 10px 30px;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 500;
}

.submit-button {
  // background: #67833e;
  color: #fff;
  // padding: 10px 30px 10px 30px;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 500;
  background: #67833e;
  align-items: center;
  display: flex;
  justify-content: center;
}

.bottomContainer {
  margin-top: 5%;
}

.buttonContainer {
  margin-top: 10px;
  justify-content: center;
  display: flex;
  padding-right: 5%; // this is for show button and SVG inline
}

.secondaryText {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 10px;
  padding-right: 5%; // this is for show button and SVG inline
}

.portion-one {
  width: 30%;
  flex: 0 0 30%;
  margin-right: 10%;
}

.portion-two {
  width: 30%;
  flex: 0 0 30%;
}

.content-container {
  // padding-left: 4.5%;
  width: 100%;
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
}

.title-container {
  margin-bottom: 1rem;
}

// .title {                     //conflicting employee details page
//   font-weight: 500;
//   font-size: 1.125rem;
//   position: relative;
//   // margin-left: 101rem;
// }

.other-text {
  margin-bottom: 0.725rem;
  font-weight: 300;
  font-size: 1.125rem;
}
.discription {
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 5px;
  // color: #979797;
  padding: 5px 12px;
  margin-bottom: 15px;
  resize: none;
  width: 100%;
  font-family: "Poppins", sans-serif;

  &::placeholder {
    color: #979797;
    font-family: "Poppins", sans-serif;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.imageText {
  font-weight: normal;
  font-size: 12px;
  font-style: italic;
  margin-left: 1rem;
}

.ingredients-container {
  border: 1px solid rgba(151, 151, 151, 0.5);
  border-radius: 5px;
  padding: 0.725rem;
  // overflow: scroll;
  max-height: 400px;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.search-ingredients {
  margin-top: 0.5rem;
}

.ingredient-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  width: 30px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  // justify-content: space-between;
  // width: 100%;
  // flex-wrap: nowrap;
}

.ingredientName {
  font-weight: 300;
  font-size: 10px;
  margin-top: 3px;
}

.ingredient-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-between;
}

.availability-item-container {
  // display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.selected-ingredient {
  text-align: center;
  align-items: center;
  display: flex;
  margin: 5px;
  align-content: center;
  justify-content: space-between;
  border: 1px solid rgba(151, 151, 151, 0.5);
  padding: 0.3rem;
  border-radius: 5px;
}
.selected-ingredient-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.selected-ingredient-text {
  font-size: 0.725rem;
  font-weight: 300;
  margin-left: 0.275rem;
  margin-right: 0.275rem;
}

.footer {
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.25);
  // width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  padding-top: 60px;
  padding-bottom: 40px;
  bottom: 0px;
  position: absolute;
  width: 75.5%;
}

.plain-button {
  background: #fff;
  color: #67833e;
  margin-right: 40px;
  padding: 10px 0px 10px 0px;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 500;
  border: 1px solid #67833e;
}

.button-text {
  font-size: 1.125rem;
  width: 200px;
  text-align: center;
}

.innerContainer {
  padding-left: 4.5%;
}

.outlet-container {
  flex: 0 0 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outlet-title {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  display: flex;
  margin-top: 1rem;
}

.kitchen-container {
  display: flex;
  // justify-content: space-between;
  width: 80%;
  margin-top: 1rem;
  // flex: 1;
  // margin-right: 10%;
}

.dropdown-container {
  flex: 0 0 30%;
  // width: 100%;
}

.preparation-dropdown-container {
  flex: 0 0 30%;
  margin-left: 4rem;
}

.dineIn-container {
  display: flex;
  flex-direction: column;
  // padding-left: 4.5%;
  margin-left: 1rem;
  width: 100%;
}

.available-stream-container {
  display: flex;
  // flex-direction: column;
}

.dineIn-switch-container {
  margin-top: 4px;
}

.customisation-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: baseline;
}

.add-text {
  color: #0085ff;
  cursor: pointer;
  width: 110px;
}

.add-text-margin {
  margin-left: 1rem;
}

.tab1-active {
  background-color: #67833e;
}

.tab1-disabled {
  background-color: #67833e;
  opacity: 0.5;
}

.tab2-active {
  background-color: #67833e;
}

.tab2-disabled {
  background-color: #d1d1d1;
}

.tab-panel-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;
  // justify-content: space-between;
}

.tab-panel-container1 {
  width: 45%;
}

.tab-panel {
  height: 9px;
  border-radius: 10px;
}

.tab-panel-container2 {
  width: 45%;
  margin-left: 1rem;
}

.tab1-panel-text {
  font-size: 1.125rem;
  font-family: Poppins;
}

.tab1-panel-text-active {
  color: #67833e;
  opacity: 0.5;
}

.tab2-panel-text {
  font-size: 1.125rem;
  font-family: Poppins;
  color: #d1d1d1;
}

.action-container {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-item-text {
  font-size: 1rem;
  font-family: Poppins;
}

.action-text {
  font-size: 1rem;
  font-family: Poppins;
  color: #67833e;
  margin-left: 0.225rem;
}

.action-icon-container {
  display: flex;
  align-items: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  border-spacing: 15px;
}

.table tr {
  // background-color: #f8f8f8;
  // border: 1px solid #ddd;
  // padding: 0.35em;
}

.table th,
.table td {
  // padding: 0.625em;
  text-align: center;
}

.sub-category {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 12px;
}

.sub-category-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #c4c4c4;
  padding-bottom: 5px;
}

.status-value-container {
  display: flex;
}

.sub-category-inner-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  // margin-top: 10px;
}

.status-text {
  font-size: 10px;
  color: #8a8a8a;
  font-family: Poppins;
  margin-top: 5px;
  margin-bottom: 10px;
}

.status-value-inner-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.status-icon-container {
  // width: 0%;
  display: flex;
  // justify-content: space-around;
}

.empty {
  border-bottom: solid 1px #c4c4c4;
  padding-bottom: 5px;
}

//Model CSS

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup_inner {
  position: absolute;
  // left: 42%;
  // right: 42%;
  // top: 22%;
  // bottom: 20%;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.modal-heading-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #67833e;
}

.modal-title-border {
  border: solid 1px #e1e1e1;
}

.modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-item-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.modal-item-category {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  color: #808080;
}

.modal-checkbox {
  height: 16px;
  width: 13px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 1rem;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.modal-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  // background-color: #eee;
  border: 1px solid #67833e;
  border-radius: 2px;
}

// .checkbox-container:hover input ~ .check {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .check {
  background-color: #6fbd0b;
  border: 1px solid #6fbd0b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .check:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .check:after {
  left: 5px;
  top: -1px;
  width: 5px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #686868;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.switch-text {
  font-family: Poppins;
  margin-left: 0.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #686868;
}

.radio-container {
  margin: 1rem 0rem !important;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  // align-items: center;
}

.radio {
  height: 20px;
  width: 20px;
}

.radio-outer-container {
  margin-top: 1rem;
}

.bottom-button-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.modal-cancel-button {
  width: 47%;
  border: 0.5px solid #929292;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  color: #6c6c6c;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.modal-submit-button {
  width: 47%;
  border: 0.5px solid #929292;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  text-align: center;
  background: #67833e;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.addItem-text {
  font-size: 16px;
  color: #fff;
}

.addItem-button-container {
  display: flex;
  background-color: rgb(103, 131, 62);
  align-items: center;
  padding: 0px 10px;
  margin-left: 40px;
  border-radius: 4px;
  cursor: pointer;
}

input:focus {
  outline: none;
}

.search-box-container {
  width: 40%;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border: 1px solid #c4c4c4;
  display: flex;
  // opacity: 0.3;
  font-size: 14px;
  border-radius: 2px;
}

.ingr-search-box-container {
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #c4c4c4;
  display: flex;
  // opacity: 0.3;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
}

.search-input {
  border: 0px;
  margin-bottom: 0px;
  padding: 0px;
  font-size: 14px;
}

.header-right-container {
  display: flex;
  justify-content: flex-end;
  width: 70%;
  padding: 30px;
}

.plus-icon {
  margin-right: 5px;
}

.editIcon {
  padding: 0px 0px 0px 10px;
}

.addItem-footer {
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.25);
  // width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  padding-top: 3%;
  padding-bottom: 3%;
  bottom: 0px;
  margin-top: 5%;
  // position: absolute;
  // width: 75.5%;
  
}

.selectedImage-cross {
  margin-top: -70px;
  margin-left: 10px;
  border: 1px solid #000;
  padding: 2px;
  border-radius: 25px;
}

td {
  padding: 20px 0px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
  padding-left: 10px;
  & svg {
    cursor: pointer;
  }

  & ul {
    position: absolute;
    width: 120px;
    top: 24px;
    background: #fff;
    padding: 0;
    list-style-type: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    z-index: 9999;
    box-sizing: border-box;
    right: 0;

    & li {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      cursor: pointer;
      color: #000000;
      margin: 5px;
      display: flex;
      // justify-content: space-around;
      // margin-bottom: 17px;
    }
  }
}

.availabilityContainer {
  display: flex;
  flex-direction: row;
  // align-items: center;
  margin-top: 0.5rem;
}

.availabilityDataContainer {
  margin-left: 0.5rem;
}

.promotional-msg-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #cecece;
  margin: 0px;
}

.availabilityText {
  font-family: Poppins;
  font-size: 14px;
  margin-left: 1rem;
  color: #979797;
}

.checkbox-squire {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-squire input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-squire {
  position: absolute;
  // top: 13px;
  left: 0;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  border: 1px solid #afafaf;

  // border-radius: 50%;
  // background-color: #afafaf;
}

/* On mouse-over, add a grey background color */
.checkbox-squire:hover input ~ .checkmark-squire {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-squire input:checked ~ .checkmark-squire {
  background-color: #00b71d;
  border: 1px solid #fff;
}

.checkmark-squire::before {
  content: "";
  position: absolute;
  display: block;
}

// .checkbox-squire .checkmark-squire::before {
//   left: 7px;
//   top: 3px;
//   width: 4px;
//   height: 10px;
//   border: solid white;
//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-squire:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-squire input:checked ~ .checkmark-squire:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-squire .checkmark-squire:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
