// {--------------------------Step 3 Item Customizations Heading and Edit Button ----------------------}
.step3-Review-Container {
  position: absolute;
  left: 39vw;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 1.7rem;
  // margin-left: 100px;
  // background-color: lightseagreen;
  // @media(max-width: 1080px){
  //   // background-color: lightseagreen;
  //   top: 119vh;
  //   left: 535px;
  // }
  // @media (min-width: 1081px) and (max-width: 1440px){
  //   top: 120vh;
  // }
  // @media(min-width: 1700px) and (max-width: 1706.67px){
  //   // background-color: lightslategray;
  //   top: 85vh;
  // }
  // @media(min-width: 1900px) and (max-width: 1920px){
  //   // background-color: lightslategray;
  //   top: 76vh;
  // }
  // @media(min-width: 2000px) and (max-width: 2048px){
  //       // background-color: lightslategray;
  //       top: 71.8vh;
  // }
}

.step3-Review-Container-heading-and-button-container {
  width: 28.5vw;

  display: flex;
  justify-content: space-between;
  gap: 22px;
  margin-bottom: -1.8rem;
  height: 2rem;
  position: relative;
  // border: 1px solid red;
}

.step3-Review-Container-heading-and-button-container1{
  width: 30vw;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 22px;
  margin-bottom: -1.8rem;
  //  border: 1px solid red;
}

.step3-Review-Container-heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  position: absolute;
}
.step3-Review-img-edit-Container {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  position: absolute;
  left: 36vw;
  top: 1vh;



}

.step3-Review-Container-heading-EditImage {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 5px;
  right: 8vw;
}
.Edit-heading {
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #E52333;
  text-decoration: none;
  cursor: pointer;
  margin-left: -0.05rem;
  // margin-top: -0.7vh;
  // ma
  // position: relative;
  // right: 8vw;
}

// {--------------------------Step 3 Modifier Section Name   ----------------------}

.step3-Review-modifier-section {
  width: 600px;
  height: auto;
  margin-top: 50px;
}

.item-customization:nth-child(n + 3) {
  margin-top: -160px; // Apply specific style to the second element
}

.step3-Review-modifier-section-heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
}

.step3-Review-modifier-name-container {
  width: 588px;
  // border: 2px solid yellow;
  height: auto;
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.step3-Review-modifier-name-heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

// {--------------------------Step 3 Modifier Section Menu   ----------------------}

.Step-3-Modifier-Section-Menu-container {
  width: fit-content;
  height: 200px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 0px;
  margin-left: 1.5rem;
  // background-color: lightskyblue;
}
.Step-3-Modifier-Section-Each-Menu-container-item
{


}
.Step-3-Modifier-Section-Each-Menu-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // background-color: lightseagreen;
  width: 6rem;
  height: fit-content;
}

.Step-3-Modifier-Section-Menu-heading {
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #6e6e6e;
  // background-color: lightcoral;
  width: fit-content;
}

.Step-3-Modifier-Section-Menu-details {
  font-family: "Poppins",sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;

  // margin-left: 5px;
  // background-color: pink;
  width: fit-content;
}
.Step-3-Modifier-Section-Menu-details-items {
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  display: flex;
width: 6rem;
height: auto;
// border: 1px solid red;
  // margin-left: 3x;
  // margin-top: -2px;
  margin-top: 2%;
  padding: 0.3rem;
  padding-left: 0;
}

.Step-3-Modifier-Section-Menu-details-price {
 
font-family: "Poppins",sans-serif;
font-weight: 400;
font-size: 14px;
line-height: 21px;
position: relative;
display: flex;
width: 6rem;
height: auto;
// border: 1px solid red;
// margin-left: 3x;
// margin-top: -2px;
margin-top: 2%;
padding: 0.3rem;
padding-left: 0;
}

/* {-----------------------------Free Modification Section---------------------------------- } */

.Free-Modification-Section {
  // width: 200px;
  width: fit-content;
  height: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -142px;
  left: 24px;
  // background-color: lightsalmon;
}

.Step-3-free-details {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  // margin-left: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: start;
  gap: 20px;
  // background-color: lightgreen;
  width: fit-content;
}

/* {-----------------------------Stream  Modification Section---------------------------------- } */

.Step-3-Review-Stream-Modification-heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  margin-top: 10px;
}

.Step-3-Review-Stream-Modification-container {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.Nodata {
  margin-top: 5%;
}
