.pendingmain{
    width: 332px;
    height: 199px;
    border-radius: 8px;
    box-shadow:  0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: white;
    position: relative;
    top:80px;
    left:20px;
 margin-top: 20px;
 
}
.pendingmain-Container{
    display: flex;
    justify-content: flex-start;
    gap:20px
}
 
.pendingimg{
    width: 100%;
    height: 100px;
   object-fit: cover;
}
 
.pendingcontent{
    width: 523px;
    height: 105px;
 
    display: flex;
    flex-direction: column;
    margin-left: 5px;
 
}
.pendingcontent1{
 
    display: flex;
    justify-content: start;
    gap: 120px;
        margin-bottom: 9px;
}
.pendingheading{

    width: 180px;
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 4px;
 
   
 
   
 
}
 
.pendingcontent2{
 
    display: flex;
    justify-content: start;
    gap: 10px;
}
 
.btnpend{
    width: 65px;
    height:  27px;
    border-radius: 4px;
    padding: 10px, 14px, 10px, 14px;
    gap: 10px;
    background-color: #67833E;
    font-family: poppins;
    color: white;
    right: 20px;
    font-size: 16px;
    line-height: 24px;
    border: none;
    position: relative;
    top: -2px;
    left:-100px;
    cursor: pointer;
}
 
.pendingtoggle{
    width:  332px;
    height:  49px;
    margin-left: 10px;
    display: flex;
    justify-content: start;
    gap: 25px;
}
 
.DineinToggle{
    display: flex;
    flex-direction:column ;
}
 
.labelpending{
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
}
 
.completedheading{
    color: #00B71D;
    position: relative;
    top:-5px;
    left: -10px;
    font-family: Poppins;
    font-weight:500 ;
    font-size: 10px;
    line-height: 21px;
}
.LastUpdate{
font-size: 10px;
}
.dashboardPending {
    display: flex;
    flex-direction: row;
  
    .dashBoardPendingContainer {
      position: relative;
      width: 100vw;
    }
}
.AddOutletPending{
    margin-top: 30px;
}