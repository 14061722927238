.ItemCustomization-container-level-one{
  width: 90%;
  // background-color: plum;
}

.ItemCustomization-container-level-one-expanded{
  width: 80%;
  @media(max-width: 1280px){
    // background-color: lightcoral;
    width: 80%;
  }
}

.mainItemCustomizations {
  position: relative;
  top: 100px;
  width: 100%;
  height: 600px;
  // overflow-y: auto;
  @media(max-width: 1280px){
    width: 90%;
    // background-color: pink;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.mainItemCustomizations-Expanded {
  position: relative;
  top: 100px;
  width: 100%;
  height: 600px;
  // overflow-y: auto;
  @media(max-width: 1280px){
    margin-left: 10px;
    width: 80%;
    // background-color: pink;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.itemcustomizationpage {
  position: relative;
  // left: 20px;
  margin-left: 0px;
  width: 90%;
  height: 565px;
  // background-color: lightsalmon;
}
.AddModifiersSection {
  width: 65rem;
  height: 2.813rem;
  display: flex;
  justify-content: start;
  margin-left: 55px;
  gap: 1.4rem;
  margin-bottom: 0.5rem;
 
}

.headingItemCustomizations {
  font-weight: 500;
  font-size: 1rem;
  width: 7.5rem;
  line-height: 1.5rem;
  
}

.AddModifiersMainInputSection {
 
// height: auto;
// margin-top: 2%;
// padding: 0;

padding-bottom: 3rem;
padding-left: 3rem;
// margin-left: 2.3%;

}
.option1-errormsg
{
 position: relative;
 left: 1rem;
  // border: 1px solid red;

}
.option11-errormsg
{
  position: relative;
  left: -1rem;
  // border: 1px solid red;


}
.availbleservicestreams
{
  margin-top: 3%;
  position: relative;
  top: 1.5rem;
}
.AddModifiersInputSection {
  display: flex;
  justify-content: start;
  gap: 0.625rem;
  
  margin-left: -4rem;

  // border: 1px solid red;
}

.dotedimageItemCustomizations {
  width: 1.5rem;
  height: 1.5rem;
  cursor: grab;
}

.paraItemCustomizations {
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.156rem;
  margin-right: 10px;
}

.inputItemCustomizations {
  width: 10.813rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  margin-bottom: 0.625rem;
  padding-left: 1rem;
  font-size: 14px;
  color: black;
}

.inputItemCustomizationserror {
  width: 10.813rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  // border: 1px solid red;
 
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  color: #979797;
}

.radioboxItemCustomizations {
  display: flex;
  justify-content: center;
  gap: 0.313rem;
  margin-left: 2.5rem;
  margin-bottom: 1.25rem;
}
.labelItemCustomizations {
  

 
  line-height: 1.313rem;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  color: #979797;

  font-size: 14px;
  
}
.labelItemCustomizations-radio {
  

 
  line-height: 1.313rem;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  color: black;

  font-size: 14px;
  
}
.labelItemCustomizations-disable{
  font-size: 0.775rem;
  line-height: 1.313rem;
  font-family: Poppins;
  font-weight: 400;
  color: #979797;
  opacity: 0.5;

}

.label1ItemCustomizations {

  line-height: 1.313rem;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  color: #979797;
  width: 10.9rem;
  font-weight: 400;
  font-size: 14px;
  
}

.radioItemCustomizations {
  accent-color: #E52333;

  width: 1.063rem;
  height: 1rem;
  margin-left: 0px;
  margin-top: 0.125rem;

  cursor: pointer;
}

.option-input-ItemCustomizations {
  width: 35.5rem;
  display: flex;
  flex-direction: column;

  gap: 1.25rem;
  margin-bottom: 1.25rem;

  // margin-left: -20px;
}
.modifiers-to-select
{

  // margin-left: 4vw;
  margin-bottom: 0;
  padding: 0;
}
.option-name-errormsg,.option-price-errormsg
{
  width: 12.5rem;
// height: 4.5rem;
  display: flex;
  flex-direction: column;
  // border: 1px solid red;
  gap: 0.2rem;
}
.modifieroptions-div
{
  display: flex;
  flex-direction: column;

  // margin-top: 1%;
// height: 4rem;
// border: 1px solid red;
gap: 1rem;
}
.input-option-name-field
{
  width: 11.375rem;
  height: 44px;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 80%;
  font-size: 14px;

  color: black;
}
.input-option-price-field
{
  width: 11.375rem;
  height: 44px !important;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  opacity: 80%;
  font-size: 14px;

  color: black;
}
.input2ItemCustomizations {
  width: 11.375rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 80%;
  font-size: 14px;
  padding-left: 0.75rem;
  color: black;
}
.draggable-item {
  cursor: grab;
  margin: 10px 0;
  margin-top: 0;
}
.input2ItemCustomizationserror {
  width: 11.375rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 80%;
  font-size: 1rem;
  padding-left: 0.75rem;
  // border: 1px solid red;
}

.btn-ItemCustomizations {
  width: 13rem;
  height: 1.313rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: none;
  background-color: transparent;
  // cursor: pointer;
  color: #0085ff;
  margin-top: 0.525rem;
  white-space: nowrap;
  margin-left: -3.438rem;
}
.spanadd {
  margin-top: 20px;


font-weight: 400;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.btn-ItemCustomizations2 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: none;
  background-color: transparent;
  // cursor: pointer;
  color: #0085ff;

  white-space: nowrap;
}

// .btn-ItemCustomizations2:hover {
//   color: #505a99;
// }

.Spinner-input-ItemCustomizations {
  display: flex;
  justify-content: start;
  // margin-left: 4rem;
  gap: 2rem;
  width: 850px;
  height: auto;
  // margin-top: 1%;

}

.Spinner-inputlabel-ItemCustomizations {
  display: flex;
  flex-direction: column;

}

.input3ItemCustomizations {
  width: 9.375rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 80%;
  font-size: 14px;
  padding-left: 0.625rem;
  color: black;
  font-weight: 400;
}
.input3ItemCustomizations-free {
  width:11rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 80%;
  font-size: 14px;
  padding-left: 0.625rem;
  color: black;
  font-weight: 400;
}
.input3ItemCustomizations-disable{
  width: 9.375rem;
  height: 2.75rem;
  border-radius: 0.313rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0.2;
  font-size: 14px;
  padding-left: 0.625rem;
  color: black;
  font-weight: 400;

}

.polydiv-ItemCustomizations {
  background-color: #d9d9d9;
  height: 44.5px;
  width: 44px;
  margin: 0.125rem;
  top: -46.5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  left: 116px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
}
.polydiv-ItemCustomizations-free {
  background-color: #d9d9d9;
  height: 44.1px;
  width: 44px;
  margin: 0.125rem;
  top: -46px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  left: 130px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
}
.polyimg-ItemCustomizations {
  position: relative;
  left: 12px;
  padding: 0.125rem;
  top: 8px;
  width: 17px;
  cursor: pointer;

  cursor: pointer;
}
.border-line
{
  position: absolute;
  top: 1.38rem;
  width: 100%;
  // height: 1px;
  padding: 0;
  margin: 0;
  height: 0px !important;
  border: none;
  border-top: 0.01px solid #939393 !important;
}
.polyimg-ItemCustomizations-disable{
  position: relative;
  left: 12px;
  padding: 0.125rem;
  top: 8px;
  width: 17px;

  cursor: pointer;

  cursor: pointer;
  opacity: 0.1;

}

.selectbox-ItemCustomizations {
  position: relative;
  width: 232px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 21px;
  color: #979797;
}

.btn-container-Addnewcustomizationstyle {
  width: 62.375rem;
  display: flex;
  justify-content: end;

  margin-top: -2.188rem;
  margin-left: 1.875rem;
}
.btn-Addnewcustomizationstyle {
  font-family: Poppins;
  font-size: 0.875rem;
  color: #2196f3;
  cursor: pointer;
}

// .btn-Addnewcustomizationstyle:hover {
//   color: #505a99;
// }
.Add-Modification-btn-ItemCustomizations {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 43.75rem;
  gap: 0.5rem;
width: 20rem;
// border: 1px solid red;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #2196f3;
  cursor: pointer;
  img{
    width: 15px;
    height: 15px;
  }
}
// .Add-Modification-btn-ItemCustomizations:hover {
//   color: #505a99;
// }

.modifier-div-margin {
  margin-bottom: 3rem;
  height: 15.625rem;
  width: fit-content;
}

.option-margin {
  margin-bottom: 1.5rem;
}

.option-input-flex-column-ItemCustomizations {
  display: flex;
  justify-content: center;
  gap: 1.625rem;
  margin-left: 5rem;
}

.option-input-flex-column1-ItemCustomizations {
  display: flex;
  justify-content: center;
  gap: 1.625rem;
  margin-left: 10rem;
  // border: 1px solid red;
 
}

.Custom-Item-availability-container {
  display: flex;
  justify-content: start;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
}

.Custom-Item-availability-container2 {
  display: flex;
  justify-content: start;
  gap: 1.25rem;
  // margin-top: -17.125rem;
  margin-left: -0.313rem;
}

.Custom-Item-availability-container-heading {
  font-family: Poppins;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
.Set-as-special-item {
  accent-color: green;
  margin-top: 0.125rem;

  width: 1.25rem;
  height: 1.25rem;
}

.Available-between {
  font-size: 0.875rem;
  font-weight: 300;
  font-family: Poppins;
  line-height: 1.313rem;
  color: #000000;
  margin-bottom: 0.313rem;
}
.calander-Custom-Item-availability-container-heading {
  display: flex;
  justify-content: start;
  gap: 1.25rem;
}
.date-Custom-item {
  opacity: 70%;

  width: 10.125rem;
  height: 2.75rem;
  border-radius: 5px;

  padding: 0.625rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn-ItemCustomizations-del {
  width: 77px;
  height: 20px;
  
height: fit-content;
   color: #e52333;;
  margin-left: 2.5rem;
  margin-top: 11px;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.875rem;
  // border: 1px solid red;
  white-space: nowrap;
  position: relative;
  left: -95px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
}

.btn1 {
  display: flex;
  gap: 50px;

  position: relative;
  transform: translateX(56px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.flexofradio {
  display: flex;
  justify-content: start;
  gap: 1.25rem;
  margin-bottom: 1rem;
  // margin-left: 3.125rem;
  width: 20rem;
  // margin-top: 0.5rem;
}
.modifier-and-options
{
  height: auto;
  margin: 0;
  padding: 0;
  // border: 1px solid blue;

}
.modifiersitem {
  height: 60vh;
  margin-top: 2%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  width: 100vw;
  z-index: 0;
  // border: 1px solid red;
  // background-color: lightgreen;
  .modifiers {
    padding-left: 55px;
width: 100%;
    // background-color: pink;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
  }
  // @media (max-width: 3710px) {
  //   height: 70vh;
  //   width: 100vw;
  //   padding-bottom: 2rem;
  //   overflow-y: auto;
  //   // border: 4px solid burlywood;
   
  //  }


 


  &::-webkit-scrollbar{
    display: none;
  }



}




.radiobtnMargin {
  display: flex;
  justify-content: start;
  gap: 0.313rem;
  // margin-left: 10px;
}

.checkbox-container {
  display: flex;
  justify-content: start;
  height: 2.5rem;
  gap: 0.625rem;
  padding-top: 0.125rem;
}

.spanOption-button {
  top: 10px;
  font-size: 16px;
  position: relative;
  font-family: 400;
  display: flex;
  // border: 1px solid red;
  justify-content: left;
  align-items: center;
}
.spanOption-button2{
  top: 10px;
  font-size: 16px;
  position: relative;
  font-family: 400;
  display: flex;
  // border: 1px solid red;
  justify-content: left;
  align-items: center;
}
.panOption-button2 {
  font-size: 14px;
}
.spanadd {
  margin-left: 7px;
margin-top: -0.1rem;
cursor: pointer;
  // font-size: 14px;
  // font-family: 400;
}

.footer-save-next {
  position: relative;
  width: 1260px;
  position: relative;
  margin-bottom: 2rem;
  margin-left: -15px;
  top: 70px;
}

.modifier-div-margin > div:nth-child(1) {
  margin-top: 34px;
  z-index: 1;
}

.error-message {
  color: red;
  font-size: 12px;
  font-family: poppins;
  margin-top: -5px;
  margin-bottom: 8px;
  margin-left: 60px;
}

.error-message1 {
  color: red;
  font-size: 12px;
  font-family: poppins;
  margin-top: 5px;
  margin-left: 20px;
}

.error-message2 {
  color: red;
  font-size: 12px;
  font-family: poppins;
  margin-top: 5px;
  margin-left: 20px;
}

.searchbox {
  display: flex;
  justify-content: start;
  position: relative;
  left: 100px;
  top: -15px;
  // background-color: lightsalmon;
  width: fit-content;
}

.searchBox-input {
  width: 400px;
  height: 44px;
  border: 1px solid #979797;
  opacity: 0.7;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: black;
  padding-left: 10px;
}

.searchIcon {
  width: 21px;
  height: 21px;
  position: relative;
  top: 10px;
  left: -35px;
  cursor: pointer;
}
.DeleteIcon
{
  width: 15px;
  height: 15px;
  position: relative;
  top: 15px;
  left: -35px;
  cursor: pointer;
}

.modifier-no-content {
  height: 248px;
}

.dropDown-item {
  position: relative;
  z-index: 0;
  margin-left: 0.8rem;
  transform: translate(-20px, -1%);
  color: #979797;
  font-size: 14px;
}

@media (max-width: 1400px) {
  .mainItemCustomizations {
    width: 62.375rem;
    height: auto;
  }

  .Add-Modification-btn-ItemCustomizations {
    margin-left: 35.75rem;
  }
}

@media (max-width: 1300px) {
  .Add-Modification-btn-ItemCustomizations {
    margin-left: 32.75rem;
  }
  // .Spinner-input-ItemCustomizations {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: start;
  //   margin-left: 4rem;
  //   gap: 0.25rem;
  //   width: 300px;
  //   height: 50vh;
  // }
  .modifier-div-margin2 {
    height: 15.625rem;

    transform: translateY(30px);
    margin-bottom: 400px;
  }

  .modifier-div-margin {
    margin-bottom: 30.5rem;
    height: 15.625rem;
  }
  .dropDown-item {
    position: relative;
    // top: 80px;
    z-index: -10;
  }
}

@media (max-width: 1200px) {
  .Add-Modification-btn-ItemCustomizations {
    margin-left: 20.75rem;
  }
}

.deleteModiferContainer {
  position: relative;
  left: 640px;
  top: -1px;
// z-index: 24;
  //cursor: pointer;
}

.Delete-text {
  width: 63px;
  color: #e52333;
  display: flex;
  height: 20px;
  font-size: 14px;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  margin-top: 13px;
  // z-index: 0;
}

.SpanDelete {
  font-size: 25px;
  transform: translate(-5px, -8px);
  position: relative;
  z-index: 0;
  color: #e52333;
}

.Search-Container-options-modifiers {
  width: 23.5rem;
  background-color: #ffffff;
  max-height: 50vh;
  padding-left: 20px;
  box-shadow: 0px 10px 15px -6px #00000040;
  // border: 1px solid red;
  transform: translateY(1px);
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 2;
  top: 5.2rem;
  left: 6.3rem;
  padding-bottom: 10px;
  &::-webkit-scrollbar{
    display: none;
  }
}

.Search-Container-options1-modifiers {
  width: 24rem;
  background-color: #ffffff;
  max-height: 50vh;
  padding-left: 20px;
  box-shadow: 0px 10px 15px -6px rgba(0, 0, 0, 0.2509803922);
  transform: translateY(1px);
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 2;
  top: 5.2rem;
  left: 6rem;
  padding-bottom: 10px;
  &::-webkit-scrollbar{
    display: none;
  }
}

.modiferSearchContainer {
  position: relative;
  right: 3vw;
  // border: 1px solid red;
  height: 3rem;
  text-align: left;

  padding-left: 0.5rem;
  width: 20rem;
}

.highlighted-modifiers {
  width: 23vw;
  text-align: left;
  display: flex;
  height: 3rem;
  margin-left: -0.5rem;
  padding-left: 0.5rem;
  // padding-left: 20px;
  justify-content: flex-start;
  // margin-left: -30px;
  align-items: flex-start;
  background-image: linear-gradient(to left, #d9d9d9 99%, #E52333 2%);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Search-Container-options-items-modifiers {
  width: 100%;
  position: relative;
  // right: 30px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 54px;
  color: #000000;
}

.Search-Container-options1-items-modifiers {
  width: 450px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 54px;
  color: #000000;
 
 
  height: 12px;
  margin-bottom: 10px;
  margin-left: 20px;
  padding-bottom: 30px;
 
  z-index: 2;
}

.Search-Container-options-modifiers ul {
  list-style: none;
  // cursor: pointer;
  cursor: pointer;
}
.Search-Container-options1-modifiers ul {
  list-style: none;
  cursor: pointer;
  // cursor: pointer;
}

.Search-Container-options-none-modifiers {
  background-color: #ffffff;
  // box-shadow: 0px 10px 15px -6px #00000040;
  overflow-x: hidden;
}
// .Search-Container-options1-none-modifiers {
//   width: 580px;
//   // height: 407px;
//   background-color: #ffffff;
//   border:1px solid transparent;

//   box-shadow: 0px 10px 15px -6px #00000040;
//   transform: translate(-20px);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: -10px;
// }

.Search-Container-options-none-flex-direction-modifiers {
  // display: flex;
  // flex-direction: column;

  // border: 1px solid red;
  background: none;
  border: none;
  margin-left: -2rem;
  box-shadow: none;
}

.NotFoundImage-modifiers {
  width: 20rem;
  height: 17rem;
}

.heading-none-modifiers {
  color: #E52333;
  font-size: 22px;
  font-family: Poppins;
  transform: translate(90px, -60px);
}

.Addmodificationfromsearch {
  width: 4rem;
  height: 2rem;
  background-color: #E52333;
  color: white;
  border: none;
  border-radius: 5px;
  position: relative;
  left: 13rem;
}

.nameErrormsg {
  color: #e52333;
  // margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  
}
.optionNameErrormsg
{
  // height: 1rem;
  // border: 1px solid red;
  // margin-bottom: 1rem;
  // margin-top: 1rem;
}
.optionpriceerrormsg
{
  // height: 1rem;
  // border: 1px solid red;
  // margin-bottom: 1rem;
  // margin-top: 1rem;
}
.modifiernameerror
{
  height: 1.5rem;
  margin-top: -0.5rem;
  // border: 1px solid red;
}





.Spinner-input-ItemCustomization-height
{

  display: flex;
  justify-content: start;
  // margin-left: 4rem;
  gap: 2rem;
  width: 80vw;
  height: 30vh;
  // margin-top: 1%;
  // border: 1px solid red;
  // @media (max-width: 3710px) {
   
  //   // border: 1px solid black;
  //   height: 15vh;
     
  //    }
  //    @media (max-width: 2304px) {
  //   height: 20vh;
  //     // border: 2px solid green;
     
  //    }
  //   @media (max-width: 1920px) {
  //     height: 22vh;
  //     // border: 2px solid violet;
     
  //    }

  // @media (max-width:1710px) {
  //   height: 28vh;
  //   // border: 1px solid brown;
    
  // }
 
  // @media (max-width:1536px) {
  //   // height: 40vh;
  //   border: 1px solid blue;
    
  // }
  @media (max-width:1400px) {
    height: 43vh;
    // border: 2px solid teal;
    
  }
}

.input-fields-of-modifiers-options
{
  display: flex;
  gap: 2rem;
}

.input-fields-errors-of-modifiers-options
{
  display: flex;

  
 
}

.optionname-errormgs
{
  width: 13.3rem;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
  // border: 1px solid blue;

}
.optionprice-errormgs
{
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}